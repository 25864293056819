import { logDOM } from '@testing-library/react';
import axios from 'axios';

const instance = axios.create({
     //baseURL: 'https://uattm.jameer.xyz/api'});   
    //baseURL: 'https://localhost:44313/api'});
    //baseURL: 'https://tm3.alhindit.com/api'});
    //baseURL: 'https://webapi.travelmythri.com/api'});
    baseURL: 'https://tmuat.alhindit.com/api'});
    
    // let tokenValue=localStorage.getItem("tokenValue");
    // if (tokenValue == null || tokenValue === "" || tokenValue === undefined) {
    //     instance.defaults.headers.common['DeviceId'] = localStorage.getItem("DeviceId");
    // }
    // if( (tokenValue==null || tokenValue=="" || tokenValue==undefined))
    // {
        
        
    //      instance.defaults.headers.common['Authorization'] =  'Bearer '+ localStorage.getItem("NonUsertokenValue");
        
    //  }
    // else
    // {
    //      instance.defaults.headers.common['Authorization'] =  'Bearer '+ localStorage.getItem("tokenValue");
    // }

   
    
    if(window.innerWidth<700)
        instance.defaults.headers.common['DeviceCode'] =  "MV";
    else
        instance.defaults.headers.common['DeviceCode'] =  "W";
   

    
export default instance;