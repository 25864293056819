import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dateFormat, { masks } from "dateformat";
import { useState, useEffect } from "react";
import { GetOneWaySearch } from "../../Services/OneWaySearch";
import { GetCRTSearch } from "../../Services/OneWaySearch";
import DatePicker from "react-datepicker";
import { GetFlightDetailsIR } from "../../Services/FlightDetails";
import { useNavigate, Link, json } from "react-router-dom";
import { AES, enc } from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetAirportList } from "../../Services/UtilitiesServices";
import ModifySearchPopUp from "./ModifySearchPopUp";
import { GetPricingDetails } from "../../Services/FlightDetails"
import ReactSlider from 'react-slider'
import { URL_EncodeKey } from "../../Services/EncryptionService"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class SearchResultCOmbainedRoundTrip extends Component {
  OnewayDestination = "";
  RoundTripDestination = "";
  constructor(props) {
    super(props);
    this.onewayOriginRef = React.createRef();
    this.onewayDestinationRef = React.createRef();
    this.RoundTripOriginRef = React.createRef();
    this.RoundTripDestinationRef = React.createRef();
    this.autoSuggestResultRef = React.createRef();
    this.autoSuggestResultRef2 = React.createRef();
    this.autoSuggestResultRef3 = React.createRef();
    this.autoSuggestResultRef4 = React.createRef();
    this.state = {
      inputValue: '',
      showEmailModal: false,
      showCheckboxes: false,
      selectedItems: [], // Array to store selected item details
      StopsReturn: [],
      DepartureTimesReturn: [],
      ArrivalTimesReturn: [],
      selectedIndex: -1,
      selectedIndex2: -1,
      selectedIndex3: -1,
      selectedIndex4: -1,
      originError: false,
      destinationError: false,
      originErrorR: false,
      destinationErrorR: false,
      RequestFields: [],
      selectedFareId: null,
      RoundTripIntSearchResult: [],
      LowerFareResult: [],
      ItinSearchResult: [],
      AvlAirlineResult: [],
      ItinSearchResultR: [],
      isLoaded: false,
      isResultFound: false,
      FlightDetailsResult: [],
      objitinResult: [],
      objitinResultR: [],
      objseglistResult: [],
      objseglistResultR: [],
      itinId: 0,
      fareId: 0,
      providerCode: "",
      itinIdR: 0,
      fareIdR: 0,
      providerCodeR: "",
      RoundTripCombainedFullSearchResult: [],

      RequestBody: {},
      originSectorRound: {},
      destinationSectorRound: {},

      originSectorOneway: [],

      RoundTripFullSearchResult: [],
      LowerFareResult: [],
      ItinSearchResult: [],
      ItinSearchResultCopy: [],
      AvlAirlineResult: [],
      ItinSearchResultR: [],
      ItinSearchResultRCopy: [],
      ResponseBodyCopy: {},

      //Filter
      ResultFIlter: {
        steps: []
      },
      Airlines: [],
      AirlineCheckbox: [],
      ItinSearchResultCopy: [],
      priceRangeMin: 0,
      priceRangeMax: 0,
      DepartureTimes: [],
      DepartureOnwardTimesCheckbox: [],
      DepartureReturnTimesCheckbox: [],
      ArrivalTimes: [],
      ArrivalOnwardTimesCheckbox: [],
      ArrivalReturnTimesCheckbox: [],
      Stops: [],
      StopsCheckbox_Onward: [],
      StopsCheckbox_Return: [],

      isFastestActive: false,
      isCheapestActive: false,
      isEarlierActive: false,


      //Autocomplete...
      value: '',
      suggestions: [],
      OnewayOriginSearchTerm: '',
      OnewayDestinationSearchTerm: '',
      OnewayOriginSearchResults: [],
      OnewayDestinationSearchResults: [],
      OnewayOriginAirport: [],
      OnewayDestinationAirport: [],
      onewayDepartureDate: new Date(),

      //RoundTrip
      RoundTripOriginSearchTerm: '',
      RoundTripDestinationSearchTerm: '',
      RoundTripOriginSearchResults: [],
      RoundTripDestinationSearchResults: [],
      RoundTripOriginAirport: [],
      RoundTripDestinationAirport: [],
      isShared: false,
      emailSent: false,
    };
    this.secondInputRef = React.createRef();
    this.swapOriginAndDestination = this.swapOriginAndDestination.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  handleRadioChangeFare = (index, item1) => {
    const { ItinSearchResult, priceRangeMax, ResponseBodyCopy } = this.state;

    // Create a copy of the current state for immutability
    const updatedItinSearchResult = [...ItinSearchResult];
    updatedItinSearchResult[index] = {
      ...updatedItinSearchResult[index],
      freeBaggage: item1.checkinBaggage,
      fareName: item1.fareName,
      netAmount: item1.netAmount,
      amount: item1.netAmount + item1.discount,
      fareId: item1.fareId,
    };

    // Update the maximum fare if needed
    if (priceRangeMax < item1.netAmount) {
      const updatedResponseBodyCopy = { ...ResponseBodyCopy, maximumFare: item1.netAmount };
      this.setState({
        priceRangeMax: item1.netAmount,
        ResponseBodyCopy: updatedResponseBodyCopy,
      });
    }

    // Update the state with the modified data
    this.setState({ ItinSearchResult: updatedItinSearchResult });
  };




  handleChangeEmail = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleEmailShow = () => {
    const { selectedItems } = this.state;


    if (selectedItems.length == 0) {
      toast.error('Please select Itinerary...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    this.setState({ emailSent: true });
    this.setState({ showEmailModal: true });
  };

  handleEmailClose = () => {
    this.setState({ showEmailModal: false });
  };

  // Toggle checkbox visibility
  handleShareButtonClick = () => {
    this.setState((prevState) => ({
      showCheckboxes: !prevState.showCheckboxes,
    }));
  };

  // Handle checkbox change
  handleCheckboxChange = (item, index) => {
    this.setState((prevState) => {
      const { selectedItems } = prevState;
      const isSelected = selectedItems.some(selectedItem => selectedItem.index === index);

      if (!isSelected && selectedItems.length >= 3) {
        toast.error('Maximum 3 Itineraries Possible...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        return;
      }

      return {
        selectedItems: isSelected
          ? selectedItems.filter((selectedItem) => selectedItem.index !== index) // Uncheck
          : [...selectedItems, { ...item, index }], // Check
      };
    });
  };

  swapOriginAndDestination() {
    let tempData;
    tempData = this.state.OnewayOriginAirport;

    let origin = this.state.OnewayOriginSearchTerm;

    this.setState({
      OnewayOriginAirport: this.state.OnewayDestinationAirport,
      OnewayDestinationAirport: tempData,

      OnewayOriginSearchTerm: this.state.OnewayDestinationSearchTerm,
      OnewayDestinationSearchTerm: origin
    })

    //Round
    let tempData1;
    tempData1 = this.state.RoundTripOriginAirport;

    let origin1 = this.state.RoundTripOriginSearchTerm;

    this.setState({
      RoundTripOriginAirport: this.state.RoundTripDestinationAirport,
      RoundTripDestinationAirport: tempData1,

      RoundTripOriginSearchTerm: this.state.RoundTripDestinationSearchTerm,
      RoundTripDestinationSearchTerm: origin1
    })
  }


  formattedDate = (date) => {
    const options = { day: '2-digit', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  //AutoComplete fn...
  handleSearch = (event) => {
    let airportsTemp = this.state.AirportJson;

    const { value } = event.target;
    if (event.target.name == "onewayOrigin") {
      this.setState({ OnewayOriginSearchTerm: value });
      this.OnewayOrigin = "";
    }
    else if (event.target.name == "onewayDestination") {
      this.setState({ OnewayDestinationSearchTerm: value });
      this.OnewayDestination = ""
    }
    else if (event.target.name == "RoundTripOrigin") {
      this.setState({ RoundTripOriginSearchTerm: value });
      this.RoundTripOrigin = ""
    }
    else if (event.target.name == "RoundTripDestination") {
      this.setState({ RoundTripDestinationSearchTerm: value });
      this.RoundTripDestination = ""
    }


    if (value.length >= 2) {
      let index = -1;
      let SortedAirportArray = [];
      const matchingAirports = airportsTemp.filter(
        (airport) =>
          airport.code.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.airportName.toLowerCase().includes(value.toLowerCase()) ||
          airport.countryCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityName.toLowerCase().includes(value.toLowerCase())
      );

      // selected code to top of the Array.apply..
      for (let i = 0; i < matchingAirports.length; i++) {
        if (matchingAirports[i].code.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;

        }
        else if (matchingAirports[i].code.toLowerCase() === matchingAirports[i].cityCode.toLowerCase()) {
          index = i;
        }
      }

      if (index >= 0) {
        SortedAirportArray.push(matchingAirports[index]);
        for (let j = 0; j < matchingAirports.length; j++) {
          if (index != j) {
            SortedAirportArray.push(matchingAirports[j]);
          }
        }
      }
      else {
        SortedAirportArray = [];
        SortedAirportArray = matchingAirports;
      }

      if (SortedAirportArray.length === 1) {
        if (event.target.name == "onewayOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.onewayOriginRef.current.focus();
          this.setState({ originError: false });
          return
        }
        else if (event.target.name == "onewayDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          if (this.onewayDestinationRef && this.onewayDestinationRef.current && this.onewayDestinationRef.current.input) {
            this.onewayDestinationRef.current.input.focus();
            this.setState({ destinationError: false });
          }
          return
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.RoundTripOriginRef.current.focus();
          this.setState({ originErrorR: false });
          return
        }
        else if (event.target.name == "RoundTripDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          if (this.RoundTripDestinationRef && this.RoundTripDestinationRef.current && this.RoundTripDestinationRef.current.input) {
            this.RoundTripDestinationRef.current.input.focus();
            this.setState({ destinationErrorR: false });
          }
          return
        }
      }
      else {
        if (event.target.name == "onewayOrigin") {
          this.setState({ originError: true });
        }
        else if (event.target.name == "onewayDestination") {
          this.setState({ destinationError: true });
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.setState({ originErrorR: true });
        }
        else if (event.target.name == "RoundTripDestination") {
          this.setState({ destinationErrorR: true });
        }
      }

      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "onewayDestination") {

        this.setState({ OnewayDestinationSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: SortedAirportArray });
      }
    }
    else if (event.target.value == "")   //IF nothing in the textbox..
    {
      if (event.target.name == "onewayOrigin" || event.target.name == "RoundTripOrigin") {
        this.OnewayOrigin = "";
        this.RoundTripOrigin = "";

        this.setState({
          SameAirportsError: false,
          OnewayOriginSearchTerm: "",
          RoundTripOriginSearchTerm: "",
          OnewayOriginAirport: {},
          RoundTripOriginAirport: {},
        });

      }
      else if (event.target.name == "onewayDestination" || event.target.name == "RoundTripDestination") {
        this.OnewayDestination = "";
        this.RoundTripDestination = "";

        this.setState({
          SameAirportsError: false,
          OnewayDestinationSearchTerm: "",
          RoundTripDestinationSearchTerm: "",
          OnewayDestinationAirport: {},
          RoundTripDestinationAirport: {},
        });
      }


    }
    else {
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: [] });
      }
      else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: [] });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: [] });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: [] });
      }
    }
  };

  resetFilter() {
    //Clear Airline Filter...
    this.setState({
      Airlines: [],
      Stops: [],
      StopsReturn: [],
      DepartureTimes: [],
      DepartureTimesReturn: [],
      ArrivalTimes: [],
      ArrivalTimesReturn: [],


      AirlineCheckbox: [],
      StopsCheckbox_Onward: [],
      StopsCheckbox_Return: [],

      DepartureOnwardTimesCheckbox: [],
      DepartureReturnTimesCheckbox: [],
      ArrivalOnwardTimesCheckbox: [],
      ArrivalReturnTimesCheckbox: [],



    });

    if (this.state.isFastestActive == true || this.state.isCheapestActive == true || this.state.isEarlierActive == true) {
      this.setState({
        ItinSearchResult: this.state.ItinSearchResultCopy,
        ItinSearchResultR: this.state.ItinSearchResultRCopy,
        isFastestActive: false,
        isCheapestActive: false,
        isEarlierActive: false,
      });
    }

    if (this.state.ResponseBodyCopy.minimumFare != undefined) {
      this.setState({
        priceRangeMin: this.state.ResponseBodyCopy.minimumFare,
        priceRangeMax: this.state.ResponseBodyCopy.maximumFare,
      })
    }

    //Display selection Removes...

  }


  handleSelectAirport = (code, name, type, airportDetails) => {
    const selectedAirport = `${code}-${airportDetails.cityName}-${name}-${airportDetails.countryCode}`;
    if (type == "onewayOrigin") {
      this.OnewayOrigin = code;
      this.setState({
        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,

        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,
        OnewayOriginSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "onewayDestination") {
      this.OnewayDestination = code;
      this.RoundTripDestination = selectedAirport;
      this.setState({
        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,

        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,
        OnewayDestinationSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripOrigin") {
      this.RoundTripOrigin = code;
      this.setState({
        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,

        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,
        RoundTripOriginSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripDestination") {
      this.RoundTripDestination = code;
      this.setState({
        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,

        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,
        RoundTripDestinationSelected: true,


      }, () => {
        this.scrollSelectedIntoView();
      });

    }

    //If same Airport...
    if (type == "onewayOrigin" || type == "onewayDestination") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }
    }
    else {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }

    }




  };

  changeAirportListShowStatus(FieldName) {
    if (FieldName == "Destination") {
      this.setState({
        OnewayOriginSearchResults: [],
        RoundTripOriginSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayOrigin == "") {
        this.setState({
          OnewayOriginSearchTerm: "",
        })
      }

      if (this.RoundTripOrigin == "") {
        this.setState({
          RoundTripOriginSearchTerm: "",
        })
      }
    }
    else {
      this.setState({
        OnewayDestinationSearchResults: [],
        RoundTripDestinationSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayDestination == "") {
        this.setState({
          OnewayDestinationSearchTerm: "",
        })
      }


      if (this.RoundTripDestination == "") {
        this.setState({
          RoundTripDestinationSearchTerm: "",
        })
      }
    }
  }


  handleKeyDown = (event, airportListLength) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.handleArrowUp();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.handleArrowDown();
    }
    else if (event.key === "Enter" && airportListLength > 0) {
      event.preventDefault();
      this.handleEnterKey(event);
    }
  };

  handleArrowUp = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex > 0 ? selectedIndex - 1 : OnewayOriginSearchResults.length - 1;
    const newIndex2 = selectedIndex2 > 0 ? selectedIndex2 - 1 : OnewayDestinationSearchResults.length - 1;
    const newIndex3 = selectedIndex3 > 0 ? selectedIndex3 - 1 : RoundTripOriginSearchResults.length - 1;
    const newIndex4 = selectedIndex4 > 0 ? selectedIndex4 - 1 : RoundTripDestinationSearchResults.length - 1;
    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  handleArrowDown = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex < OnewayOriginSearchResults.length - 1 ? selectedIndex + 1 : 0;
    const newIndex2 = selectedIndex2 < OnewayDestinationSearchResults.length - 1 ? selectedIndex2 + 1 : 0;
    const newIndex3 = selectedIndex3 < RoundTripOriginSearchResults.length - 1 ? selectedIndex3 + 1 : 0;
    const newIndex4 = selectedIndex4 < RoundTripDestinationSearchResults.length - 1 ? selectedIndex4 + 1 : 0;

    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  scrollSelectedIntoView = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    if (this.autoSuggestResultRef.current) {
      const selectedElement = this.autoSuggestResultRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef2.current) {
      const selectedElement2 = this.autoSuggestResultRef2.current.querySelector(`[data-index="${selectedIndex2}"]`);
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef3.current) {
      const selectedElement3 = this.autoSuggestResultRef3.current.querySelector(`[data-index="${selectedIndex3}"]`);
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef4.current) {
      const selectedElement4 = this.autoSuggestResultRef4.current.querySelector(`[data-index="${selectedIndex4}"]`);
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  handleEnterKey = (event) => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    let defaultIndex = selectedIndex;
    let defaultIndex2 = selectedIndex2;
    let defaultIndex3 = selectedIndex3;
    let defaultIndex4 = selectedIndex4;
    if (defaultIndex >= -1 && defaultIndex < OnewayOriginSearchResults.length) {
      if (defaultIndex == -1)

        defaultIndex = 0;
      const selectedAirport = OnewayOriginSearchResults[defaultIndex];
      if (event.target.name == "onewayOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.onewayOriginRef.current.focus();
        this.setState({ originError: false });
        return
      }
    }
    if (defaultIndex2 >= -1 && defaultIndex2 < OnewayDestinationSearchResults.length) {
      if (defaultIndex2 == -1)

        defaultIndex2 = 0;
      const selectedAirport = OnewayDestinationSearchResults[defaultIndex2];
      if (event.target.name == "onewayDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          OnewayOriginSearchTerm,
        } = this.state;

        if (this.OnewayDestination == this.OnewayOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          this.setState({ SameAirportsError: false });
          if (this.onewayDestinationRef && this.onewayDestinationRef.current && this.onewayDestinationRef.current.input) {
            this.onewayDestinationRef.current.input.focus();
            this.setState({
              destinationError: false,
              SameAirportsError: false
            });
          }
          return
        }

      }
    }
    if (defaultIndex3 >= -1 && defaultIndex3 < RoundTripOriginSearchResults.length) {
      if (defaultIndex3 == -1)

        defaultIndex3 = 0;
      const selectedAirport = RoundTripOriginSearchResults[defaultIndex3];
      if (event.target.name == "RoundTripOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.RoundTripOriginRef.current.focus();
        this.setState({ originErrorR: false });
        return
      }
    }
    if (defaultIndex4 >= -1 && defaultIndex4 < RoundTripDestinationSearchResults.length) {
      if (defaultIndex4 == -1)

        defaultIndex4 = 0;
      const selectedAirport = RoundTripDestinationSearchResults[defaultIndex4];
      if (event.target.name == "RoundTripDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          RoundTripOriginSearchTerm,
        } = this.state;

        if (this.RoundTripDestination == RoundTripOriginSearchTerm) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          if (this.RoundTripDestinationRef && this.RoundTripDestinationRef.current && this.RoundTripDestinationRef.current.input) {
            this.RoundTripDestinationRef.current.input.focus();
            this.setState({ destinationErrorR: false });
          }
          return
        }
      }
    }


  };


  componentDidMount() {
    window.scrollTo(0, 0);
    this.resetFilter();

    this.setState({
      isLoaded: false,
    })

    const params = new URLSearchParams(window.location.search);
    const CRTSearchRequest = params.get('CRTSearchRequest');

    this.requestBody = JSON.parse(CRTSearchRequest);

    if (JSON.parse(localStorage.getItem("TM_UDetails")) != null) {
      let userDetails = JSON.parse(localStorage.getItem("TM_UDetails"));
      this.requestBody.userId = userDetails.userId;
      if (this.requestBody.isDirect = "False")
        this.requestBody.isDirect = false;
      else
        this.requestBody.isDirect = true;
    }

    this.setAirportDetailsFromRequestBody();

    this.setState({
      originSectorRound: this.requestBody.objSectorList[0],
      destinationSectorRound: this.requestBody.objSectorList[1],
      onewayDepartureDate: this.requestBody.objSectorList[0].departureDate,
    });

    try {
      this.setState({ RequestBody: this.requestBody }, () => {
        this.getSearchResult();
      })
    } catch (error) {
      if (error.response.data.responseMessage == "InvalidToken") {
        this.props.navigate('/');
      }
      toast.success('Something went wrong...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    };

    //Get All Airports...
    this.getAllAirports();

  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };


  setAirportDetailsFromRequestBody() {
    const params = new URLSearchParams(window.location.search);
    const CRTSearchRequest = params.get('CRTSearchRequest');

    let dataOnward = {}, dataReturn = {};
    let requestDummyOnward = JSON.parse(CRTSearchRequest).objSectorList[0];
    let requestDummyReturn = JSON.parse(CRTSearchRequest).objSectorList[1];

    this.OnewayOrigin = requestDummyOnward.origin;
    this.RoundTripOrigin = requestDummyOnward.origin;
    dataOnward.cityCode = requestDummyOnward.origin;
    dataOnward.countryCode = requestDummyOnward.origincountry;

    this.OnewayDestination = requestDummyOnward.destination;
    this.RoundTripDestination = requestDummyOnward.destination;
    dataReturn.cityCode = requestDummyReturn.origin;
    dataReturn.countryCode = requestDummyReturn.origincountry;


    let date = {};
    date.departureDate = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd")

    this.setState({
      OnewayOriginAirport: dataOnward,
      OnewayDestinationAirport: dataReturn,

      RoundTripOriginAirport: dataOnward,
      RoundTripDestinationAirport: dataReturn,
      originSectorRound: date,
    })
  }

  //#region OnewaySection
  originDestinationSectorChange(event, type) {
    let originSectorMain = [];
    let originSector1 = this.state.RequestBody.objSectorList[0];

    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.origin = this.state.AirportJson[key].cityCode;
          originSector1.origincountry = this.state.AirportJson[key].countryCode;
          originSector1.tripmode = "O";
        }
      });
      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
      });
    } else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.destination = this.state.AirportJson[key].cityCode;
          originSector1.destinationcountry = this.state.AirportJson[key].countryCode;
        }
      });

      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
      });
    }

  }

  setStartDate(date) {

    let RoundTripCombainedFullSearchResult = this.state.RoundTripCombainedFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    RoundTripCombainedFullSearchResult.departureDate = selectedDate;
    let onewayDepartureDate = selectedDate;

    this.setState({
      RoundTripCombainedFullSearchResult: RoundTripCombainedFullSearchResult,
      onewayDepartureDate: onewayDepartureDate,
    })

    //set Round Trip Section...
    this.setTravelDateRoundTrip(date, "S", "setStartDate");
  }


  dropDownSelectionChange = (event) => {
    const { name, value } = event.target;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 10 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 10 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      this.setState({
        infant: infantValue
      });
    }

    let testData = {};
    testData = this.state.RequestBody;
    let countData = this.state.RoundTripCombainedFullSearchResult;
    if (
      event.target.name == "adult" ||
      event.target.name == "child" ||
      event.target.name == "infant"
    ) {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value);
        countData.adult = Number(event.target.value);
      } else if (event.target.name == "child") {
        testData.child = Number(event.target.value);
        countData.child = Number(event.target.value);
      } else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value);
        countData.infant = Number(event.target.value);
      }
      this.setState({
        RequestBody: testData,
        RoundTripCombainedFullSearchResult: countData
      });
      return;
    } else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value;

      this.setState({
        RequestBody: testData,
      });
    }

  };

  SearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originError == false && this.state.destinationError == false) {
      const {
        OnewayOriginSearchTerm,
        OnewayDestinationSearchTerm,
      } = this.state;
      if (!OnewayOriginSearchTerm || !OnewayDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = {}
        data.departureDate = this.state.onewayDepartureDate;
        data.origin = this.state.OnewayOriginAirport.cityCode;
        data.origincountry = this.state.OnewayOriginAirport.countryCode;
        data.destination = this.state.OnewayDestinationAirport.cityCode;
        data.destinationcountry = this.state.OnewayDestinationAirport.countryCode;
        data.Tripmode = type;

        requestToSend.objSectorList = [data];
        //TravelType...
        if (
          requestToSend.objSectorList[0].origincountry != "IN" ||
          requestToSend.objSectorList[0].destinationcountry != "IN"
        ) {
          requestToSend.traveltype = "I";
          travelTypeToCheck = "I";
        } else {
          requestToSend.traveltype = "D";
          travelTypeToCheck = "D";
        }

        this.setState({
          RequestBody: requestToSend,
        });


        //Navigation....
        if (type == "O") {
          let url = "/Oneway?onewaySearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
          this.props.navigate(url);
        }
        else if (type == "R") {
          if (travelTypeToCheck == "D") {
            let url = "/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
            this.props.navigate(url);
          }
          else {
            let url = "/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
            this.props.navigate(url);
            this.componentDidMount();
          }
        } else if (type == "S") this.props.navigate("/International");
        else if (type == "M") this.props.navigate("/Multicity");
      }

    }
  };

  //#endregion

  //#region FIlters...

  //Airline Filter
  filterCommonSettings = (event, item, i) => {



    window.scrollTo(0, 0);
    let value = item.airlineName

    let data = {};
    let isPresent = false;
    let airlines = [];

    airlines = this.state.Airlines;
    data = this.state.ResultFIlter;
    if (event.target.checked) {

      airlines.forEach((airline, index) => {
        if (airline == value) {
          return;
        }

      });

      airlines.push(value);
    }
    else {
      let ddd = [];
      airlines.map((item) => {

        if (item != value) {

          ddd.push(item);


        }
      });
      airlines = ddd

    }
    this.setState({
      Airlines: airlines,
    })
    let airlineData = {};
    airlineData.Airlines = airlines
    this.setState({
      ResultFIlter: airlineData
    })


    this.render();

    let value1 = this.state.AirlineCheckbox;
    value1[i] = event.target.checked;
    this.setState({ AirlineCheckbox: value1 })


  }

  priceRangeChange = (event) => {


    this.setState({
      priceRangeMin: event[0] - 1,
      priceRangeMax: event[1] + 1,
    })


  }

  stopsChanged = (event, i) => {
    window.scrollTo(0, 0);
    let data = [];
    let resultFIlter = {};
    data = this.state.Stops;
    resultFIlter = this.state.ResultFIlter;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });

      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }

    this.setState({
      Stops: data
    })



    let value1 = this.state.StopsCheckbox_Onward;
    value1[i] = event.target.checked;
    this.setState({ StopsCheckbox_Onward: value1 })

  }

  stopsReturnChanged = (event, i) => {
    window.scrollTo(0, 0);
    let data = [];
    let resultFIlter = {};
    data = this.state.StopsReturn;
    resultFIlter = this.state.ResultFIlter;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }

    this.setState({
      StopsReturn: data
    })



    let value1 = this.state.StopsCheckbox_Return;
    value1[i] = event.target.checked;
    this.setState({ StopsCheckbox_Return: value1 })

  }

  SortList = (type) => {
    let data = {}
    data = this.state.ResultFIlter;
    data.sortList = type;
    this.setState({
      ResultFIlter: data
    })

    //Run Filter...
    this.getFilteredSearchResult("sort");
  }

  //DepartureTImeSort...
  departreTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.DepartureTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }
    this.setState({
      DepartureTimes: data
    })


    let value1 = this.state.DepartureOnwardTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ DepartureOnwardTimesCheckbox: value1 })

  }

  departureTimeReturn = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.DepartureTimesReturn;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }
    this.setState({
      DepartureTimesReturn: data
    })



    let value1 = this.state.DepartureReturnTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ DepartureReturnTimesCheckbox: value1 })

  }

  //ArrivalTImeSort...
  arrivalTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.ArrivalTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });

      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }
    this.setState({
      ArrivalTimes: data
    })


    //FilterReset....

    let value1 = this.state.ArrivalOnwardTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ ArrivalOnwardTimesCheckbox: value1 })
  }

  arrivalTimeReturn = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.ArrivalTimesReturn;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }
    this.setState({
      ArrivalTimesReturn: data
    })


    // REset Filter....

    let value1 = this.state.ArrivalReturnTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ ArrivalReturnTimesCheckbox: value1 })

  }

  //Sort By Cheapest Fastest Earliest...
  sortArray = (event) => {
    window.scrollTo(0, 0);
    let type = event.target.value;
    if (type == "cheapest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount),
        isCheapestActive: true,
        isFastestActive: false,
        isEarlierActive: false,

      }));
    }
    else if (type == "fastest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.onwardDetails.durationInMinutes - b.onwardDetails.durationInMinutes),
        isFastestActive: true,
        isCheapestActive: false,
        isEarlierActive: false,
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          let item1 = a.onwardDetails;
          let item2 = b.onwardDetails;
          const timeA = new Date(`${item1.arrivalDate} ${item1.arrivalTime}`);
          const timeB = new Date(`${item2.arrivalDate} ${item2.arrivalTime}`);

          return timeA - timeB
        }),
        isEarlierActive: true,
        isFastestActive: false,
        isCheapestActive: false,
      }));
    }

  };

  //Here Filter the data...
  getFilteredSearchResult = (type) => {
    let tempArray = [];

    //////////////////////////////////
    if (type == "airline")//AIrline FIlter ...)
    {
      let list = [], airlineList = [];
      list = this.state.ItinSearchResultCopy;
      airlineList = this.state.ResultFIlter.Airlines;
      if (this.state.Airlines.length) {
        list.length > 0 && list.map((item, index) => (
          this.state.Airlines.map((airline, index2) => {
            if (airline == item.airlineName) {
              tempArray.push(item);
            }
          })

        ))
      }
      else {
        tempArray = list;
      }

    }
    else if (type == "price")//Price Range Filter... 
    {
      this.state.ItinSearchResult.map((item2, index3) => {
        if (item2.netAmount < this.state.ResultFIlter.priceRangeMax) {
          tempArray.push(item2);
        }
      })

    }
    else if (type == "stop") {


      if (this.state.ResultFIlter.steps.length) {
        let isAllSelected = false;
        if (this.state.ResultFIlter.steps.length == 3) {
          return;
        }

        this.state.ItinSearchResult.length > 0 && this.state.ItinSearchResult.map((item, index) => (
          this.state.ResultFIlter.steps.map((stop, index2) => {
            if (stop == item.noofStop) {
              tempArray.push(item);
            }
          })

        ))
      }

    }
    else if (type == "sort") {
      tempArray = this.state.ItinSearchResult.sort((a, b) => new Date(...a.initialRegistration.split('/').reverse()) - new Date(...b.initialRegistration.split('/').reverse()))


    }
    else if (type == "departureRange") {

      let departRange = this.state.ResultFIlter.departureRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.departureDate} ${item.departureTime}`);
        const hours = getDate.getHours();


        if (departRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (departRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }
    else if (type == "arrivalRange") {

      let arrivalRange = this.state.ResultFIlter.arrivalRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.arrivalDate} ${item.arrivalTime}`);
        const hours = getDate.getHours();


        if (arrivalRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (arrivalRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }

    this.setState({
      ItinSearchResult: tempArray
    })

    this.render();
  }

  sortByDropDown(type) {

    if (type == "1") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount)
      }));
    }
    else if (type == "2") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.duration - b.duration)
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        })
      }));
    }
  }

  //#endregion


  //#region RoundTrip ModifySearch

  inputSearchSectorChangeFocusOutRoundTrip = (event, type) => {

    let objSector = [];
    let sectorValues = {},
      sectorValuesReturn = {};
    sectorValues = this.state.originSectorRound;
    sectorValuesReturn = this.state.destinationSectorRound;

    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.origin = this.state.AirportJson[key].cityCode;
          sectorValues.origincountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.destination = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.destinationcountry = this.state.AirportJson[key].countryCode;
        }
      });
    } else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.destination = this.state.AirportJson[key].cityCode;
          sectorValues.destinationcountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.origin = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.origincountry = this.state.AirportJson[key].countryCode;
        }
      });
    }

    //Onward & Return...
    this.setState({
      originSectorRound: sectorValues,
      destinationSectorRound: sectorValuesReturn,
    });

  };

  inputOnewaySearchRequestChangeHandlerRoundTrip = (event) => {

    let testData = {};
    testData = this.state.RequestBody;
    let countData = this.state.RoundTripCombainedFullSearchResult;
    if (
      event.target.name == "adult" ||
      event.target.name == "child" ||
      event.target.name == "infant"
    ) {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value);
        countData.adult = Number(event.target.value);
      } else if (event.target.name == "child") {
        testData.child = Number(event.target.value);
        countData.child = Number(event.target.value);
      } else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value);
        countData.infant = Number(event.target.value);

      }

      this.setState({
        RequestBody: testData,
        RoundTripCombainedFullSearchResult: countData
      });
      return;
    } else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value;

      this.setState({
        RequestBody: testData,
      });
    }
  };

  setTravelDateRoundTrip = (date, type) => {

    let RoundTripCombainedFullSearchResult = this.state.RoundTripCombainedFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    let originSectorRound = this.state.originSectorRound;
    if (type == "S") {
      RoundTripCombainedFullSearchResult.departureDate = selectedDate;
      originSectorRound.departureDate = selectedDate;
      if (selectedDate > RoundTripCombainedFullSearchResult.returnDate) //Departure date GREATER THAN return Date...
      {
        let destinationSectorRound = this.state.destinationSectorRound;
        destinationSectorRound.departureDate = selectedDate;
        RoundTripCombainedFullSearchResult.returnDate = selectedDate;
        //Set Return Date...      
        this.setState({
          destinationSectorRound: destinationSectorRound,
          RoundTripCombainedFullSearchResult: RoundTripCombainedFullSearchResult,
        })
      }
      this.setState({

        RoundTripCombainedFullSearchResult: RoundTripCombainedFullSearchResult,
        originSectorRound: originSectorRound,
      })

      //Oneway...
      let onewayDepartureDate = selectedDate;
      this.setState({
        onewayDepartureDate: onewayDepartureDate,
      })
    }
    else {
      let destinationSectorRound = this.state.destinationSectorRound;
      RoundTripCombainedFullSearchResult.returnDate = selectedDate;
      destinationSectorRound.departureDate = selectedDate;

      //Set Return Date...      
      this.setState({
        destinationSectorRound: destinationSectorRound,
        RoundTripCombainedFullSearchResult: RoundTripCombainedFullSearchResult,

      })
    }


  }


  ModifyRoundSearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originErrorR == false && this.state.destinationErrorR == false) {
      const {
        RoundTripOriginSearchTerm,
        RoundTripDestinationSearchTerm,
      } = this.state;
      if (!RoundTripOriginSearchTerm || !RoundTripDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = [];
        data.push(this.state.originSectorRound);
        data.push(this.state.destinationSectorRound);

        let onwardObj = {}, returnObj = {};
        onwardObj = this.state.originSectorRound;
        returnObj = this.state.destinationSectorRound;

        onwardObj.origin = this.state.RoundTripOriginAirport.cityCode
        onwardObj.origincountry = this.state.RoundTripOriginAirport.countryCode
        onwardObj.destination = this.state.RoundTripDestinationAirport.cityCode
        onwardObj.destinationcountry = this.state.RoundTripDestinationAirport.countryCode


        returnObj.origin = this.state.RoundTripDestinationAirport.cityCode
        returnObj.origincountry = this.state.RoundTripDestinationAirport.countryCode
        returnObj.destination = this.state.RoundTripOriginAirport.cityCode
        returnObj.destinationcountry = this.state.RoundTripOriginAirport.countryCode

        requestToSend.objSectorList = [onwardObj, returnObj]


        //TravelType...
        if (
          requestToSend.objSectorList[0].origincountry != "IN" ||
          requestToSend.objSectorList[0].destinationcountry != "IN"
        ) {
          requestToSend.traveltype = "I";
          travelTypeToCheck = "I";
        } else {
          requestToSend.traveltype = "D";
          travelTypeToCheck = "D";
        }

        this.setState({
          RequestBody: requestToSend,
        });




        //Navigation....
        if (type == "O") this.props.navigate("/Oneway");
        else if (type == "R") {
          if (travelTypeToCheck == "D") {
            let url = "/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
            this.props.navigate(url);
          }
          else {
            let url = "/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
            this.props.navigate(url);
            this.componentDidMount();
          }

        }
        else if (type == "S") this.props.navigate("/International");
        else if (type == "M") this.props.navigate("/Multicity");
      }

    }
  };
  //#endregion

  getSearchResult() {



    GetCRTSearch(this.requestBody).then((response) => {
      if (!CheckNetworkStatus()) {
        this.props.navigate('/issue');
      }
      if (!response.data.status) {
        if (response.data.responseMessage == "No Result Found") {
          this.setState({
            isResultFound: true
          })

        }
      }
      if (response.data.status) {
        //Initializing Object...
        let tempStopsCheck_onward = new Array(3).fill(false);
        let tempStopsCheck_return = new Array(3).fill(false);
        let tempDepartureOnwardTimesCheckbox = new Array(4).fill(false);
        let tempDepartureReturnTimesCheckbox = new Array(4).fill(false);
        let tempArrivalOnwardTimesCheckbox = new Array(4).fill(false);
        let tempArrivalReturnTimesCheckbox = new Array(4).fill(false);
        let avlAirlinesArray = new Array(response.data.objAvlairlineList.length).fill(false);

        this.setState({
          LowerFareResult: response.data.objlowfareList,
          ItinSearchResult: response.data.objItinList,
          ItinSearchResultCopy: response.data.objItinList,
          AvlAirlineResult: response.data.objAvlairlineList,
          RoundTripCombainedFullSearchResult: response.data,
          ItinSearchResultR: response.data.objItinListR,
          ItinSearchResultRCopy: response.data.objItinListR,
          ResponseBodyCopy: response.data,

          AirlineCheckbox: avlAirlinesArray,
          StopsCheckbox_Onward: tempStopsCheck_onward,
          StopsCheckbox_Return: tempStopsCheck_return,
          DepartureOnwardTimesCheckbox: tempDepartureOnwardTimesCheckbox,
          DepartureReturnTimesCheckbox: tempDepartureReturnTimesCheckbox,
          ArrivalOnwardTimesCheckbox: tempArrivalOnwardTimesCheckbox,
          ArrivalReturnTimesCheckbox: tempArrivalReturnTimesCheckbox,

          isLoaded: true,
        });


        this.setState({
          priceRangeMax: response.data.maximumFare,

          OnewayOriginSearchTerm: response.data.origin,
          OnewayDestinationSearchTerm: response.data.destination,

          RoundTripOriginSearchTerm: response.data.origin,
          RoundTripDestinationSearchTerm: response.data.destination,
        })
      }
    })
      .catch((error) => {
        if (error.response && error.response.status == 403 && !window.navigator.onLine) {
          this.props.navigate('/issue');
        }
        else if (error.response && error.response.data && (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut")) {
          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");
          localStorage.removeItem("NonUsertokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.removeItem("RS");

          toast.success('Unauthorized Access...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.props.navigate('/');

        }
        else {

          toast.error(`Something went wrong`, {
            position: 'bottom-center',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            onClose: () => {
              // Redirect to the previous page
              this.props.navigate('/');
            },
          });
        }


      });


  }

  getAllAirports() {
    GetAirportList().then((response) => {
      if (response.data.status) {
        this.setState({
          AirportJson: response.data.objAirportList,
        })
      }
    })
  }

  getFlightDetailsResult(id, fare, code, idR, fareR, codeR) {

    this.setState({
      FlightDetailsResult: [],
      objitinResult: [],
      objseglistResult: [],
      RequestFields: [],
    })


    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
      itinIdR: idR,
      fareIdR: fareR,
      providerCodeR: codeR,
    };

    GetFlightDetailsIR(data).then((response) => {
      if (response.data.status) {
        this.setState({
          objseglistResult: response.data.objitin.objseglist,
          objseglistResultR: response.data.objitinR.objseglist,
          objitinResult: response.data.objitin,
          objitinResultR: response.data.objitinR,
          FlightDetailsResult: response.data,
          RequestFields: data,
        });

      }
    })
      .catch((error) => {

        if (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut") {
          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");
          localStorage.removeItem("NonUsertokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.removeItem("RS");

          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.props.navigate('/');

        }
        else {
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      });
  }

  BookingRequest(id, fare, code, idR, fareR, codeR) {

    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
      itinIdR: idR,
      fareIdR: fareR,
      providerCodeR: codeR,
    };


    const secretKey = URL_EncodeKey(); // Secret key used for encryption
    const jsonData = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonData, secretKey).toString();
    const bookparam = encodeURIComponent(encryptedData);
    this.props.navigate(`/checkout?checkoutRequest=${bookparam}`)

  }

  shareOnWhatsApp = () => {
    this.setState({ isShared: true });
    const currentUrl = window.location.href;
    const { selectedItems, RoundTripCombainedFullSearchResult } = this.state;

    if (selectedItems.length == 0) {
      toast.error('Please select Itinerary...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    // Construct the message for each item in the list
    const message = selectedItems.map(item => `
Onward Flight: ${item.onwardDetails.airlineName} ${item.onwardDetails.flightDetails}
From: ${item.onwardDetails.sourceAirport} ${item.onwardDetails.departureDate} ${item.onwardDetails.departureTime}
To: ${item.onwardDetails.destinationAirport} ${item.onwardDetails.arrivalDate} ${item.onwardDetails.arrivalTime}

Return Flight: ${item.returnDetails.airlineName} ${item.returnDetails.flightDetails}
From: ${item.returnDetails.sourceAirport} ${item.returnDetails.departureDate} ${item.returnDetails.departureTime}
To: ${item.returnDetails.destinationAirport} ${item.returnDetails.arrivalDate} ${item.returnDetails.arrivalTime}
Travellers: Adult - ${RoundTripCombainedFullSearchResult.adult}, Child - ${RoundTripCombainedFullSearchResult.child}, Infant - ${RoundTripCombainedFullSearchResult.infant}
Fare: ₹${item.amount}
-------------------------------------------------
    `).join('\n');

    const fullMessage = `
${message}
    `;

    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(fullMessage)}`;

    // Open WhatsApp with the message
    window.open(url, '_blank');
  };

  shareByEmail = (recipient) => {
    const currentUrl = window.location.href;
    const { selectedItems, RoundTripCombainedFullSearchResult } = this.state;

    // Construct the body for each item in the list
    const body = selectedItems.map(item => `
      Onward Flight: ${item.onwardDetails.airlineName} ${item.onwardDetails.flightDetails}
      From: ${item.onwardDetails.sourceAirport} ${item.onwardDetails.departureDate} ${item.onwardDetails.departureTime}
      To: ${item.onwardDetails.destinationAirport} ${item.onwardDetails.arrivalDate} ${item.onwardDetails.arrivalTime}
            
      Return Flight: ${item.returnDetails.airlineName} ${item.returnDetails.flightDetails}
      From: ${item.returnDetails.sourceAirport} ${item.returnDetails.departureDate} ${item.returnDetails.departureTime}
      To: ${item.returnDetails.destinationAirport} ${item.returnDetails.arrivalDate} ${item.returnDetails.arrivalTime}
      Travellers: Adult - ${RoundTripCombainedFullSearchResult.adult}, Child - ${RoundTripCombainedFullSearchResult.child}, Infant - ${RoundTripCombainedFullSearchResult.infant}
      Fare: ₹${item.amount}
      -------------------------------------------------
          `).join('\n');

    const subject = `Flight Details`;
    const fullBody = `
${body}
Please click on the following link to view the full availability:
${currentUrl}
    `;

    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(recipient)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(fullBody)}`;

    // Open Gmail's compose page with the subject and body pre-filled
    window.open(gmailLink, '_blank');
  };

  handleShareViaEmail = () => {
    const recipient = this.state.inputValue;
    this.handleEmailClose();

    if (recipient) {
      // Validate the email format (simple regex)
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(recipient)) {
        this.shareByEmail(recipient);
      } else {
        toast.error('Please enter a valid email address...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } else {
      toast.error('Email address is required...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  handleFilter = (item) => {
    const newDate = item.date;

    // Modify the request body
    this.modifiedRequestBody = {
      ...this.state.RequestBody, // Copy the existing requestBody
      isDirect: true, // Update the isDirect property
      objSectorList: [
        {
          ...this.state.RequestBody.objSectorList[0], // Copy the first element of objSectorList
          departureDate: newDate, // Update the first departureDate
        },
        ...this.state.RequestBody.objSectorList.slice(1), // Keep the rest of the objSectorList unchanged
      ],
    };

    const Param = encodeURIComponent(JSON.stringify(this.modifiedRequestBody));
    this.props.navigate(`/InternationalRoundTrip?CRTSearchRequest=${Param}`);
    this.componentDidMount();
  };

  handleFilterReturn = (item) => {
    const newDate = item.date;

    // Modify the request body
    this.modifiedRequestBody = {
      ...this.state.RequestBody, // Copy the existing requestBody
      isDirect: true, // Update the isDirect property
      objSectorList: [
        ...this.state.RequestBody.objSectorList.slice(0, 1), // Keep the first element unchanged
        {
          ...this.state.RequestBody.objSectorList[1], // Copy the second element of objSectorList
          departureDate: newDate, // Update the second departureDate
        },
        ...this.state.RequestBody.objSectorList.slice(2), // Keep the rest of the objSectorList unchanged (if exists)
      ],
    };

    const Param = encodeURIComponent(JSON.stringify(this.modifiedRequestBody));
    this.props.navigate(`/InternationalRoundTrip?CRTSearchRequest=${Param}`);
    this.componentDidMount();
  };



  handlePrevFilter = (date, tripmode) => {
    const formatteddate = new Date(date);

    formatteddate.setDate(formatteddate.getDate() - 1);

    const newDate = formatteddate.toLocaleDateString('en-CA'); // Format as 'YYYY-MM-DD'

    const item = { date: newDate };
    if (tripmode == 'O') {
      this.handleFilter(item);
    }
    else if (tripmode == 'R') {
      this.handleFilterReturn(item);
    }


  };

  handleNextFilter = (date, tripmode) => {
    const formatteddate = new Date(date);

    formatteddate.setDate(formatteddate.getDate() + 1);

    const newDate = formatteddate.toLocaleDateString('en-CA'); // Format as 'YYYY-MM-DD'

    const item = { date: newDate };

    if (tripmode == 'O') {
      this.handleFilter(item);
    }
    else if (tripmode == 'R') {
      this.handleFilterReturn(item);
    }

  };

  render() {

    const { showCheckboxes, selectedItems } = this.state;
    //AUtoComplete...
    const { showValidationMessage, SameAirportsError } = this.state;
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    const { OnewayOriginSearchTerm, OnewayDestinationSearchTerm, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchTerm, RoundTripDestinationSearchTerm, RoundTripOriginSearchResults, RoundTripDestinationSearchResults, originError, destinationError, originErrorR, destinationErrorR } = this.state;
    const showSearchResultsOnewayOrigin = OnewayOriginSearchResults.length > 0;
    const showSearchResultsOnewayDestination = OnewayDestinationSearchResults.length > 0;

    const showSearchResultsRoundTripyOrigin = RoundTripOriginSearchResults.length > 0;
    const showSearchResultsRoundTripDestination = RoundTripDestinationSearchResults.length > 0;

    const showNoResultsMessageOnewayOrigin = RoundTripOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageOnewayDestination = OnewayDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;

    const showNoResultsMessageRoundTripOrigin = OnewayOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageRoundTripDestination = RoundTripDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;
    const whatsappTooltip = <Tooltip id="whatsapp-tooltip">Share on WhatsApp</Tooltip>;
    const emailTooltip = <Tooltip id="email-tooltip">Share via Email</Tooltip>;
    const shareTooltip = <Tooltip id="share-tooltip">Share Itinerary</Tooltip>;



    return (this.state.isLoaded && !this.state.isResultFound) ? (
      <div>
        {/* Modify search desktop view starts */}
        {(window.innerWidth > 700) && <div className="inner-search-area desk-modify">
          <div className="inner-search" id="inner-search">
            <div className="container">


              <div>
                <ModifySearchPopUp parentFunction={this.componentDidMount} />
              </div>

              <Modal show={this.state.showEmailModal} onHide={this.handleEmailClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title><strong>Email this search</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <div>
                    <input
                      type="text"
                      value={this.state.inputValue}
                      onChange={this.handleChangeEmail}
                      placeholder="Email ID"
                      className="form-control"
                    />
                  </div>

                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" className="w-100" onClick={() => this.handleShareViaEmail()} style={{ textTransform: 'none' }}>
                    Share
                  </Button>
                </Modal.Footer>

              </Modal>
            </div>
          </div>

        </div>}
        {/* Modify search desktop view ends */}

        {/* Modify search mobile view starts */}

        {(window.innerWidth < 700) && <div className="inner-search-area mob-modify">
          <div className="inner-search" id="inner-search" alt="">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="mob-mod-list">
                    <li>{
                      this.state.RoundTripCombainedFullSearchResult.origin
                    }</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg></li>
                    <li>{
                      this.state.RoundTripCombainedFullSearchResult
                        .destination
                    }</li>

                    <li>|</li>
                    <li>{this.formattedDate(
                      this.state.RoundTripCombainedFullSearchResult
                        .departureDate
                    )} - {this.formattedDate(
                      this.state.RoundTripCombainedFullSearchResult
                        .returnDate
                    )}</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg> {
                        this.state.RoundTripCombainedFullSearchResult.adult +
                        this.state.RoundTripCombainedFullSearchResult.child +
                        this.state.RoundTripCombainedFullSearchResult.infant
                      }</li>
                    <li> <a className="mod-btn-mob" data-bs-toggle="modal" data-bs-target="#modifyModal" >MODIFY</a></li>
                  </ul>






                </div>


              </div>
            </div>
          </div>
        </div>}

        <div
          className="modal fade"
          id="modifyModal"
          tabIndex={-1}
          aria-labelledby="modifyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Modify Search
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <ModifySearchPopUp parentFunction={this.componentDidMount} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">

              {/* Sidebar desktop starts */}
              <div className="side-bar side-bar-desk">
                <div className="indv-scroll" id="scrollbarind">
                  <div className="bg-white" id="sidebar-wrapper">
                    {/*Airline Price area starts*/}
                    <div className="airline-area">
                      <div className="d-flex flex-row reset-area">
                        <div>&nbsp;</div>
                        <span className="reset-filter" onClick={() => this.resetFilter()}>Reset filters</span>
                      </div>
                      <div className="side-bar-title">
                        <h4>Airlines</h4>
                      </div>
                      {this.state.AvlAirlineResult &&
                        this.state.AvlAirlineResult.length > 0 ? (
                        <form className="airline" id="scrollbar1">
                          {this.state.AvlAirlineResult.map((item, index) => (
                            <div className="side-loop" key={index}>
                              <label style={{ width: '100%', fontSize: '1.1em' }}>
                                <div className="d-flex airline-price mb-2">
                                  <div className="flex-row">
                                    <img
                                      src={`assets/img/logos/${item.airlineCode}.gif`}
                                      width={30}
                                    />
                                  </div>
                                  <div className="flex-row pt-2 pl-5 me-auto">
                                    <h6>
                                      {item.airlineName}
                                      <span>({item.count})</span>
                                    </h6>
                                  </div>
                                  <div className="flex-row pt-2 mr-10">
                                    <h6>₹ {Math.round(item.minAmount)}</h6>
                                  </div>
                                  <div className="air-check-box d-flex align-items-center">
                                    <input type="checkbox" checked={this.state.AirlineCheckbox[index]} className="form-control" value={item.airlineName} onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}

                        </form>
                      ) : (
                        <div className="p-1">
                          <img src="assets/img/flight2.svg" width={40} />
                          Sorry,no airlines found
                        </div>
                      )}
                    </div>
                    {/*Airline Price area ends*/}
                    {/*Stops area starts*/}
                    <div className="stops-area">
                      <div className="side-bar-title">
                        <h4>Stops</h4>
                      </div>
                      <div id="stop-area-on">
                        <p className="no-margin small-text">Onward</p>
                        <input
                          type="checkbox"
                          id="non-stop"
                          name="non-stop"
                          checked={this.state.StopsCheckbox_Onward[0]}
                          defaultValue="0" onChange={(e) => this.stopsChanged(e, 0)}
                        />
                        <label htmlFor="non-stop">
                          0<br />
                          Non-stop
                        </label>
                        <input
                          type="checkbox"
                          id="one-stop"
                          name="one-stop"
                          checked={this.state.StopsCheckbox_Onward[1]}
                          defaultValue="1" onChange={(e) => this.stopsChanged(e, 1)}
                        />
                        <label htmlFor="one-stop">
                          1<br />
                          stop
                        </label>
                        <input
                          type="checkbox"
                          id="oneplus-stop"
                          name="oneplus-stop"
                          checked={this.state.StopsCheckbox_Onward[2]}
                          defaultValue="2" onChange={(e) => this.stopsChanged(e, 2)}
                        />
                        <label htmlFor="oneplus-stop">
                          1+
                          <br />
                          stop
                        </label>
                      </div>
                      <div id="stop-area-re">
                        <p className="no-margin small-text">Return</p>
                        <input
                          type="checkbox"
                          id="non-stop-re"
                          name="non-stop-re"
                          checked={this.state.StopsCheckbox_Return[0]}
                          defaultValue="0" onChange={(e) => this.stopsReturnChanged(e, 0)}
                        />
                        <label htmlFor="non-stop-re">
                          0<br />
                          Non-stop
                        </label>
                        <input
                          type="checkbox"
                          id="one-stop-re"
                          name="one-stop-re"
                          checked={this.state.StopsCheckbox_Return[1]}
                          defaultValue="1" onChange={(e) => this.stopsReturnChanged(e, 1)}
                        />
                        <label htmlFor="one-stop-re">
                          1<br />
                          stop
                        </label>
                        <input
                          type="checkbox"
                          id="oneplus-stop-re"
                          name="oneplus-stop-re"
                          checked={this.state.StopsCheckbox_Return[2]}
                          defaultValue="2" onChange={(e) => this.stopsReturnChanged(e, 2)}
                        />
                        <label htmlFor="oneplus-stop-re">
                          1+
                          <br />
                          stop
                        </label>
                      </div>
                    </div>
                    {/*Stops area ends */}
                    {/*Departure time area starts */}
                    <div className="departure-area">
                      <div className="side-bar-title">
                        <h4>Departure Time</h4>
                      </div>
                      <div id="departure-time-on">
                        <p className="no-margin small-text">Onward</p>
                        <input
                          type="checkbox"
                          id="chk1"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.DepartureOnwardTimesCheckbox[0]}
                          onChange={(e) => this.departreTime(e, 0)}
                        />
                        <label htmlFor="chk1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          Before
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk2"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.DepartureOnwardTimesCheckbox[1]}
                          onChange={(e) => this.departreTime(e, 1)}
                        />
                        <label htmlFor="chk2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk3"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.DepartureOnwardTimesCheckbox[2]}
                          onChange={(e) => this.departreTime(e, 2)}
                        />
                        <label htmlFor="chk3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk4"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.DepartureOnwardTimesCheckbox[3]}
                          onChange={(e) => this.departreTime(e, 3)}
                        />
                        <label htmlFor="chk4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          AFTER
                          <br />
                          6PM
                        </label>
                      </div>
                      <div id="departure-time-re">
                        <p className="no-margin small-text">Return</p>
                        <input type="checkbox" id="chk5" name="chkD" defaultValue="before6" checked={this.state.DepartureReturnTimesCheckbox[0]} onChange={(e) => this.departureTimeReturn(e, 0)} />
                        <label htmlFor="chk5"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk6" name="chkD" defaultValue="6to12" checked={this.state.DepartureReturnTimesCheckbox[1]} onChange={(e) => this.departureTimeReturn(e, 1)} />
                        <label htmlFor="chk6"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk7" name="chkD" defaultValue="12to6" checked={this.state.DepartureReturnTimesCheckbox[2]} onChange={(e) => this.departureTimeReturn(e, 2)} />
                        <label htmlFor="chk7"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk8" name="chkD" defaultValue="after6" checked={this.state.DepartureReturnTimesCheckbox[3]} onChange={(e) => this.departureTimeReturn(e, 3)} />
                        <label htmlFor="chk8"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>
                    </div>
                    {/*Departure time area ends */}
                    {/*Arrival time area starts */}
                    <div className="arrival-area">
                      <div className="side-bar-title">
                        <h4>Arrival Time</h4>
                      </div>
                      <div id="arrival-time-on">
                        <p className="no-margin small-text">Onward</p>
                        <input
                          type="checkbox"
                          id="chk01"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.ArrivalOnwardTimesCheckbox[0]}
                          onChange={(e) => this.arrivalTime(e, 0)}
                        />
                        <label htmlFor="chk01">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          Before
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk02"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.ArrivalOnwardTimesCheckbox[1]}
                          onChange={(e) => this.arrivalTime(e, 1)}
                        />
                        <label htmlFor="chk02">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk03"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.ArrivalOnwardTimesCheckbox[2]}
                          onChange={(e) => this.arrivalTime(e, 2)}
                        />
                        <label htmlFor="chk03">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk04"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.ArrivalOnwardTimesCheckbox[3]}
                          onChange={(e) => this.arrivalTime(e, 3)}
                        />
                        <label htmlFor="chk04">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          AFTER
                          <br />
                          6PM
                        </label>
                      </div>

                      <div id="arrival-time-re">
                        <p className="no-margin small-text">Return</p>
                        <input type="checkbox" id="chk05" name="chkR" defaultValue="before6" checked={this.state.ArrivalReturnTimesCheckbox[0]} onChange={(e) => this.arrivalTimeReturn(e, 0)} />
                        <label htmlFor="chk05"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk06" name="chkR" defaultValue="6to12" checked={this.state.ArrivalReturnTimesCheckbox[1]} onChange={(e) => this.arrivalTimeReturn(e, 1)} />
                        <label htmlFor="chk06"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk07" name="chkR" defaultValue="12to6" checked={this.state.ArrivalReturnTimesCheckbox[2]} onChange={(e) => this.arrivalTimeReturn(e, 2)} />
                        <label htmlFor="chk07"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk08" name="chkR" defaultValue="after6" checked={this.state.ArrivalReturnTimesCheckbox[3]} onChange={(e) => this.arrivalTimeReturn(e, 3)} />
                        <label htmlFor="chk08"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>
                    </div>
                    {/*Arrival time area ends */}
                    {/*refund/non refund selection */}
                    {/*Price range slider */}
                    {(this.state.ResponseBodyCopy.minimumFare != this.state.ResponseBodyCopy.maximumFare) && <div>
                      <div className="price-slider-area">
                        <div className="side-bar-title">
                          <h4>Price</h4>
                        </div>

                        <ReactSlider
                          min={Math.round(this.state.ResponseBodyCopy.minimumFare)}
                          max={Math.round(this.state.ResponseBodyCopy.maximumFare)}
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          defaultValue={[parseInt(Math.round(this.state.priceRangeMin)), parseInt(Math.round(this.state.priceRangeMax))]}
                          value={[this.state.priceRangeMin, this.state.priceRangeMax]}
                          ariaLabel={['Lower thumb', 'Upper thumb']}
                          ariaValuetext={state => `Thumb value ${state.valueNow}`}
                          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                          pearling
                          onChange={(e) => this.priceRangeChange(e)}
                        />
                      </div>

                    </div>
                    }
                    {/*Aircraft area starts */}

                    {/*Aircraft area ends */}
                  </div>
                </div>
              </div>
              {/* Sidebar desktop ends */}

              {/* Sidebar mobile starts */}

              <div className="side-bar side-bar-mob">
                <div className="accordion accordion-flush" id="accordionFilter">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Filters
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFilter"
                    >
                      <div className="accordion-body">

                        <div className="bg-white" id="sidebar-wrapper">
                          {/*Airline Price area starts*/}
                          <div className="airline-area">
                            <div className="d-flex flex-row reset-area">

                              <span className="reset-filter" onClick={() => this.resetFilter()}>Reset filters</span>
                            </div>
                            <div className="side-bar-title">
                              <h4>Airlines</h4>
                            </div>
                            {this.state.AvlAirlineResult &&
                              this.state.AvlAirlineResult.length > 0 ? (
                              <form className="airline" id="scrollbar1">
                                {this.state.AvlAirlineResult.map((item, index) => (
                                  <div className="side-loop" key={index}>
                                    <label style={{ width: '100%', fontSize: '1.1em' }}>
                                      <div className="d-flex airline-price mb-3">
                                        <div className="flex-row">
                                          <img
                                            src={`assets/img/logos/${item.airlineCode}.gif`}
                                            width={30}
                                          />
                                        </div>
                                        <div className="flex-row pt-2 pl-5 me-auto">
                                          <h6>
                                            {item.airlineName}
                                            <span>({item.count})</span>
                                          </h6>
                                        </div>
                                        <div className="flex-row pt-2 mr-10">
                                          <h6>₹ {Math.round(item.minAmount)}</h6>
                                        </div>
                                        <div className="air-check-box d-flex align-items-center">
                                          <input type="checkbox" checked={this.state.AirlineCheckbox[index]} className="form-control" value={item.airlineName} onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                ))}

                              </form>
                            ) : (
                              <div className="p-1">
                                <img src="assets/img/flight2.svg" width={40} />
                                Sorry,no airlines found
                              </div>
                            )}
                          </div>
                          {/*Airline Price area ends*/}
                          {/*Stops area starts*/}
                          <div className="stops-area">
                            <div className="side-bar-title">
                              <h4>Stops</h4>
                            </div>
                            <div id="stop-area-on">
                              <p className="no-margin small-text">Onward</p>
                              <input
                                type="checkbox"
                                id="non-stop"
                                name="non-stop"
                                checked={this.state.StopsCheckbox_Onward[0]}
                                defaultValue="0" onChange={(e) => this.stopsChanged(e, 0)}
                              />
                              <label htmlFor="non-stop">
                                0<br />
                                Non-stop
                              </label>
                              <input
                                type="checkbox"
                                id="one-stop"
                                name="one-stop"
                                checked={this.state.StopsCheckbox_Onward[1]}
                                defaultValue="1" onChange={(e) => this.stopsChanged(e, 1)}
                              />
                              <label htmlFor="one-stop">
                                1<br />
                                stop
                              </label>
                              <input
                                type="checkbox"
                                id="oneplus-stop"
                                name="oneplus-stop"
                                checked={this.state.StopsCheckbox_Onward[2]}
                                defaultValue="2" onChange={(e) => this.stopsChanged(e, 2)}
                              />
                              <label htmlFor="oneplus-stop">
                                1+
                                <br />
                                stop
                              </label>
                            </div>
                            <div id="stop-area-re">
                              <p className="no-margin small-text">Return</p>
                              <input
                                type="checkbox"
                                id="non-stop-re"
                                name="non-stop-re"
                                checked={this.state.StopsCheckbox_Return[0]}
                                defaultValue="0" onChange={(e) => this.stopsReturnChanged(e, 0)}
                              />
                              <label htmlFor="non-stop-re">
                                0<br />
                                Non-stop
                              </label>
                              <input
                                type="checkbox"
                                id="one-stop-re"
                                name="one-stop-re"
                                checked={this.state.StopsCheckbox_Return[1]}
                                defaultValue="1" onChange={(e) => this.stopsReturnChanged(e, 1)}
                              />
                              <label htmlFor="one-stop-re">
                                1<br />
                                stop
                              </label>
                              <input
                                type="checkbox"
                                id="oneplus-stop-re"
                                name="oneplus-stop-re"
                                checked={this.state.StopsCheckbox_Return[2]}
                                defaultValue="2" onChange={(e) => this.stopsReturnChanged(e, 2)}
                              />
                              <label htmlFor="oneplus-stop-re">
                                1+
                                <br />
                                stop
                              </label>
                            </div>
                          </div>
                          {/*Stops area ends */}
                          {/*Departure time area starts */}
                          <div className="departure-area">
                            <div className="side-bar-title">
                              <h4>Departure Time</h4>
                            </div>
                            <div id="departure-time-on">
                              <p className="no-margin small-text">Onward</p>
                              <input
                                type="checkbox"
                                id="chk1"
                                name="chk"
                                defaultValue="before6"
                                checked={this.state.DepartureOnwardTimesCheckbox[0]}
                                onChange={(e) => this.departreTime(e, 0)}
                              />
                              <label htmlFor="chk1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-alt-high"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg>
                                <br />
                                Before
                                <br />
                                6AM
                              </label>
                              <input
                                type="checkbox"
                                id="chk2"
                                name="chk"
                                defaultValue="6to12"
                                checked={this.state.DepartureOnwardTimesCheckbox[1]}
                                onChange={(e) => this.departreTime(e, 1)}
                              />
                              <label htmlFor="chk2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-high"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg>
                                <br />
                                6AM-
                                <br />
                                12PM
                              </label>
                              <input
                                type="checkbox"
                                id="chk3"
                                name="chk"
                                defaultValue="12to6"
                                checked={this.state.DepartureOnwardTimesCheckbox[2]}
                                onChange={(e) => this.departreTime(e, 2)}
                              />
                              <label htmlFor="chk3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-high-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg>
                                <br />
                                12PM-
                                <br />
                                6PM
                              </label>
                              <input
                                type="checkbox"
                                id="chk4"
                                name="chk"
                                defaultValue="after6"
                                checked={this.state.DepartureOnwardTimesCheckbox[3]}
                                onChange={(e) => this.departreTime(e, 3)}
                              />
                              <label htmlFor="chk4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-moon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg>
                                <br />
                                AFTER
                                <br />
                                6PM
                              </label>
                            </div>
                            <div id="departure-time-re">
                              <p className="no-margin small-text">Return</p>
                              <input type="checkbox" id="chk5" name="chkD" defaultValue="before6" checked={this.state.DepartureReturnTimesCheckbox[0]} onChange={(e) => this.departureTimeReturn(e, 0)} />
                              <label htmlFor="chk5"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                              </svg><br />Before<br />6AM</label>
                              <input type="checkbox" id="chk6" name="chkD" defaultValue="6to12" checked={this.state.DepartureReturnTimesCheckbox[1]} onChange={(e) => this.departureTimeReturn(e, 1)} />
                              <label htmlFor="chk6"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                              </svg><br />6AM-<br />12PM</label>
                              <input type="checkbox" id="chk7" name="chkD" defaultValue="12to6" checked={this.state.DepartureReturnTimesCheckbox[2]} onChange={(e) => this.departureTimeReturn(e, 2)} />
                              <label htmlFor="chk7"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                              </svg><br />12PM-<br />6PM</label>
                              <input type="checkbox" id="chk8" name="chkD" defaultValue="after6" checked={this.state.DepartureReturnTimesCheckbox[3]} onChange={(e) => this.departureTimeReturn(e, 3)} />
                              <label htmlFor="chk8"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                              </svg><br />AFTER<br />6PM</label>
                            </div>
                          </div>
                          {/*Departure time area ends */}
                          {/*Arrival time area starts */}
                          <div className="arrival-area">
                            <div className="side-bar-title">
                              <h4>Arrival Time</h4>
                            </div>
                            <div id="arrival-time-on">
                              <p className="no-margin small-text">Onward</p>
                              <input
                                type="checkbox"
                                id="chk01"
                                name="chk"
                                defaultValue="before6"
                                checked={this.state.ArrivalOnwardTimesCheckbox[0]}
                                onChange={(e) => this.arrivalTime(e, 0)}
                              />
                              <label htmlFor="chk01">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-alt-high"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg>
                                <br />
                                Before
                                <br />
                                6AM
                              </label>
                              <input
                                type="checkbox"
                                id="chk02"
                                name="chk"
                                defaultValue="6to12"
                                checked={this.state.ArrivalOnwardTimesCheckbox[1]}
                                onChange={(e) => this.arrivalTime(e, 1)}
                              />
                              <label htmlFor="chk02">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-high"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg>
                                <br />
                                6AM-
                                <br />
                                12PM
                              </label>
                              <input
                                type="checkbox"
                                id="chk03"
                                name="chk"
                                defaultValue="12to6"
                                checked={this.state.ArrivalOnwardTimesCheckbox[2]}
                                onChange={(e) => this.arrivalTime(e, 2)}
                              />
                              <label htmlFor="chk03">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-brightness-high-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg>
                                <br />
                                12PM-
                                <br />
                                6PM
                              </label>
                              <input
                                type="checkbox"
                                id="chk04"
                                name="chk"
                                defaultValue="after6"
                                checked={this.state.ArrivalOnwardTimesCheckbox[3]}
                                onChange={(e) => this.arrivalTime(e, 3)}
                              />
                              <label htmlFor="chk04">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={20}
                                  height={20}
                                  fill="currentColor"
                                  className="bi bi-moon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg>
                                <br />
                                AFTER
                                <br />
                                6PM
                              </label>
                            </div>

                            <div id="arrival-time-re">
                              <p className="no-margin small-text">Return</p>
                              <input type="checkbox" id="chk05" name="chkR" defaultValue="before6" checked={this.state.ArrivalReturnTimesCheckbox[0]} onChange={(e) => this.arrivalTimeReturn(e, 0)} />
                              <label htmlFor="chk05"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                              </svg><br />Before<br />6AM</label>
                              <input type="checkbox" id="chk06" name="chkR" defaultValue="6to12" checked={this.state.ArrivalReturnTimesCheckbox[1]} onChange={(e) => this.arrivalTimeReturn(e, 1)} />
                              <label htmlFor="chk06"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                              </svg><br />6AM-<br />12PM</label>
                              <input type="checkbox" id="chk07" name="chkR" defaultValue="12to6" checked={this.state.ArrivalReturnTimesCheckbox[2]} onChange={(e) => this.arrivalTimeReturn(e, 2)} />
                              <label htmlFor="chk07"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                              </svg><br />12PM-<br />6PM</label>
                              <input type="checkbox" id="chk08" name="chkR" defaultValue="after6" checked={this.state.ArrivalReturnTimesCheckbox[3]} onChange={(e) => this.arrivalTimeReturn(e, 3)} />
                              <label htmlFor="chk08"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                              </svg><br />AFTER<br />6PM</label>
                            </div>
                          </div>
                          {/*Arrival time area ends */}
                          {/*refund/non refund selection */}

                          {/*Price range slider */}
                          {(this.state.ResponseBodyCopy.minimumFare != this.state.ResponseBodyCopy.maximumFare) && <div>
                            <div className="price-slider-area">
                              <div className="side-bar-title">
                                <h4>Price</h4>
                              </div>

                              <ReactSlider
                                min={Math.floor(this.state.ResponseBodyCopy.minimumFare)}
                                max={Math.round(this.state.ResponseBodyCopy.maximumFare)}
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                defaultValue={[parseInt(Math.round(this.state.priceRangeMin)), parseInt(Math.round(this.state.priceRangeMax))]}
                                value={[this.state.priceRangeMin, this.state.priceRangeMax]}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                pearling
                                onChange={(e) => this.priceRangeChange(e)}
                              />
                            </div>

                          </div>
                          }
                          {/*Aircraft area starts */}

                          {/*Aircraft area ends */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              {/* Sidebar mobile ends */}


            </div>
            <div className="col-lg-9 col-md-9">
              <div className="top-sort-area position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-row">
                    <form id="top-sort-form">
                      <input
                        type="radio"
                        id="cheapest"
                        name="sortOption"
                        defaultValue="cheapest"
                        checked={this.state.isCheapestActive}
                        onChange={(e) => this.sortArray(e)}
                      />
                      <label htmlFor="cheapest">
                        <span className="selection">
                          <img src="assets/img/cheapest.svg" width={25} />
                        </span>{" "}
                        CHEAPEST
                      </label>
                      <input
                        type="radio"
                        id="fastest"
                        name="sortOption"
                        defaultValue="fastest"
                        checked={this.state.isFastestActive}
                        onChange={(e) => this.sortArray(e)}
                      />
                      <label htmlFor="fastest">
                        <span className="selection">
                          <img src="assets/img/fastest.svg" width={25} />
                        </span>{" "}
                        FASTEST
                      </label>
                      <input
                        type="radio"
                        id="earliest"
                        name="sortOption"
                        defaultValue="earliest"
                        checked={this.state.isEarlierActive}
                        onChange={(e) => this.sortArray(e)}
                      />
                      <label htmlFor="earliest">
                        <span className="selection">
                          <img src="assets/img/earliest.svg" width={25} />
                        </span>{" "}
                        EARLIEST
                      </label>
                    </form>
                  </div>
                </div>
                <OverlayTrigger placement="top" overlay={shareTooltip} className="custom-tooltip">
                  <button onClick={this.handleShareButtonClick} className="it_share_btn">
                    {showCheckboxes ? 'X' : (
                      <svg
                        fill="#ffffff"
                        width="15px"
                        height="15px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#ffffff"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                          <path d="M8.137,11.631a.908.908,0,0,1-.17.038A3.016,3.016,0,0,1,8,12a2.962,2.962,0,0,1-.033.328.949.949,0,0,1,.173.042L16.9,16.485a.991.991,0,0,1,.242.18A2.969,2.969,0,0,1,19,16a3.037,3.037,0,1,1-2.911,2.306c-.014-.006-.029,0-.044-.01L7.289,14.181a.953.953,0,0,1-.134-.1,3,3,0,1,1,0-4.162,1,1,0,0,1,.133-.1L16.045,5.7c.014-.007.029,0,.044-.011A2.93,2.93,0,0,1,16,5a3,3,0,1,1,3,3,2.969,2.969,0,0,1-1.862-.665,1.03,1.03,0,0,1-.242.18Z" />
                        </g>
                      </svg>
                    )}
                  </button>
                </OverlayTrigger>
                {showCheckboxes && (
                  <div className="share-show">
                    <>
                      <OverlayTrigger placement="top" overlay={whatsappTooltip}>
                        <img src="assets/img/whatsapp.png" width={30} onClick={this.shareOnWhatsApp} />
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={emailTooltip}>
                        <img src="assets/img/mail.png" width={30} onClick={this.handleEmailShow} />
                      </OverlayTrigger>
                    </>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-6 col-sm-16">
                  <div className="next-prev-block d-flex">
                    <div className="mr-10">
                      <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripCombainedFullSearchResult.departureDate, 'O')}>Onward Prev Day</button>
                    </div>
                    <div>
                      <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripCombainedFullSearchResult.returnDate, 'R')}>Return Prev Day</button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-6 col-sm-6">
                  <div className="next-prev-block d-flex justify-content-end">

                    <div className="mr-10">
                      <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripCombainedFullSearchResult.departureDate, 'O')}>Onward Next Day</button>
                    </div>
                    <div>
                      <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripCombainedFullSearchResult.returnDate, 'R')}>Return Next Day</button>
                    </div>
                  </div>

                </div>
              </div>




              {/*One way result*/}
              {this.state.ItinSearchResult &&
                this.state.ItinSearchResult.length > 0 ? (
                <div>
                  {this.state.ItinSearchResult.map((item, index) => {

                    let arrivalTime = "";
                    let arrivalTimeR = "";


                    if (parseInt(item.onwardDetails.arrivalTime.split(":")[0]) < 6) {
                      arrivalTime = "before6";
                    }
                    else if (parseInt(item.onwardDetails.arrivalTime.split(":")[0]) >= 6 && parseInt(item.onwardDetails.arrivalTime.split(":")[0]) < 12) {
                      arrivalTime = "6to12";
                    }
                    else if (parseInt(item.onwardDetails.arrivalTime.split(":")[0]) >= 12 && parseInt(item.onwardDetails.arrivalTime.split(":")[0]) < 18) {
                      arrivalTime = "12to6";
                    }
                    else if (parseInt(item.onwardDetails.arrivalTime.split(":")[0]) >= 18) {
                      arrivalTime = "after6";
                    }

                    if (parseInt(item.returnDetails.arrivalTime.split(":")[0]) < 6) {
                      arrivalTimeR = "before6";
                    }
                    else if (parseInt(item.returnDetails.arrivalTime.split(":")[0]) >= 6 && parseInt(item.returnDetails.arrivalTime.split(":")[0]) < 12) {
                      arrivalTimeR = "6to12";
                    }
                    else if (parseInt(item.returnDetails.arrivalTime.split(":")[0]) >= 12 && parseInt(item.returnDetails.arrivalTime.split(":")[0]) < 18) {
                      arrivalTimeR = "12to6";
                    }
                    else if (parseInt(item.returnDetails.arrivalTime.split(":")[0]) >= 18) {
                      arrivalTimeR = "after6";
                    }



                    //Arrival Time...
                    let departureTime = "";
                    let departureTimeR = "";


                    if (parseInt(item.onwardDetails.departureTime.split(":")[0]) < 6) {
                      departureTime = "before6";
                    }
                    else if (parseInt(item.onwardDetails.departureTime.split(":")[0]) >= 6 && parseInt(item.onwardDetails.departureTime.split(":")[0]) < 12) {
                      departureTime = "6to12";
                    }
                    else if (parseInt(item.onwardDetails.departureTime.split(":")[0]) >= 12 && parseInt(item.onwardDetails.departureTime.split(":")[0]) < 18) {
                      departureTime = "12to6";
                    }
                    else if (parseInt(item.onwardDetails.departureTime.split(":")[0]) >= 18) {
                      departureTime = "after6";
                    }

                    if (parseInt(item.returnDetails.departureTime.split(":")[0]) < 6) {
                      departureTimeR = "before6";
                    }
                    else if (parseInt(item.returnDetails.departureTime.split(":")[0]) >= 6 && parseInt(item.returnDetails.departureTime.split(":")[0]) < 12) {
                      departureTimeR = "6to12";
                    }
                    else if (parseInt(item.returnDetails.departureTime.split(":")[0]) >= 12 && parseInt(item.returnDetails.departureTime.split(":")[0]) < 18) {
                      departureTimeR = "12to6";
                    }
                    else if (parseInt(item.returnDetails.departureTime.split(":")[0]) >= 18) {
                      departureTimeR = "after6";
                    }


                    return <div key={index}>



                      {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.onwardDetails.airlineName))) && this.state.priceRangeMin <= item.netAmount && this.state.priceRangeMax >= item.netAmount && (this.state.Stops.length == 0 || (this.state.Stops.length > 0 && this.state.Stops.includes(item.onwardDetails.noofStop.toString()))) && (this.state.DepartureTimes.length == 0 || (this.state.DepartureTimes.length > 0 && this.state.DepartureTimes.includes(departureTime))) && (this.state.ArrivalTimes.length == 0 || (this.state.ArrivalTimes.length > 0 && this.state.ArrivalTimes.includes(arrivalTime))) &&
                        (this.state.StopsReturn.length == 0 || (this.state.StopsReturn.length > 0 && this.state.StopsReturn.includes(item.returnDetails.noofStop.toString()))) && (this.state.DepartureTimesReturn.length == 0 || (this.state.DepartureTimesReturn.length > 0 && this.state.DepartureTimesReturn.includes(departureTimeR))) && (this.state.ArrivalTimesReturn.length == 0 || (this.state.ArrivalTimesReturn.length > 0 && this.state.ArrivalTimesReturn.includes(arrivalTimeR))) &&

                        <div className="deal-wrapper">
                          {/* combined result Web view starts*/}
                          <div className="flight-route-display oneway-desk">
                            {showCheckboxes && (
                              <input
                                type="checkbox"
                                checked={selectedItems.some(selectedItem => selectedItem.index === index)}
                                onChange={() => this.handleCheckboxChange(item, index)} className="share-check"
                              />
                            )}
                            <div className="row">
                              <div className="col-lg-8 col-md-8">
                                <div className="row round-inter">
                                  <div className="airline-company col-lg-3 col-md-3 text-center">
                                    <img
                                      src={`assets/img/logos/${item.onwardDetails.airlineCode}.gif`}
                                    />
                                    <p className="small-text">
                                      {item.onwardDetails.airlineName}
                                      <br />
                                      {item.onwardDetails.flightDetails}
                                    </p>
                                  </div>
                                  <div className="route-details-area col-lg-3 col-md-3 text-right">
                                    <div className="row">
                                      <p className="small-text">
                                        {item.onwardDetails.source}
                                      </p>
                                      <h6 className="time-text">
                                        {item.onwardDetails.departureTime}
                                      </h6>
                                      <p className="small-text text-grey">
                                        {dateFormat(item.onwardDetails.departureDate, "dd-mm-yyyy")}
                                        <br />
                                        {item.onwardDetails.sourceAirport}
                                        {item.onwardDetails.depTerminal && item.onwardDetails.depTerminal.length > 0 && (
                                          <>
                                            <br />
                                            Terminal:{item.onwardDetails.depTerminal}
                                            <br />
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="travel-time col-lg-3 col-md-3 text-center">
                                    <p className="small-text text-grey">
                                      {item.onwardDetails.duration}
                                    </p>
                                    <span>
                                      <img src="assets/img/arrow.png" />
                                    </span>
                                    <p className="small-text text-grey">
                                      {item.onwardDetails.noofStop === 0
                                        ? "Non-Stop"
                                        : item.onwardDetails.noofStop + " Stop"}
                                    </p>
                                  </div>
                                  <div className="route-details-area col-lg-3 col-md-3 text-left">
                                    <div className="row">
                                      <p className="small-text">
                                        {item.onwardDetails.destination}
                                      </p>
                                      <h6 className="time-text">
                                        {item.onwardDetails.arrivalTime}
                                      </h6>
                                      <p className="small-text text-grey">
                                        {dateFormat(item.onwardDetails.arrivalDate, "dd-mm-yyyy")}
                                        <br />
                                        {item.onwardDetails.destinationAirport}
                                        {item.onwardDetails.arrTerminal && item.onwardDetails.arrTerminal.length > 0 && (
                                          <>
                                            <br />
                                            Terminal:{item.onwardDetails.arrTerminal}
                                            <br />
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="border-dashed" />

                                <div className="row align-items-center round-inter">
                                  <div className="airline-company col-lg-3 col-md-3 text-center">
                                    <img
                                      src={`assets/img/logos/${item.returnDetails.airlineCode}.gif`}
                                    />
                                    <p className="small-text">
                                      {item.returnDetails.airlineName}
                                      <br />
                                      {item.returnDetails.flightDetails}
                                    </p>
                                  </div>
                                  <div className="route-details-area col-lg-3 col-md-3 text-right">
                                    <div className="row">
                                      <p className="small-text">
                                        {item.returnDetails.source}
                                      </p>
                                      <h6 className="time-text">
                                        {item.returnDetails.departureTime}
                                      </h6>
                                      <p className="small-text text-grey">
                                        {dateFormat(item.returnDetails.departureDate, "dd-mm-yyyy")}
                                        <br />
                                        {item.returnDetails.sourceAirport}
                                        {item.returnDetails.depTerminal && item.returnDetails.depTerminal.length > 0 && (
                                          <>
                                            <br />
                                            Terminal:{item.returnDetails.depTerminal}
                                            <br />
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="travel-time col-lg-3 col-md-3 text-center">
                                    <p className="small-text text-grey">
                                      {item.returnDetails.duration}
                                    </p>
                                    <span>
                                      <img src="assets/img/arrow.png" />
                                    </span>
                                    <p className="small-text text-grey">
                                      {item.returnDetails.noofStop === 0
                                        ? "Non-Stop"
                                        : item.returnDetails.noofStop + " Stop"}
                                    </p>
                                  </div>
                                  <div className="route-details-area col-lg-3 col-md-3 text-left">
                                    <div className="row">
                                      <p className="small-text">
                                        {item.returnDetails.destination}
                                      </p>
                                      <h6 className="time-text">
                                        {item.returnDetails.arrivalTime}
                                      </h6>
                                      <p className="small-text text-grey">
                                        {dateFormat(item.returnDetails.arrivalDate, "dd-mm-yyyy")}
                                        <br />
                                        {item.returnDetails.destinationAirport}
                                        {item.returnDetails.arrTerminal && item.returnDetails.arrTerminal.length > 0 && (
                                          <>
                                            <br />
                                            Terminal:{item.returnDetails.arrTerminal}
                                            <br />
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 pt-5">
                                <div className="row align-items-center">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="flex-row">
                                      <span className="mr-10 text-grey">
                                      </span>
                                    </div>
                                    <div className="flex-row">
                                      {item.noofSeat !== 0 && (
                                        <p className="no-margin small-text"><img src="assets/img/seat.png" width={13} className="mr-5" />{item.noofSeat} Seats</p>
                                      )}
                                      <h4 className="price-text">
                                        ₹{Math.round(item.netAmount)}
                                      </h4>
                                      <p className="no-margin small-text">{item.fareName}</p>
                                      {item.pricingList && (
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Click here for more fares"
                                        >
                                          {item.pricingList.length > 1 ? (
                                            <a
                                              href=""
                                              data-bs-toggle="collapse"
                                              data-bs-target={'#panel-collapse' + `${index}`}
                                              aria-expanded="true"
                                              aria-controls="panel-collapse"
                                              className="more-fare-btn"
                                            >
                                              More fares
                                            </a>
                                          ) : <div></div>}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <a type="button" className="btn btn-primary book-btn" onClick={() => this.BookingRequest(item.itinId, item.fareId, item.providerCode, 0, 0, "")}>
                                      BOOK
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id={'panel-collapse' + `${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="panel-heading"
                            >
                              <div className="accordion-body relative-position">
                                <button type="button"
                                  href=""
                                  data-bs-toggle="collapse"
                                  data-bs-target={'#panel-collapse' + `${index}`}
                                  aria-expanded="true"
                                  aria-controls="panel-collapse"
                                  className="btn btn-close collapse-close-more"
                                >

                                </button>
                                <div className="row">
                                  {item.pricingList.map((item1, index1) => (
                                    <div className="col-lg-3 col-md-3" key={index1}>
                                      <div className="card">
                                        <label
                                          className="fare-selection"
                                        >
                                          <input
                                            name={`plan` + `${index}`}
                                            className="single-fare"
                                            id={`single1` + `${index1}`}
                                            type="radio"
                                            defaultChecked={index1 == 0}
                                            //onChange={() => { let data = this.state.ItinSearchResult; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName; data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.ResponseBodyCopy; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, ResponseBodyCopy:max})}; this.setState({ ItinSearchResult: data }) }}
                                            onChange={() => this.handleRadioChangeFare(index, item1)}
                                          />
                                          <div className="card-header text-center">
                                            <div style={{ width: 200 }}>{item1.fareName}</div>
                                          </div>
                                          <div className="card-body">
                                            <h4 className="no-margin text-center">
                                              {Math.round(item1.netAmount)}
                                            </h4>
                                            <table className="table">
                                              <tbody>
                                                <tr>
                                                  <td className="small-text">
                                                    <img
                                                      src="assets/img/hand.png"
                                                      width={30}
                                                    />{" "}
                                                    Hand Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.cabinBaggage}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="small-text">
                                                    <img
                                                      src="assets/img/check-in.png"
                                                      width={30}
                                                    />{" "}
                                                    Check-in Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.checkinBaggage}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  ))}

                                </div>
                              </div>
                            </div>

                            <div className="flight-details-area d-flex flex-row-reverse">
                              <div className="flex-row">
                                <p>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#flightdetails"
                                    onClick={() =>
                                      this.getFlightDetailsResult(
                                        item.itinId,
                                        item.fareId,
                                        item.providerCode,
                                        0,
                                        0,
                                        ""
                                      )
                                    }
                                  >
                                    Flight Details{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-caret-down-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                  </a>
                                </p>
                              </div>
                              <div className="flex-row">
                                {item.freeBaggage !== "" && (
                                  <p>
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-briefcase"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                    </svg>{" "}
                                    {item.freeBaggage}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* combined result Web view ends*/}

                          {/* Combined result Mobile view starts */}

                          <div className="flight-route-display oneway-mob">
                          {showCheckboxes && (
                              <input
                                type="checkbox"
                                checked={selectedItems.some(selectedItem => selectedItem.index === index)}
                                onChange={() => this.handleCheckboxChange(item, index)} className="share-check"
                              />
                            )}
                            <div className="round-inter">
                              <ul className="d-flex flex-row">
                                <li> <img
                                  src={`assets/img/logos/${item.returnDetails.airlineCode}.gif`} className="air-logo-mob"
                                /></li>
                                <li>{item.returnDetails.airlineName}</li>
                                <li>{item.returnDetails.flightDetails}</li>

                              </ul>

                              <div className="row">
                                <div className="route-details-area col-sm-4 col-4">

                                  <p className="small-text">
                                    {item.onwardDetails.source}
                                  </p>
                                  <h6 className="time-text">
                                    {item.onwardDetails.departureTime}
                                  </h6>
                                  <p className="small-text">
                                    {dateFormat(item.onwardDetails.departureDate, "dd-mm-yyyy")}
                                  </p>
                                  <p className="small-text">
                                    {item.onwardDetails.sourceAirport}
                                  </p>




                                </div>
                                <div className="travel-time col-sm-4 col-4">
                                  <p className="small-text text-grey">
                                    {item.onwardDetails.duration}
                                  </p>
                                  <span>
                                    <img src="assets/img/arrow.png" width={70} />
                                  </span>
                                  <p className="small-text text-grey">
                                    {item.onwardDetails.noofStop === 0
                                      ? "Non-Stop"
                                      : item.onwardDetails.noofStop + " Stop"}
                                  </p>
                                </div>
                                <div className="route-details-area col-sm-4 col-4">
                                  <div className="row">
                                    <p className="small-text">
                                      {item.onwardDetails.destination}
                                    </p>
                                    <h6 className="time-text">
                                      {item.onwardDetails.arrivalTime}
                                    </h6>
                                    <p className="small-text">
                                      {dateFormat(item.onwardDetails.arrivalDate, "dd-mm-yyyy")}
                                    </p>
                                    <p className="small-text">
                                      {item.onwardDetails.destinationAirport}
                                    </p>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-dashed"></div>
                            <div className="round-inter">
                              <ul className="d-flex flex-row">
                                <li>  <img
                                  src={`assets/img/logos/${item.returnDetails.airlineCode}.gif`} className="air-logo-mob"
                                /></li>
                                <li>{item.returnDetails.airlineName}</li>
                                <li>{item.returnDetails.flightDetails}</li>

                              </ul>
                              <div className="row">
                                <div className="route-details-area col-sm-4 col-4">

                                  <p className="small-text">
                                    {item.returnDetails.source}
                                  </p>
                                  <h6 className="time-text">
                                    {item.returnDetails.departureTime}
                                  </h6>
                                  <p className="small-text">
                                    {dateFormat(item.returnDetails.departureDate, "dd-mm-yyyy")}
                                  </p>
                                  <p className="small-text">{item.returnDetails.sourceAirport}</p>

                                </div>
                                <div className="travel-time col-sm-4 col-4">
                                  <p className="small-text">
                                    {item.returnDetails.duration}
                                  </p>
                                  <span>
                                    <img src="assets/img/arrow.png" width={70} />
                                  </span>
                                  <p className="small-text">
                                    {item.returnDetails.noofStop === 0
                                      ? "Non-Stop"
                                      : item.returnDetails.noofStop + " Stop"}
                                  </p>
                                </div>
                                <div className="route-details-area col-sm-4 col-4">
                                  <div className="row">
                                    <p className="small-text">
                                      {item.returnDetails.destination}
                                    </p>
                                    <h6 className="time-text">
                                      {item.returnDetails.arrivalTime}
                                    </h6>
                                    <p className="small-text">
                                      {dateFormat(item.returnDetails.arrivalDate, "dd-mm-yyyy")}
                                    </p>
                                    <p className="small-text">{item.returnDetails.destinationAirport}</p>



                                  </div>
                                </div>

                              </div>



                            </div>

                            <div className="col-lg-4 col-md-4">
                              <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  <div className="flex-row">
                                    <span className="mr-10 text-grey">
                                    </span>
                                  </div>
                                  <div className="flex-row">

                                    <h4 className="price-text">
                                      ₹{Math.round(item.netAmount)}
                                    </h4>
                                    {item.pricingList && (
                                      <div
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Click here for more fares"
                                      >
                                        {item.pricingList.length > 1 ? (
                                          <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#panel-collapse' + `${index}`}
                                            aria-expanded="true"
                                            aria-controls="panel-collapse"
                                            className="more-fare-btn"
                                          >
                                            More fares
                                          </a>
                                        ) : <div></div>}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <a type="button" className="btn btn-primary book-btn" onClick={() => this.BookingRequest(item.itinId, item.fareId, item.providerCode, 0, 0, "")}>
                                    BOOK
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              id={'panel-collapse' + `${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="panel-heading"
                            >
                              <div className="accordion-body relative-position">
                                <button type="button"
                                  href=""
                                  data-bs-toggle="collapse"
                                  data-bs-target={'#panel-collapse' + `${index}`}
                                  aria-expanded="true"
                                  aria-controls="panel-collapse"
                                  className="btn btn-close collapse-close-more"
                                >

                                </button>
                                <div className="row">
                                  {item.pricingList.map((item1, index1) => (
                                    <div className="col-lg-3 col-md-3" key={index1}>
                                      <div className="card">
                                        <label
                                          className="fare-selection"
                                        >
                                          <input
                                            name={`planMob` + `${index}`}
                                            className="single-fare"
                                            id={`single1` + `${index1}`}
                                            type="radio"
                                            defaultChecked={index1 == 0}
                                            //onChange={() => { let data = this.state.ItinSearchResult; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName; data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.ResponseBodyCopy; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, ResponseBodyCopy:max})}; this.setState({ ItinSearchResult: data }) }}
                                            onChange={() => this.handleRadioChangeFare(index, item1)}
                                          />
                                          <div className="card-header text-center">
                                            <div style={{ width: 200 }}>{item1.fareName}</div>
                                          </div>
                                          <div className="card-body">
                                            <h4 className="no-margin text-center">
                                              {Math.round(item1.netAmount)}
                                            </h4>
                                            <table className="table">
                                              <tbody>
                                                <tr>
                                                  <td className="small-text">
                                                    <img
                                                      src="assets/img/hand.png"
                                                      width={30}
                                                    />{" "}
                                                    Hand Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.cabinBaggage}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="small-text">
                                                    <img
                                                      src="assets/img/check-in.png"
                                                      width={30}
                                                    />{" "}
                                                    Check-in Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.checkinBaggage}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  ))}

                                </div>
                              </div>
                            </div>

                            <div className="flight-details-area d-flex flex-row-reverse">
                              <div className="flex-row">

                                <p>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#flightdetails"
                                    onClick={() =>
                                      this.getFlightDetailsResult(
                                        item.itinId,
                                        item.fareId,
                                        item.providerCode,
                                        0,
                                        0,
                                        ""
                                      )
                                    }
                                  >
                                    Flight Details{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-caret-down-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                  </a>
                                </p>
                              </div>
                              <div className="flex-row">
                                {item.freeBaggage !== "" && (
                                  <p>
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-briefcase"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                    </svg>{" "}
                                    {item.freeBaggage}
                                  </p>
                                )}
                              </div>
                              {item.noofSeat !== 0 && (
                                <p className="no-margin small-text"><img src="assets/img/seat.png" width={13} className="mr-5" />{item.noofSeat} Seats</p>
                              )}
                            </div>
                          </div>




                          {/* Combined result Mobile view ends */}


                        </div>




                      }





                      {/* No AIrline Selected...
      
         //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                    </div>
                  }


                  )}
                </div>
              ) : (
                <div></div>
              )}
              {/*One way result ends*/}
            </div>
          </div>
        </div>
        {/*## Footer starts ##*/}


        {/*Flight details popup*/}
        {/* Modal */}
        <div
          className="modal fade"
          id="flightdetails"
          tabIndex={-1}
          aria-labelledby="flightdetailsLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="flightdetailsLabel">
                  FLIGHT DETAILS
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="tab-content" id="ex1-content">
                  <div className="row">
                    <div className="col-lg-8 col-md-8">
                      <div className="col-lg-12 col-md-12" >
                        <div className="popup-route-details">
                          <h4>
                            {this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity}{" "}
                            <span>{this.state.objitinResult.departureDate}</span>{" "}
                            <span>{this.state.objitinResult.adultCheckinBaggage}</span>
                            <span>{this.state.objitinResult.adultCabinBaggage}</span>
                          </h4>
                        </div>
                        {this.state.objseglistResult.map((item, index) => (
                          <div
                            className="tab-pane fade show active"
                            id="ex1-tabs-1"
                            role="tabpanel"
                            aria-labelledby="ex1-tab-1"
                            key={index}
                          >
                            <h6>
                              <img
                                src={`assets/img/logos/${item.airlineCode}.gif`}
                                className="tab-air"
                                width={30}
                              />
                              {item.airlineName} {item.airlineCode} {item.flightNumber}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={25}
                                  height={25}
                                  fill="currentColor"
                                  className="bi bi-dot"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                              </span>
                              {item.airlineFlightClass}
                            </h6>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 dep">
                                <h4>
                                  {item.departureAirportCode}{" "}
                                  {item.departureTime}
                                </h4>

                                <p className="small-text">
                                  {item.departureDate}
                                </p>
                                <p className="small-text">
                                  {item.departureAirport}
                                </p>
                                {item.depTerminal && item.depTerminal.length > 0 && (
                                  <p className="small-text">
                                    Terminal:{item.depTerminal}
                                  </p>
                                )}
                                <p className="small-text">
                                  {item.departureCity}
                                </p>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                                <div className="flex-column">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={25}
                                    height={25}
                                    fill="currentColor"
                                    className="bi bi-clock text-grey"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                  </svg>
                                </div>
                                <div className="flex-column">
                                  <p className="small-text text-grey">
                                    {item.travelDuration}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 arr">
                                <h4>
                                  {item.arrivalAirportCode} {item.arrivalTime}
                                </h4>
                                <p className="small-text">{item.arrivalDate}</p>
                                <p className="small-text">
                                  {item.arrivalAirport}
                                </p>
                                {item.arrTerminal && item.arrTerminal.length > 0 && (
                                  <p className="small-text">
                                    Terminal:{item.arrTerminal}
                                  </p>
                                )}
                                <p className="small-text">{item.arrivalCity}</p>
                              </div>
                            </div>
                            {index !== this.state.objseglistResult.length - 1 ? (
                              <div className="layover">
                                Flight change at  {item.layoverSector} , layover of{" "}
                                {item.layoverTime}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ))}
                      </div>


                      <div className="col-lg-12 col-md-12" >

                        <div className="popup-route-details">
                          <h4>{this.state.objitinResultR.originCity} - {this.state.objitinResultR.destinationCity} <span>{this.state.objitinResultR.departureDate}</span> <span>{this.state.objitinResultR.adultCheckinBaggage}</span><span>{this.state.objitinResult.adultCabinBaggage}</span></h4>
                        </div>
                        {this.state.objseglistResultR.map((item, index) => (

                          <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" key={index} >
                            <h6><img src={`assets/img/logos/${item.airlineCode}.gif`} className="tab-air" width={30} />{item.airlineName} {item.airlineCode} {item.flightNumber}
                              <span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                              </svg></span>{item.airlineFlightClass}</h6>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 dep">
                                <h4>{item.departureAirportCode} {item.departureTime}</h4>
                                <p className="small-text">{item.departureDate}</p>
                                <p className="small-text">{item.departureAirport}</p>
                                {item.depTerminal && item.depTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.depTerminal}</p>
                                )}
                                <p className="small-text">{item.departureCity}</p>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                                <div className="flex-column"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-clock text-grey" viewBox="0 0 16 16">
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg></div>
                                <div className="flex-column">
                                  <p className="small-text text-grey">{item.travelDuration}</p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 arr">
                                <h4>{item.arrivalAirportCode} {item.arrivalTime}</h4>
                                <p className="small-text">{item.arrivalDate}</p>
                                <p className="small-text">{item.arrivalAirport}</p>
                                {item.arrTerminal && item.arrTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.arrTerminal}</p>
                                )}
                                <p className="small-text">{item.arrivalCity}</p>
                              </div>

                            </div>
                            {index !== this.state.objseglistResultR.length - 1 ? (
                              <div className="layover">
                                Flight change at {item.layoverSector} , layover of {item.layoverTime}
                              </div>
                            ) : <div></div>}


                          </div>

                        ))}
                      </div>

                    </div>
                    <div className="col-lg-4 col-md-4 d-flex flex-column summary-area">
                      <div className="fare-summery-area">

                        <nav>
                          <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-fare-tab" data-bs-toggle="tab" data-bs-target="#nav-fare" type="button" role="tab" aria-controls="nav-fare" aria-selected="true">Fare Summary</button>
                            <button className="nav-link" id="nav-bag-tab" data-bs-toggle="tab" data-bs-target="#nav-bag" type="button" role="tab" aria-controls="nav-bag" aria-selected="false">Baggage</button>

                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade show active" id="nav-fare" role="tabpanel" aria-labelledby="nav-fare-tab">
                            <table className="f-smry-table border-btm">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Base fare</th>
                                  <th>Fees & Tax</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult x {this.state.FlightDetailsResult.adult}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtTax)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child x {this.state.FlightDetailsResult.child}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdTax)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant x {this.state.FlightDetailsResult.infant}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infTax)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <table className="f-smry-table">
                              <tbody>
                                <tr>
                                  <td><h4>Total :</h4></td>

                                  <td align="right"><h4>{(this.state.FlightDetailsResult.totalFare) ? Math.round(this.state.FlightDetailsResult.totalFare) : "N/A"}</h4></td>
                                </tr>
                              </tbody>
                            </table>
                            <p className="no-margin text-white small-text">*All fares & seats are subject to availability at the time of booking</p>
                          </div>
                          <div className="tab-pane fade" id="nav-bag" role="tabpanel" aria-labelledby="nav-bag-tab">
                            <h4 className="text-white">{this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity}</h4>
                            <table className="f-smry-table">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Check-in</th>
                                  <th>Cabin</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult</td>
                                    <td>{this.state.objitinResult.adultCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.adultCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child</td>
                                    <td>{this.state.objitinResult.childCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.childCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant</td>
                                    <td>{this.state.objitinResult.infantCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.infantCabinBaggage}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                            <h4 className="text-white">{this.state.objitinResultR.originCity} - {this.state.objitinResultR.destinationCity}</h4>
                            <table className="f-smry-table">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Check-in</th>
                                  <th>Cabin</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult</td>
                                    <td>{this.state.objitinResultR.adultCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.adultCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child</td>
                                    <td>{this.state.objitinResultR.childCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.childCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant</td>
                                    <td>{this.state.objitinResultR.infantCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.infantCabinBaggage}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <p className="text-white no-margin small-text">*The information is just for reference. Please check with airline before check-in</p>
                          </div>

                        </div>


















                        <div className="popup-book-btn-area">

                          <button type="button" className="btn btn-primary button" onClick={() => this.BookingRequest(this.state.RequestFields.itinId, this.state.RequestFields.fareId, this.state.RequestFields.providerCode, 0, 0, "")} data-bs-dismiss="modal" aria-label="Close">BOOK</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>



                <div>
                </div>
              </div>
            </div>
          </div>
        </div>






      </div>
    ) : (!this.state.isLoaded && this.state.isResultFound) ? (
      <div>
        <div className="bg-white p-3">
          <div className="pt-6 align-items-center justify-content-center">
            <div className="text-center pt-5">
              <img src="assets/img/no-flight.png" className="no-flight" width={100} />
              <h4>Oops! No flights found</h4>
              <p>Sorry, There were no flights found for this route and date combination.<br />We suggest you modify your search and try again.</p>
              <Link to="/"><button className="btn btn-primary mb-5">Back to search</button></Link>
            </div>
          </div>
        </div>

      </div>
    ) : (
      <div>
        <div className="progress1 indeterminate"></div>
        <div className="loader">
          <div className="wait">
            <div className="typewriter">
              <div>
                <p>Finding the best options for you!</p>
              </div>
            </div>




          </div>

          <div className="plane1">
            <img src="assets/img/loader.gif" className="plane1-img" />
          </div>
          <div className="earth-wrapper">
            <div className="earth"></div>
          </div>

        </div>

      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <SearchResultCOmbainedRoundTrip {...props} navigate={navigate} />;
}

export default WithNavigate;
