import './App.css';
import { Outlet } from 'react-router-dom';
import Routers from './Routes/Routers';


function App() {
  return (
    <div>
      <Routers>
          <Outlet></Outlet>
      </Routers>
    </div>
  );
}

export default App;
