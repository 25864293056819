import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export default class TravelCalender extends Component {

  render() {
    return (
      <div>
          <div className="container">
              <div className="user-profile pt-3">
                  <h5>My Account</h5>
                  <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <Link to="/Profile"><button className="nav-link " type="button">Profile</button></Link>
                      <Link to="/MyTrips"><button className="nav-link"type="button">My Trips</button></Link>
                      <Link to="/AccountStatement"><button className="nav-link" i type="button" >Account Statement</button></Link>
                      {/* <Link to="/TravelCalender"><button className="nav-link active"  type="button">Travel Calendar</button></Link> */}
                      </div>
                  </nav>
                  <div className="tab-content pt-3" id="nav-tabContent">        
                      <div >
                          <div className="mb-3">
                              <div id="calendar" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  )
  }
}
