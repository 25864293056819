import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dateFormat, { masks } from "dateformat";
import { logDOM } from "@testing-library/react";
import { useNavigate, Link, json } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import { GetNonUserToken } from "../../Services/NonUser";
import { ToastContainer, toast } from "react-toastify";
import { CheckNetworkStatus } from "../../Utilities/Validations";
import { GetAirportList, GetRecentSearchList } from "../../Services/UtilitiesServices";
import { GetAirlineBanners, GetFlightDeals } from '../../Services/AirlineService';

class HomePageNew extends Component {


  OnewayOrigin = "";
  OnewayDestination = "";
  RoundTripOrigin = "";
  RoundTripDestination = "";
  constructor(props) {
    super(props);
    this.onewayOriginRef = React.createRef();
    this.onewayOriginRefMob = React.createRef();
    this.onewayDestinationRef = React.createRef();
    this.onewayDestinationRefMob = React.createRef();
    this.RoundTripOriginRef = React.createRef();
    this.RoundTripOriginRefMob = React.createRef();
    this.RoundTripDestinationRef = React.createRef();
    this.RoundTripDestinationRefMob = React.createRef();
    this.autoSuggestResultRef = React.createRef();
    this.autoSuggestResultRefMob = React.createRef();
    this.autoSuggestResultRef2 = React.createRef();
    this.autoSuggestResultRef2Mob = React.createRef();
    this.autoSuggestResultRef3 = React.createRef();
    this.autoSuggestResultRef3Mob = React.createRef();
    this.autoSuggestResultRef4 = React.createRef();
    this.autoSuggestResultRef4Mob = React.createRef();
    this.endDatePickerRef = React.createRef();
    this.endDatePickerRefMob = React.createRef();
    this.state = {
      isMobileView: window.innerWidth <= 768,
      airlineBannerList: [],
      isAirlineBannerData: false,
      flightDealsList: [],
      isFlightDealsData: false,
      recentSearch: [],
      isRecentSearchData: false,
      AirportJson: [],
      adult: 1,
      child: 0,
      infant: 0,
      selectedIndex: -1,
      selectedIndex2: -1,
      selectedIndex3: -1,
      selectedIndex4: -1,
      matchedOrigin: [],
      originError: false,
      destinationError: false,
      originErrorR: false,
      destinationErrorR: false,
      IsLoggedIn: false,
      DefaultUserLoginResponse: [],
      RequestBodyOneway: {
        adult: 1,
        child: 0,
        infant: 0,
        airlineClass: "Economy",
      },
      objsectorlistOneWay: {},
      RequestBodyRoundTrip: {
        adult: 1,
        child: 0,
        infant: 0,
        airlineClass: "Economy",
      },
      objsectorlistRoundTrip: {},
      objsectorlistRoundTripReturn: {},
      startDate: "",
      endDate: new Date(),
      value: "",
      suggestions: [],
      SortedAirportListArray: [],
      OnewayOriginSearchTerm: "",
      OnewayDestinationSearchTerm: "",
      OnewayOriginSearchResults: [],
      OnewayDestinationSearchResults: [],
      OnewayOriginAirport: [],
      OnewayDestinationAirport: [],

      //RoundTrip
      RoundTripOriginSearchTerm: "",
      RoundTripDestinationSearchTerm: "",
      RoundTripOriginSearchResults: [],
      RoundTripDestinationSearchResults: [],
      RoundTripOriginAirport: [],
      RoundTripDestinationAirport: [],

      options: {
        items: 1,
        loop: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 6000,
        animateOut: 'fadeOut',
        responsiveClass: true,
      },
    };
    this.secondInputRef = React.createRef();
    this.swapOriginAndDestination = this.swapOriginAndDestination.bind(this);
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    let UserDetails = {};
    try {
      let recentSearch = JSON.parse(localStorage.getItem("RS"));
      if (recentSearch != null) {
        this.setState({
          recentSearch: recentSearch
        })
      }
      else {
        this.setState({
          recentSearch: []
        })
      }
    }
    catch (ex) {

    }


    //AirlineBanners....
    this.getAllAirlineBanners();


    //Load All Airports...
    this.getAllAirports();
    if (localStorage.getItem("tokenValue") != null) {
      UserDetails = JSON.parse(localStorage.getItem("TM_UDetails"))
      this.getLast3SearchList(UserDetails);
    }


    //Oneway initial Date load...
    let dateValue = {};
    let todayDate = new Date();
    dateValue = this.state.objsectorlistOneWay;
    dateValue.departureDate = dateFormat(todayDate, "yyyy-mm-dd");
    this.setState({
      objsectorlistOneWay: dateValue,
    });

    //Round trip initial date load...

    let dateValueRound = {};
    let dateValueRoundR = {};

    dateValueRound = this.state.objsectorlistRoundTrip;
    dateValueRound.departureDate = dateFormat(todayDate, "yyyy-mm-dd");

    dateValueRoundR = this.state.objsectorlistRoundTripReturn;
    dateValueRoundR.departureDate = dateFormat(todayDate, "yyyy-mm-dd");

    this.setState({
      objsectorlistRoundTrip: dateValueRound,
      objsectorlistRoundTripReturn: dateValueRoundR,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // Check if the view is mobile or not
    this.setState({ isMobileView: window.innerWidth <= 768 });
  };

  getAllAirports() {
    GetAirportList().then((response) => {
      if (response.data.status) {
        this.setState({
          AirportJson: response.data.objAirportList,
        });
      }
    });
  }

  getAllAirlineBanners() {
    GetAirlineBanners().then((response) => {

      this.setState({
        airlineBannerList: response.data.airlineBannerList,
        isAirlineBannerData: true,
      })


    });
  }

  getAllFlightDeals() {
    GetFlightDeals().then((response) => {
      if (response.data.status) {
        this.setState({
          flightDealsList: response.data.flightDealsList,
          isFlightDealsData: true,
        })
      }



    });
  }

  getLast3SearchList(UserId) {
    GetRecentSearchList(UserId).then((response) => {
      if (response.data.status) {
        localStorage.removeItem('RS');
        localStorage.setItem('RS', JSON.stringify(response.data.recentSearchList));
        this.setState({
          isRecentSearchData: true,
          recentSearch: response.data.recentSearchList,
        })
      }


    });
  }

  NonUserToken(DeviceId) {
    let data = {};
    data.Id = DeviceId;
    localStorage.setItem("DeviceId", data.Id);

    GetNonUserToken()
      .then((response) => {
        if (response.data.status) {
          this.setState({
            DefaultUserLoginResponse: response.data,
          });
          localStorage.setItem("NonUsertokenValue", response.data.token);
        }
      })
      .catch((error) => {
        if (error.response.data.responseMessage == "InvalidToken") {
          this.props.navigate("/");
        }
      });
  }

  //AutoComplete fn...
  handleSearch = (event) => {
    let airportsTemp = this.state.AirportJson;

    const { value } = event.target;
    if (event.target.name == "onewayOrigin") {
      this.setState({ OnewayOriginSearchTerm: value, selectedIndex: -1 });
      this.OnewayOrigin = "";
    } else if (event.target.name == "onewayDestination") {
      this.setState({ OnewayDestinationSearchTerm: value, selectedIndex2: -1 });
      this.OnewayDestination = "";
    } else if (event.target.name == "RoundTripOrigin") {
      this.setState({ RoundTripOriginSearchTerm: value, selectedIndex3: -1 });
      this.RoundTripOrigin = "";
    } else if (event.target.name == "RoundTripDestination") {
      this.setState({
        RoundTripDestinationSearchTerm: value,
        selectedIndex4: -1,
      });
      this.RoundTripDestination = "";
    }

    if (value.length >= 2) {
      let index = -1;
      let SortedAirportArray = [];
      let matchingAirports = airportsTemp.filter(
        (airport) =>
          airport.code.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.airportName.toLowerCase().includes(value.toLowerCase()) ||
          airport.countryCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityName.toLowerCase().includes(value.toLowerCase())
      );
      // selected code to top of the Array.apply..
      for (let i = 0; i < matchingAirports.length; i++) {
        if (matchingAirports[i].code.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;
        } else if (
          matchingAirports[i].code.toLowerCase() ===
          matchingAirports[i].cityCode.toLowerCase()
        ) {
          index = i;
        }
      }

      if (index >= 0) {
        SortedAirportArray.push(matchingAirports[index]);
        for (let j = 0; j < matchingAirports.length; j++) {
          if (index != j) {
            SortedAirportArray.push(matchingAirports[j]);
          }
        }
      } else {
        SortedAirportArray = [];
        SortedAirportArray = matchingAirports;
      }

      if (SortedAirportArray.length === 1) {
        if (event.target.name == "onewayOrigin") {
          this.handleSelectAirport(
            SortedAirportArray[0].code,
            SortedAirportArray[0].airportName,
            "onewayOrigin",
            SortedAirportArray[0],
            SortedAirportArray[0].countryCode,
            SortedAirportArray[0].cityName,
            event
          );
          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          } else {
            this.onewayOriginRef.current.focus();
            if(this.state.isMobileView)
              this.onewayOriginRefMob.current.focus();
            this.setState({
              originError: false,
              SameAirportsError: false,
            });
            return;
          }
        } else if (event.target.name == "onewayDestination") {
          this.handleSelectAirport(
            SortedAirportArray[0].code,
            SortedAirportArray[0].airportName,
            "onewayDestination",
            SortedAirportArray[0],
            SortedAirportArray[0].countryCode,
            SortedAirportArray[0].cityName
          );
          const { OnewayOriginSearchTerm } = this.state;
          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          } else {
            if (
              this.onewayDestinationRef &&
              this.onewayDestinationRef.current &&
              this.onewayDestinationRef.current.input
            ) {
              this.onewayDestinationRef.current.input.focus();
              this.setState({
                destinationError: false,
                SameAirportsError: false,
              });
            }
            if(this.state.isMobileView)
            {
              if (
                this.onewayDestinationRefMob &&
                this.onewayDestinationRefMob.current &&
                this.onewayDestinationRefMob.current.input
              ) {
                this.onewayDestinationRefMob.current.input.focus();
                this.setState({
                  destinationError: false,
                  SameAirportsError: false,
                });
              }
            }
            
            return;
          }
        } else if (event.target.name == "RoundTripOrigin") {
          this.handleSelectAirport(
            SortedAirportArray[0].code,
            SortedAirportArray[0].airportName,
            "RoundTripOrigin",
            SortedAirportArray[0],
            SortedAirportArray[0].countryCode,
            SortedAirportArray[0].cityName
          );
          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          } else {            
              this.RoundTripOriginRef.current.focus();
              if(this.state.isMobileView)
                this.RoundTripOriginRefMob.current.focus();
            this.setState({
              originErrorR: false,
              SameAirportsError: false,
            });
            return;
          }
        } else if (event.target.name == "RoundTripDestination") {
          this.handleSelectAirport(
            SortedAirportArray[0].code,
            SortedAirportArray[0].airportName,
            "RoundTripDestination",
            SortedAirportArray[0],
            SortedAirportArray[0].countryCode,
            SortedAirportArray[0].cityName
          );
          const { OnewayOriginSearchTerm } = this.state;

          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          } else {
            if (
              this.RoundTripDestinationRef &&
              this.RoundTripDestinationRef.current &&
              this.RoundTripDestinationRef.current.input
            ) {
              this.RoundTripDestinationRef.current.input.focus();
              this.setState({
                destinationErrorR: false,
                SameAirportsError: false,
              });
            }
            if(this.state.isMobileView)
            {
              if (
                this.RoundTripDestinationRefMob &&
                this.RoundTripDestinationRefMob.current &&
                this.RoundTripDestinationRefMob.current.input
              ) {
                this.RoundTripDestinationRefMob.current.input.focus();
                this.setState({
                  destinationErrorR: false,
                  SameAirportsError: false,
                });
              }
            }
            
            return;
          }
        }
      } else {
        if (event.target.name == "onewayOrigin") {
          this.setState({ originError: true });
        } else if (event.target.name == "onewayDestination") {
          this.setState({ destinationError: true });
        } else if (event.target.name == "RoundTripOrigin") {
          this.setState({ originErrorR: true });
        } else if (event.target.name == "RoundTripDestination") {
          this.setState({ destinationErrorR: true });
        }
      }

      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: SortedAirportArray });
      } else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: SortedAirportArray });
      } else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: SortedAirportArray });
      } else if (event.target.name == "RoundTripDestination") {
        this.setState({
          RoundTripDestinationSearchResults: SortedAirportArray,
        });
      }
      return;
    } else if (event.target.value == "") {
      //IF nothing in the textbox..
      if (
        event.target.name == "onewayOrigin" ||
        event.target.name == "RoundTripOrigin"
      ) {
        this.OnewayOrigin = "";
        this.RoundTripOrigin = "";

        this.setState({
          SameAirportsError: false,
          OnewayOriginSearchTerm: "",
          RoundTripOriginSearchTerm: "",
          OnewayOriginAirport: {},
          RoundTripOriginAirport: {},
        });
      } else if (
        event.target.name == "onewayDestination" ||
        event.target.name == "RoundTripDestination"
      ) {
        this.OnewayDestination = "";
        this.RoundTripDestination = "";

        this.setState({
          SameAirportsError: false,
          OnewayDestinationSearchTerm: "",
          RoundTripDestinationSearchTerm: "",
          OnewayDestinationAirport: {},
          RoundTripDestinationAirport: {},
        });
      }
    } else {
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: [] });
      } else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: [] });
      } else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: [] });
      } else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: [] });
      }
    }
  };
  handleSelectAirport = (code, name, type, airportDetails) => {
    const selectedAirport = `${code}-${airportDetails.cityName}-${name}-${airportDetails.countryCode}`;
    if (type == "onewayOrigin") {
      this.OnewayOrigin = selectedAirport;
      this.RoundTripOrigin = selectedAirport;
      this.setState(
        {
          OnewayOriginSearchTerm: selectedAirport,
          OnewayOriginSearchResults: [],
          OnewayOriginAirport: airportDetails,
          originError: false,
          selectedIndex: -1,
          ////
          RoundTripOriginSearchTerm: selectedAirport,
          RoundTripOriginSearchResults: [],
          RoundTripOriginAirport: airportDetails,
          originErrorR: false,
          selectedIndex3: -1,
          OnewayOriginSelected: true,
          ////
        },
        () => {
          this.scrollSelectedIntoView();
        }
      );
    } else if (type == "onewayDestination") {
      this.OnewayDestination = selectedAirport;
      this.RoundTripDestination = selectedAirport;
      this.setState(
        {
          OnewayDestinationSearchTerm: selectedAirport,
          OnewayDestinationSearchResults: [],
          OnewayDestinationAirport: airportDetails,
          destinationError: false,
          selectedIndex2: -1,
          ///
          RoundTripDestinationSearchTerm: selectedAirport,
          RoundTripDestinationSearchResults: [],
          RoundTripDestinationAirport: airportDetails,
          destinationErrorR: false,
          selectedIndex4: -1,
          OnewayDestinationSelected: true,
          ////
        },
        () => {
          this.scrollSelectedIntoView();
        }
      );
    } else if (type == "RoundTripOrigin") {
      this.RoundTripOrigin = selectedAirport;
      this.setState(
        {
          RoundTripOriginSearchTerm: selectedAirport,
          RoundTripOriginSearchResults: [],
          RoundTripOriginAirport: airportDetails,
          originErrorR: false,
          selectedIndex3: -1,

          OnewayOriginSearchTerm: selectedAirport,
          OnewayOriginSearchResults: [],
          OnewayOriginAirport: airportDetails,
          originError: false,
          selectedIndex: -1,
          RoundTripOriginSelected: true,
        },
        () => {
          this.scrollSelectedIntoView();
        }
      );
    } else if (type == "RoundTripDestination") {
      this.RoundTripDestination = selectedAirport;
      this.setState(
        {
          RoundTripDestinationSearchTerm: selectedAirport,
          RoundTripDestinationSearchResults: [],
          RoundTripDestinationAirport: airportDetails,
          destinationErrorR: false,
          selectedIndex4: -1,

          OnewayDestinationSearchTerm: selectedAirport,
          OnewayDestinationSearchResults: [],
          OnewayDestinationAirport: airportDetails,
          destinationError: false,
          selectedIndex2: -1,
          RoundTripDestinationSelected: true,
        },
        () => {
          this.scrollSelectedIntoView();
        }
      );
    }

    //If same Airport...
    if (this.OnewayDestination == this.OnewayOrigin) {
      this.setState({
        SameAirportsError: true,
      });
    } else {
      this.setState({
        SameAirportsError: false,
      });
    }

    if (this.RoundTripOrigin == this.RoundTripDestination) {
      this.setState({
        SameAirportsError: true,
      });
    } else {
      this.setState({
        SameAirportsError: false,
      });
    }
  };

  handleKeyDown = (event, airportListLength) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.handleArrowUp();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.handleArrowDown();
    } else if ((event.key === "Enter" || event.key === "Tab") && airportListLength > 0) {
      event.preventDefault();
      this.handleEnterKey(event);
    }
  };

  handleArrowUp = () => {
    const {
      selectedIndex,
      selectedIndex2,
      selectedIndex3,
      selectedIndex4,
      OnewayOriginSearchResults,
      OnewayDestinationSearchResults,
      RoundTripOriginSearchResults,
      RoundTripDestinationSearchResults,
    } = this.state;
    let newIndex =
      selectedIndex > 0
        ? selectedIndex - 1
        : OnewayOriginSearchResults.length - 1;
    let newIndex2 =
      selectedIndex2 > 0
        ? selectedIndex2 - 1
        : OnewayDestinationSearchResults.length - 1;
    let newIndex3 =
      selectedIndex3 > 0
        ? selectedIndex3 - 1
        : RoundTripOriginSearchResults.length - 1;
    let newIndex4 =
      selectedIndex4 > 0
        ? selectedIndex4 - 1
        : RoundTripDestinationSearchResults.length - 1;

    if (newIndex > -1 && newIndex < OnewayOriginSearchResults.length) {
      let AirportDetails = OnewayOriginSearchResults[newIndex];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayOriginSearchTerm: selectedAirport });
    }
    if (newIndex2 > -1 && newIndex2 < OnewayDestinationSearchResults.length) {

      let AirportDetails = OnewayDestinationSearchResults[newIndex2];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayDestinationSearchTerm: selectedAirport });
    }
    if (newIndex3 > -1 && newIndex3 < RoundTripOriginSearchResults.length) {

      let AirportDetails = RoundTripOriginSearchResults[newIndex3];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripOriginSearchTerm: selectedAirport });
    }
    if (
      newIndex4 > -1 &&
      newIndex4 < RoundTripDestinationSearchResults.length
    ) {

      let AirportDetails = RoundTripDestinationSearchResults[newIndex4];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripDestinationSearchTerm: selectedAirport });
    }

    this.setState(
      {
        selectedIndex: newIndex,
        selectedIndex2: newIndex2,
        selectedIndex3: newIndex3,
        selectedIndex4: newIndex4,
      },
      () => {
        this.scrollSelectedIntoView();
      }
    );
  };

  handleArrowDown = () => {
    const {
      selectedIndex,
      selectedIndex2,
      selectedIndex3,
      selectedIndex4,
      OnewayOriginSearchResults,
      OnewayDestinationSearchResults,
      RoundTripOriginSearchResults,
      RoundTripDestinationSearchResults,
    } = this.state;
    let newIndex =
      selectedIndex < OnewayOriginSearchResults.length - 1
        ? selectedIndex + 1
        : 0;
    let newIndex2 =
      selectedIndex2 < OnewayDestinationSearchResults.length - 1
        ? selectedIndex2 + 1
        : 0;
    let newIndex3 =
      selectedIndex3 < RoundTripOriginSearchResults.length - 1
        ? selectedIndex3 + 1
        : 0;
    let newIndex4 =
      selectedIndex4 < RoundTripDestinationSearchResults.length - 1
        ? selectedIndex4 + 1
        : 0;

    if (newIndex >= -1 && newIndex < OnewayOriginSearchResults.length) {
      if (newIndex == -1) newIndex = 0;
      const AirportDetails = OnewayOriginSearchResults[newIndex];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayOriginSearchTerm: selectedAirport });
    }
    if (newIndex2 >= -1 && newIndex2 < OnewayDestinationSearchResults.length) {
      if (newIndex2 == -1) newIndex2 = 0;
      const AirportDetails = OnewayDestinationSearchResults[newIndex2];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayDestinationSearchTerm: selectedAirport });
    }
    if (newIndex3 >= -1 && newIndex3 < RoundTripOriginSearchResults.length) {
      if (newIndex3 == -1) newIndex3 = 0;
      const AirportDetails = RoundTripOriginSearchResults[newIndex3];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripOriginSearchTerm: selectedAirport });
    }
    if (
      newIndex4 >= -1 &&
      newIndex4 < RoundTripDestinationSearchResults.length
    ) {
      if (newIndex4 == -1) newIndex4 = 0;
      const AirportDetails = RoundTripDestinationSearchResults[newIndex4];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripDestinationSearchTerm: selectedAirport });
    }

    this.setState(
      {
        selectedIndex: newIndex,
        selectedIndex2: newIndex2,
        selectedIndex3: newIndex3,
        selectedIndex4: newIndex4,
      },
      () => {
        this.scrollSelectedIntoView();
      }
    );
  };

  scrollSelectedIntoView = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } =
      this.state;
    if (this.autoSuggestResultRef.current) {
      const selectedElement = this.autoSuggestResultRef.current.querySelector(
        `[data-index="${selectedIndex}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.state.isMobileView && this.autoSuggestResultRefMob.current) {
      const selectedElement = this.autoSuggestResultRefMob.current.querySelector(
        `[data-index="${selectedIndex}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef2.current) {
      const selectedElement2 = this.autoSuggestResultRef2.current.querySelector(
        `[data-index="${selectedIndex2}"]`
      );
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.state.isMobileView && this.autoSuggestResultRef2Mob.current) {
      const selectedElement2 = this.autoSuggestResultRef2Mob.current.querySelector(
        `[data-index="${selectedIndex2}"]`
      );
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef3.current) {
      const selectedElement3 = this.autoSuggestResultRef3.current.querySelector(
        `[data-index="${selectedIndex3}"]`
      );
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.state.isMobileView && this.autoSuggestResultRef3Mob.current) {
      const selectedElement3 = this.autoSuggestResultRef3Mob.current.querySelector(
        `[data-index="${selectedIndex3}"]`
      );
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef4.current) {
      const selectedElement4 = this.autoSuggestResultRef4.current.querySelector(
        `[data-index="${selectedIndex4}"]`
      );
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.state.isMobileView && this.autoSuggestResultRef4Mob.current) {
      const selectedElement4 = this.autoSuggestResultRef4Mob.current.querySelector(
        `[data-index="${selectedIndex4}"]`
      );
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  handleArrowKeyPress = (key) => {
    const { selectedIndex, OnewayOriginSearchResults } = this.state;
    let newIndex;
    if (key === "ArrowUp") {
      newIndex = selectedIndex - 1;
      newIndex = newIndex < 0 ? OnewayOriginSearchResults.length - 1 : newIndex;
    } else if (key === "ArrowDown") {
      newIndex = selectedIndex + 1;
      newIndex = newIndex >= OnewayOriginSearchResults.length ? 0 : newIndex;
    }

    this.setState({ selectedIndex: newIndex });
  };

  handleEnterKey = (event) => {
    const {
      selectedIndex,
      selectedIndex2,
      selectedIndex3,
      selectedIndex4,
      OnewayOriginSearchResults,
      OnewayDestinationSearchResults,
      RoundTripOriginSearchResults,
      RoundTripDestinationSearchResults,
    } = this.state;
    let defaultIndex = selectedIndex;
    let defaultIndex2 = selectedIndex2;
    let defaultIndex3 = selectedIndex3;
    let defaultIndex4 = selectedIndex4;

    if (defaultIndex >= -1 && defaultIndex < OnewayOriginSearchResults.length) {
      if (defaultIndex == -1) defaultIndex = 0;
      const selectedAirport = OnewayOriginSearchResults[defaultIndex];
      if (event.target.name == "onewayOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        if (this.OnewayDestination == this.OnewayOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        } else {
          this.setState({ SameAirportsError: false });
            this.onewayOriginRef.current.focus();
          if(this.state.isMobileView)
            this.onewayOriginRefMob.current.focus();
          this.setState({ originError: false });
          return;
        }
      }
    }
    if (
      defaultIndex2 >= -1 &&
      defaultIndex2 < OnewayDestinationSearchResults.length
    ) {
      if (defaultIndex2 == -1) defaultIndex2 = 0;
      const selectedAirport = OnewayDestinationSearchResults[defaultIndex2];
      if (event.target.name == "onewayDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const { OnewayOriginSearchTerm } = this.state;

        if (this.OnewayDestination == this.OnewayOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        } else {
          this.setState({ SameAirportsError: false });
          if (
            this.onewayDestinationRef &&
            this.onewayDestinationRef.current &&
            this.onewayDestinationRef.current.input
          ) {
            this.onewayDestinationRef.current.input.focus();
            this.setState({ destinationError: false });
          }
          if(this.state.isMobileView)
          {
            if (
              this.onewayDestinationRefMob &&
              this.onewayDestinationRefMob.current &&
              this.onewayDestinationRefMob.current.input
            ) {
              this.onewayDestinationRefMob.current.input.focus();
              this.setState({ destinationError: false });
            }
          }
          
          return;
        }
      }
    }
    if (
      defaultIndex3 >= -1 &&
      defaultIndex3 < RoundTripOriginSearchResults.length
    ) {
      if (defaultIndex3 == -1) defaultIndex3 = 0;
      const selectedAirport = RoundTripOriginSearchResults[defaultIndex3];
      if (event.target.name == "RoundTripOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        if (this.RoundTripDestination == this.RoundTripOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        } else {
          this.setState({ SameAirportsError: false });          
          this.RoundTripOriginRef.current.focus();
          if(this.state.isMobileView)
            this.RoundTripOriginRefMob.current.focus();
          this.setState({ originErrorR: false });
          return;
        }
      }
    }
    if (
      defaultIndex4 >= -1 &&
      defaultIndex4 < RoundTripDestinationSearchResults.length
    ) {
      if (defaultIndex4 == -1) defaultIndex4 = 0;
      const selectedAirport = RoundTripDestinationSearchResults[defaultIndex4];
      if (event.target.name == "RoundTripDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );

        const { RoundTripOriginSearchTerm } = this.state;

        if (this.RoundTripDestination == this.RoundTripOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        } else {
          this.setState({ SameAirportsError: false });
          if (
            this.RoundTripDestinationRef &&
            this.RoundTripDestinationRef.current &&
            this.RoundTripDestinationRef.current.input
          ) {
            this.RoundTripDestinationRef.current.input.focus();
            this.setState({ destinationErrorR: false });
          }

          if(this.state.isMobileView)
            {
              if (
                this.RoundTripDestinationRefMob &&
                this.RoundTripDestinationRefMob.current &&
                this.RoundTripDestinationRefMob.current.input
              ) {
                this.RoundTripDestinationRefMob.current.input.focus();
                this.setState({
                  destinationErrorR: false,
                  SameAirportsError: false,
                });
              }
            }
          return;
        }
      }
    }
  };

  SelectAirport = (code, name, type, airportDetails) => {
    this.handleSelectAirport(
      code,
      name,
      type,
      airportDetails,
      airportDetails.countryCode,
      airportDetails.cityName
    );
    if (type == "onewayOrigin") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      } else {
        this.setState({ SameAirportsError: false });
        
        this.onewayOriginRef.current.focus();
        if(this.state.isMobileView)
          this.onewayOriginRefMob.current.focus();
        this.setState({ originError: false });
        return;
      }
    } else if (type == "onewayDestination") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      } else {
        this.setState({ SameAirportsError: false });
        if (
          this.onewayDestinationRef &&
          this.onewayDestinationRef.current &&
          this.onewayDestinationRef.current.input
        ) {
          this.onewayDestinationRef.current.input.focus();
          this.setState({ destinationError: false });
        }
        if(this.state.isMobileView)
        {
          if (
            this.onewayDestinationRefMob &&
            this.onewayDestinationRefMob.current &&
            this.onewayDestinationRefMob.current.input
          ) {
            this.onewayDestinationRefMob.current.input.focus();
            this.setState({ destinationError: false });
          }
        }
        
        return;
      }
    } else if (type == "RoundTripOrigin") {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      } else {
        this.setState({ SameAirportsError: false });
        
        this.RoundTripOriginRef.current.focus();
        if(this.state.isMobileView)
          this.RoundTripOriginRefMob.current.focus();
        this.setState({ originErrorR: false });
        return;
      }
    } else if (type == "RoundTripDestination") {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      } else {
        this.setState({ SameAirportsError: false });
        if (
          this.RoundTripDestinationRef &&
          this.RoundTripDestinationRef.current &&
          this.RoundTripDestinationRef.current.input
        ) {
          this.RoundTripDestinationRef.current.input.focus();
          this.setState({ destinationErrorR: false });
        }

        if(this.state.isMobileView)
          {
            if (
              this.RoundTripDestinationRefMob &&
              this.RoundTripDestinationRefMob.current &&
              this.RoundTripDestinationRefMob.current.input
            ) {
              this.RoundTripDestinationRefMob.current.input.focus();
              this.setState({
                destinationErrorR: false,
                SameAirportsError: false,
              });
            }
          }
        return;
      }
    }
  };

  changeAirportListShowStatus(FieldName) {
    if (FieldName == "Destination") {
      this.setState({
        OnewayOriginSearchResults: [],
        RoundTripOriginSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayOrigin == "") {
        this.setState({
          OnewayOriginSearchTerm: "",
        });
      }

      if (this.RoundTripOrigin == "") {
        this.setState({
          RoundTripOriginSearchTerm: "",
        });
      }
    } else {
      this.setState({
        OnewayDestinationSearchResults: [],
        RoundTripDestinationSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayDestination == "") {
        this.setState({
          OnewayDestinationSearchTerm: "",
        });
      }

      if (this.RoundTripDestination == "") {
        this.setState({
          RoundTripDestinationSearchTerm: "",
        });
      }
    }
  }

  swapOriginAndDestination() {
    let tempData;
    let tempDataR;
    tempData = this.state.OnewayOriginAirport;
    let origin = this.state.OnewayOriginSearchTerm;
    tempDataR = this.state.RoundTripOriginAirport;
    let originR = this.state.RoundTripOriginSearchTerm;

    this.setState({
      OnewayOriginAirport: this.state.OnewayDestinationAirport,
      OnewayDestinationAirport: tempData,

      OnewayOriginSearchTerm: this.state.OnewayDestinationSearchTerm,
      OnewayDestinationSearchTerm: origin,

      RoundTripOriginAirport: this.state.RoundTripDestinationAirport,
      RoundTripDestinationAirport: tempDataR,

      RoundTripOriginSearchTerm: this.state.RoundTripDestinationSearchTerm,
      RoundTripDestinationSearchTerm: originR,
    });
  }



  //#region OnewaySection
  inputFareTypeChangeHandler = (event) => { };

  ////////////////////////////////Oneway Search///////////////////////////////////////////////
  SearchFlights(tripMode) {
    //Check network connection...
    if (!CheckNetworkStatus()) return;

    if (
      this.state.originError == false &&
      this.state.destinationError == false
    ) {
      const { OnewayOriginSearchTerm, OnewayDestinationSearchTerm } =
        this.state;

      if (!OnewayOriginSearchTerm || !OnewayDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      } else {
        this.setState({ showValidationMessage: false });
        let testData = this.state.objsectorlistOneWay;
        let testData2 = this.state.RequestBodyOneway;

        testData.tripmode = tripMode;
        this.setState({
          objsectorlistOneWay: testData,
        });

        testData.origin = this.state.OnewayOriginAirport.code;
        testData.origincountry = this.state.OnewayOriginAirport.countryCode;

        testData.destination = this.state.OnewayDestinationAirport.code;
        testData.destinationcountry =
          this.state.OnewayDestinationAirport.countryCode;

        //TravelType...

        if (
          testData.origincountry != "IN" ||
          testData.destinationcountry != "IN"
        ) {
          testData2.traveltype = "I";
        } else {
          testData2.traveltype = "D";
        }
        this.setState({
          objsectorlistOneWay: testData2,
        });

        //IsDIrect DefaultValue: False- currently UI not contain that Field
        let dataForIsDirectUpdate = this.state.RequestBodyOneway;
        dataForIsDirectUpdate.isdirect = false;
        dataForIsDirectUpdate.prefferedCarriers = "";
        dataForIsDirectUpdate.prefferedProviders = "";
        dataForIsDirectUpdate.fareType = "";

        this.setState({
          RequestBodyOneway: dataForIsDirectUpdate,
        });
        let OnewaySearchRequest = this.state.RequestBodyOneway;
        OnewaySearchRequest.objSectorList = [this.state.objsectorlistOneWay];

        OnewaySearchRequest.isRecent = false;



        const Param = encodeURIComponent(JSON.stringify(OnewaySearchRequest));

        if (tripMode == "O") {


          this.props.navigate(`/Oneway?onewaySearchRequest=${Param}`);
        } else if (tripMode == "R") {
          if (
            testData.origincountry == "IN" &&
            testData.destinationcountry == "IN"
          ) {


            this.props.navigate(`/Roundtrip?IRSearchRequest=${Param}`);
          } else {
            this.props.navigate("/InternationalRoundTrip"); //Combained RoundTrip
          }
        } else if (tripMode == "S") this.props.navigate("/International");
        else if (tripMode == "M") this.props.navigate("/Multicity");
      }

    }
  }

  SearchFlightsRecent(data) {
    let tripMode = "";


    if (data.objSectorList.length == 2) { tripMode = "R" }
    else { tripMode = "O" }

    let testData = data.objSectorList[0];

    data.isRecent = true;

    const datatToSend = encodeURIComponent(JSON.stringify(data));


    if (tripMode == "O") {

      this.props.navigate(`/Oneway?onewaySearchRequest=${datatToSend}`);
    } else if (tripMode == "R") {
      if (
        testData.originCountry == "IN" &&
        testData.destinationCountry == "IN"
      ) {
        this.props.navigate(`/Roundtrip?IRTSearchRequest=${datatToSend}`);
      } else {
        this.props.navigate(`/InternationalRoundTrip?CRTSearchRequest=${datatToSend}`);
      }
    } else if (tripMode == "S") this.props.navigate("/International");
    else if (tripMode == "M") this.props.navigate("/Multicity");
  }

  /////PAX COUNT///////
  inputOnewaySearchRequestChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 10 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue,
      });
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 10 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue,
      });
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      this.setState({
        infant: infantValue,
      });
    }

    let testData = {};
    testData = this.state.RequestBodyOneway;

    if (
      event.target.name == "adult" ||
      event.target.name == "child" ||
      event.target.name == "infant"
    ) {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value);
      } else if (event.target.name == "child") {
        testData.child = Number(event.target.value);
      } else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value);
      }
      this.setState({
        RequestBodyOneway: testData,
        RequestBodyRoundTrip: testData,
      });

      return;
    } else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value;
      this.setState({
        RequestBodyOneway: testData,
      });
    }
  };

  //////////Origin Destination////////////////////////
  inputSearchSectorChangeFocusOut = (type) => {
    let objSector = [];
    let sectorValues = {};
    sectorValues = this.state.objsectorlistOneWay;
    if (type == "onewayOrigin") {
      sectorValues.origin = this.state.OnewayOriginAirport.code;
      sectorValues.origincountry = this.state.OnewayOriginAirport.countryCode;

    } else if (type == "onewayDestination") {
      sectorValues.destination = this.state.OnewayDestinationAirport.code;
      sectorValues.destinationcountry =
        this.state.OnewayDestinationAirport.countryCode;
    }
    this.setState({
      objsectorlistOneWay: sectorValues,
    });
  };

  setStartDate(date) {
    let dateValue = {},
      RoundTripData = {};
    dateValue = this.state.objsectorlistOneWay;
    dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");

    this.setState({
      objsectorlistOneWay: dateValue,

    });
    this.setTravelDateRoundTrip(date, "S");
  }

  //#endregion

  //#region RoundTripSection

  /////PAX COUNT///////
  inputOnewaySearchRequestChangeHandlerRoundTrip = (event) => {
    const { name, value } = event.target;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 10 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue,
      });
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 10 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue,
      });
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      this.setState({
        infant: infantValue,
      });
    }

    let testData = {};
    testData = this.state.RequestBodyRoundTrip;

    if (
      event.target.name == "adult" ||
      event.target.name == "child" ||
      event.target.name == "infant"
    ) {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value);
      } else if (event.target.name == "child") {
        testData.child = Number(event.target.value);
      } else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value);
      }
      this.setState({
        RequestBodyRoundTrip: testData,
        RequestBodyOneway: testData,
      });


      return;
    } else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value;
      this.setState({
        RequestBodyRoundTrip: testData,
      });
    }
  };

  inputSearchSectorChangeFocusOutRoundTrip = (event, type) => {
    let objSector = [];
    let sectorValues = {},
      sectorValuesReturn = {};
    sectorValues = this.state.objsectorlistRoundTrip;
    sectorValuesReturn = this.state.objsectorlistRoundTripReturn;
    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.origin = this.state.AirportJson[key].code;
          sectorValues.origincountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.destination = this.state.AirportJson[key].code;
          sectorValuesReturn.destinationcountry =
            this.state.AirportJson[key].countryCode;
        }
      });
    } else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.destination = this.state.AirportJson[key].code;
          sectorValues.destinationcountry =
            this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.origin = this.state.AirportJson[key].code;
          sectorValuesReturn.origincountry =
            this.state.AirportJson[key].countryCode;
        }
      });
    }
    //Onward...
    this.setState({
      objsectorlistRoundTrip: sectorValues,
    });

    //Return...
    this.setState({
      objsectorlistRoundTripReturn: sectorValuesReturn,
    });
  };

  setTravelDateRoundTrip(date, type) {
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    let dateValue = {},
      onewayData = {};
    if (type == "S") {
      dateValue = this.state.objsectorlistRoundTrip;
      dateValue.departureDate = selectedDate;

      onewayData = this.state.objsectorlistOneWay;
      onewayData.departureDate = selectedDate;
      this.setState({
        objsectorlistRoundTrip: dateValue,
        objsectorlistOneWay: onewayData,
      });

      dateValue = this.state.objsectorlistRoundTripReturn;
      if (
        selectedDate > this.state.objsectorlistRoundTripReturn.departureDate
      ) {
        dateValue.departureDate = selectedDate;
        this.setState({
          objsectorlistRoundTripReturn: dateValue,
        });
      }
    } else {
      dateValue = this.state.objsectorlistRoundTripReturn;
      dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");
      this.setState({
        objsectorlistRoundTripReturn: dateValue,
      });
    }

  }

  SearchFlightsRoundTrip(tripMode) {
    //Check network connection...
    if (!CheckNetworkStatus()) return;

    if (
      this.state.originErrorR == false &&
      this.state.destinationErrorR == false
    ) {
      const { RoundTripOriginSearchTerm, RoundTripDestinationSearchTerm } =
        this.state;
      if (!RoundTripOriginSearchTerm || !RoundTripDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      } else {
        this.setState({ showValidationMessage: false });
        let onwardObj = this.state.objsectorlistRoundTrip;
        let returnObj = this.state.objsectorlistRoundTripReturn;
        onwardObj.tripmode = "O";
        returnObj.tripmode = "R";

        //Onward...
        onwardObj.origin = this.state.RoundTripOriginAirport.code;
        onwardObj.origincountry = this.state.RoundTripOriginAirport.countryCode;
        onwardObj.destination = this.state.RoundTripDestinationAirport.code;
        onwardObj.destinationcountry =
          this.state.RoundTripDestinationAirport.countryCode;

        //Return...
        returnObj.origin = this.state.RoundTripDestinationAirport.code;
        returnObj.origincountry =
          this.state.RoundTripDestinationAirport.countryCode;
        returnObj.destination = this.state.RoundTripOriginAirport.code;
        returnObj.destinationcountry =
          this.state.RoundTripOriginAirport.countryCode;

        //Onward...
        this.setState({
          objsectorlistRoundTrip: onwardObj,
        });

        //Return...
        this.setState({
          objsectorlistRoundTripReturn: returnObj,
        });

        //Onward TravelType...
        let onwardObj1 = this.state.objsectorlistRoundTrip;
        let request = this.state.RequestBodyRoundTrip;
        if (
          onwardObj1.origincountry != "IN" ||
          onwardObj1.destinationcountry != "IN"
        ) {
          request.traveltype = "I";
        } else {
          request.traveltype = "D";
        }

        this.setState({
          RequestBodyRoundTrip: request,
        });

        //IsDIrect DefaultValue: False- currently UI not contain that Field
        let request1 = this.state.RequestBodyRoundTrip;
        request1.isdirect = false;
        request1.prefferedCarriers = "";
        request1.prefferedProviders = "";
        request1.fareType = "";

        this.setState({
          RequestBodyRoundTrip: request1,
        });

        let FinalRequestBody = {};
        FinalRequestBody = this.state.RequestBodyRoundTrip;

        let sectorList = [];
        sectorList.push(this.state.objsectorlistRoundTrip);
        sectorList.push(this.state.objsectorlistRoundTripReturn);
        FinalRequestBody.objSectorList = sectorList;

        const Param = encodeURIComponent(JSON.stringify(FinalRequestBody));
        //Structure area...


        let dataToRedirect = this.state.objsectorlistRoundTrip;

        if (tripMode == "O") this.props.navigate("/Oneway");
        else if (tripMode == "R") {
          if (
            dataToRedirect.origincountry == "IN" &&
            dataToRedirect.destinationcountry == "IN"
          )
            this.props.navigate(`/Roundtrip?IRTSearchRequest=${Param}`);
          else
            this.props.navigate(
              `/InternationalRoundTrip?CRTSearchRequest=${Param}`
            ); //Combained RoundTrip
        } else if (tripMode == "S") this.props.navigate("/International");
        else if (tripMode == "M") this.props.navigate("/Multicity");
      }
    }
  }

  handleReturnDateClick = () => {
    document.getElementById("ex1-tab-2").click();
    if (
      this.endDatePickerRef &&
      this.endDatePickerRef.current &&
      this.endDatePickerRef.current.input
    ) {
      this.endDatePickerRef.current.input.focus();
    }

    if (
      this.endDatePickerRefMob &&
      this.endDatePickerRefMob.current &&
      this.endDatePickerRefMob.current.input
    ) {
      this.endDatePickerRefMob.current.input.focus();
    }
  };

  handleReturnDateClickm = () => {
    document.getElementById("ex1-tab-2m").click();
    if (
      this.endDatePickerRef &&
      this.endDatePickerRef.current &&
      this.endDatePickerRef.current.input
    ) {
      this.endDatePickerRef.current.input.focus();
    }

    if (
      this.endDatePickerRefMob &&
      this.endDatePickerRefMob.current &&
      this.endDatePickerRefMob.current.input
    ) {
      this.endDatePickerRefMob.current.input.focus();
    }
  };


  //#endregion

  render() {
    const { showValidationMessage, SameAirportsError } = this.state;
    let OriginPlaceName = "", DestinatonPlaceName = "";

    const { value, suggestions } = this.state;
    const shouldRenderRecentSearch = this.state.isRecentSearchData;

    const inputProps = {
      placeholder: "Enter City Name",
      value,
      onChange: this.onChange,
    };

    //AUtoComplete...
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } =
      this.state;
    const {
      OnewayOriginSearchTerm,
      OnewayDestinationSearchTerm,
      OnewayOriginSearchResults,
      OnewayDestinationSearchResults,
      RoundTripOriginSearchTerm,
      RoundTripDestinationSearchTerm,
      RoundTripOriginSearchResults,
      RoundTripDestinationSearchResults,
      originError,
      destinationError,
      originErrorR,
      destinationErrorR,
    } = this.state;
    const showSearchResultsOnewayOrigin = OnewayOriginSearchResults.length > 0;
    const showSearchResultsOnewayDestination =
      OnewayDestinationSearchResults.length > 0;

    const showSearchResultsRoundTripyOrigin =
      RoundTripOriginSearchResults.length > 0;
    const showSearchResultsRoundTripDestination =
      RoundTripDestinationSearchResults.length > 0;

    const showNoResultsMessageOnewayOrigin =
      RoundTripOriginSearchResults.length >= 2 &&
      !showSearchResultsOnewayOrigin;
    const showNoResultsMessageOnewayDestination =
      OnewayDestinationSearchResults.length >= 2 &&
      !showSearchResultsOnewayDestination;

    const showNoResultsMessageRoundTripOrigin =
      OnewayOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageRoundTripDestination =
      RoundTripDestinationSearchResults.length >= 2 &&
      !showSearchResultsOnewayDestination;



    return (
      <div>
{/* Mobile Search starts */}
<section className="main-banner map-area main-mob position-relative" id="map-area">
  <div className="mobile-bg">
    {/* <div className="container">
      <h4 className="text-white">It’s time to Fly again!</h4>
    </div> */}
    </div>      
<div className="mobile-nav-menu">
<ul className="nav justify-content-center">
  <li className="nav-item">
  <Link to="/" className="nav-link active"><img src="assets/img/flightm.png" width={25} className="mr-5" />Flights</Link>
  </li>
  <li className="nav-item">
<Link to="ComingSoon" className="nav-link"><img src="assets/img/hotelm.png" width={25} className="mr-5" />Hotels</Link>

  </li>
  <li className="nav-item">
  <Link to="ComingSoon" className="nav-link"><img src="assets/img/busm.png" width={25} className="mr-5" />Bus</Link>
  </li>
 
</ul>
    </div>

          <div className="search-widget">
          
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        <form className="booking-form" id="booking-form">
                       
                          <ul
                            className="nav nav-pills mb-1 mob-select-nav"
                            id="ex1m"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active"
                                id="ex1-tab-1m"
                                data-bs-toggle="pill"
                                href="#ex1-pills-1m"
                                role="tab"
                                aria-controls="ex1-pills-1m"
                                aria-selected="true"
                              >
                                One Way
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                id="ex1-tab-2m"
                                data-bs-toggle="pill"
                                role="tab"
                                href="#ex1-pills-2m"
                                aria-controls="ex1-pills-2m"
                                aria-selected="false"
                              >
                                Round Trip
                              </a>
                            </li>

                          </ul>
                 
                
                          <div className="tab-content" id="ex1m-content">
                            <div
                              className="tab-pane fade show active"
                              id="ex1-pills-1m"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-1m"
                            >
                              <div className="booking-content position-relative">
                                <div className="row">
                                  <div className="m-search-bg">
                                  <div className="col-md-3 col-lg-3 col-12">

                                   

                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            autoFocus
                                            type="text"
                                            value={OnewayOriginSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                OnewayOriginSearchResults.length
                                              );
                                            }} 
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Origin"
                                              )
                                            }
                                            placeholder="Origin"
                                            className="form-control text-start takeoff"
                                            name="onewayOrigin"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {originError &&
                                            OnewayOriginSearchTerm.length > 2 &&
                                            OnewayOriginSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid origin!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !OnewayOriginSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Departure Airport
                                              </span>
                                            )}
                                          {showSearchResultsOnewayOrigin &&
                                            OnewayOriginSearchTerm.length > 0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRefMob}
                                              >
                                                {OnewayOriginSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "onewayOrigin",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      {
                                                        <div
                                                          className={
                                                            index === 0 &&
                                                              selectedIndex == -1
                                                              ? "auto-result-block-selected "
                                                              : "auto-result-block"
                                                          }
                                                        >
                                                          <div className="float-start">
                                                            <span>
                                                              {airport.cityName} (
                                                              <span>
                                                                {airport.code}
                                                              </span>
                                                              )
                                                            </span>
                                                            <br />
                                                            <span>
                                                              {
                                                                airport.airportName
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="float-end pt-2">
                                                            <span>
                                                              {
                                                                airport.countryCode
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="clearfix"></div>
                                                        </div>
                                                      }
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsOnewayOrigin &&
                                            showNoResultsMessageOnewayOrigin && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                      <div className="swap-imagem">
                                      <a onClick={this.swapOriginAndDestination}>
                                        <img
                                          src="assets/img/swapm.webp"
                                          width={35}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  {" "}

  <div className="col-md-3 col-lg-3 col-12">

                                   

                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            ref={this.onewayOriginRefMob}
                                            type="text"
                                            value={OnewayDestinationSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                OnewayDestinationSearchResults.length
                                              );
                                            }}
                                            onFocus={(e) => {
                                              this.changeAirportListShowStatus("Destination");
                                              e.target.select();
                                            }}
                                            placeholder="Destination"
                                            className="form-control no-border landing"
                                            name="onewayDestination" style={{border:'none'}}
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {destinationError &&
                                            OnewayDestinationSearchTerm.length >
                                            2 &&
                                            OnewayDestinationSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid destination!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !OnewayDestinationSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Destination Airport
                                              </span>
                                            )}
                                          {SameAirportsError && (
                                            <span className="sameaierr">
                                              Origin and Destination cannot be the
                                              same.
                                            </span>
                                          )}
                                          {showSearchResultsOnewayDestination &&
                                            OnewayDestinationSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef2Mob}
                                              >
                                                {OnewayDestinationSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "onewayDestination",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex2
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      {
                                                        <div
                                                          className={
                                                            index === 0 &&
                                                              selectedIndex2 == -1
                                                              ? "auto-result-block-selected "
                                                              : "auto-result-block"
                                                          }
                                                        >
                                                          <div className="float-start">
                                                            <span>
                                                              {airport.cityName} (
                                                              <span>
                                                                {airport.code}
                                                              </span>
                                                              )
                                                            </span>
                                                            <br />
                                                            <span>
                                                              {
                                                                airport.airportName
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="float-end pt-2">
                                                            <span>
                                                              {
                                                                airport.countryCode
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="clearfix"></div>
                                                        </div>
                                                      }
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsOnewayDestination &&
                                            showNoResultsMessageOnewayDestination && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>




                                  </div>
  </div>
                                
                                  
                                
  <div className="m-search-bg">
    <div className="row">
                                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                                    <p className="b-sub-txt">
                                      Departure
                                    </p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.onewayDestinationRefMob}
                                        selected={this.state.startDate}
                                        value={dateFormat(
                                          this.state.objsectorlistOneWay
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setStartDate(date)
                                        }
                                        minDate={new Date()} onFocus={(e) => e.target.blur()} className="date-cal"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                                    <p className="b-sub-txt">Return</p>


                                    <div
                                      className="origin-form"
                                      onClick={this.handleReturnDateClickm}
                                    >
                                      <DatePicker  className="date-cal" placeholderText="Choose" />
                                    </div>
                                  </div>
  </div>
      </div>                              <div className="m-search-bg">
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                    <p className="b-sub-txt-2">Traveller(s)</p>
                                    <div className="row">

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Adult (12+)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Adult"
                                          name="adult"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          value={this.state.adult}
                                          fdprocessedid="jtwsm"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.child, 10),
                                          }).map(
                                            (_, index) =>
                                              index > 0 && (
                                                <option key={index} value={index}>
                                                  {index < 10
                                                    ? `0${index}`
                                                    : index}
                                                </option>
                                              )
                                          )}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Child (2-11)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Child"
                                          name="child"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          value={this.state.child}
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.adult, 10),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4 position-relative">
                                        <label
                                          className="small-label"
                                          style={{ position: "absolute", top: "3px", left: "0" }}
                                        >
                                          Infant(Below 2yrs)
                                        </label>
                                        <select
                                          className="form-control t-select" style={{ marginTop: "24px", }}
                                          value={this.state.infant}
                                          id="Infant"
                                          name="infant"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          fdprocessedid="pxdj69"
                                        >

                                          {Array.from({
                                            length: parseInt(
                                              this.state.adult + 1
                                            ),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
<div className="m-search-bg">
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 class">
                                   
                                   
                                    <select
                                      className="form-control t-class"
                                      defaultValue={"Economy"}
                                      name="airlineClass"
                                      id="airline_class"
                                      onChange={(e) =>
                                        this.inputOnewaySearchRequestChangeHandler(
                                          e
                                        )
                                      }
                                      fdprocessedid="92g40h"
                                    >
                                      <option value="Economy">Economy</option>
                                      <option value="StandardEconomy">
                                        Standard Economy
                                      </option>
                                      <option value="PremiumEconomy">
                                        Premium Economy
                                      </option>
                                      <option value="Business">Business</option>
                                      <option value="PremiumBusiness">
                                        Premium Business
                                      </option>
                                      <option value="First">First</option>
                                      <option value="PremiumFirst">
                                        Premium First
                                      </option>
                                    </select>
                                  </div>
                                  </div>
                                  <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                                    <button
                                      type="button"
                                      className="btn btn-primary f-search-btn"
                                      onClick={() => this.SearchFlights("O")}
                                      disabled={
                                        this.state.originError ||
                                        this.state.destinationError ||
                                        SameAirportsError
                                      }
                                    >
                                      Search Flights
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="ex1-pills-2m"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-2m"
                            >
                              <div className="booking-content position-relative">
                                <div className="row">
                                <div className="m-search-bg">
                                  <div className="col-md-3 col-lg-3 col-12">

                                    
                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            type="text"
                                            value={RoundTripOriginSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                RoundTripOriginSearchResults.length
                                              );
                                            }}
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Origin"
                                              )
                                            }
                                            placeholder="Origin"
                                            className="form-control text-start takeoff"
                                            name="RoundTripOrigin"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {originErrorR &&
                                            RoundTripOriginSearchTerm.length >
                                            2 &&
                                            RoundTripOriginSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid origin!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !RoundTripOriginSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Departure Airport
                                              </span>
                                            )}
                                          {showSearchResultsRoundTripyOrigin &&
                                            RoundTripOriginSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef3Mob}
                                              >
                                                {RoundTripOriginSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "RoundTripOrigin",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex3
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      <div
                                                        className={
                                                          index === 0 &&
                                                            selectedIndex3 == -1
                                                            ? "auto-result-block-selected "
                                                            : "auto-result-block"
                                                        }
                                                      >
                                                        <div className="float-start">
                                                          <span>
                                                            {airport.cityName} (
                                                            <span>
                                                              {airport.code}
                                                            </span>
                                                            )
                                                          </span>
                                                          <br />
                                                          <span>
                                                            {airport.airportName}
                                                          </span>
                                                        </div>
                                                        <div className="float-end pt-2">
                                                          <span>
                                                            {airport.countryCode}
                                                          </span>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsRoundTripyOrigin &&
                                            showNoResultsMessageRoundTripOrigin && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                 
                                    <div className="swap-imagem">
                                      <a onClick={this.swapOriginAndDestination}>
                                        <img
                                          src="assets/img/swapm.webp"
                                          width={35}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                {" "}
                                  <div className="col-md-3 col-lg-3 col-12">

                                  
                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            ref={this.RoundTripOriginRefMob}
                                            type="text"
                                            value={RoundTripDestinationSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                RoundTripDestinationSearchResults.length
                                              );
                                            }}
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Destination"
                                              )
                                            }
                                            placeholder="Destination"
                                            className="form-control no-border landing"
                                            name="RoundTripDestination"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {destinationErrorR &&
                                            RoundTripDestinationSearchTerm.length >
                                            2 &&
                                            RoundTripDestinationSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid Destination!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !RoundTripDestinationSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Destination Airport
                                              </span>
                                            )}
                                          {SameAirportsError && (
                                            <span className="sameaierr">
                                              Origin and Destination cannot be the
                                              same.
                                            </span>
                                          )}
                                          {showSearchResultsRoundTripDestination &&
                                            RoundTripDestinationSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef4Mob}
                                              >
                                                {RoundTripDestinationSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "RoundTripDestination",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex4
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      <div
                                                        className={
                                                          index === 0 &&
                                                            selectedIndex4 == -1
                                                            ? "auto-result-block-selected "
                                                            : "auto-result-block"
                                                        }
                                                      >
                                                        <div className="float-start">
                                                          <span>
                                                            {airport.cityName} (
                                                            <span>
                                                              {airport.code}
                                                            </span>
                                                            )
                                                          </span>
                                                          <br />
                                                          <span>
                                                            {airport.airportName}
                                                          </span>
                                                        </div>
                                                        <div className="float-end pt-2">
                                                          <span>
                                                            {airport.countryCode}
                                                          </span>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsRoundTripDestination &&
                                            showNoResultsMessageRoundTripDestination && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
   </div>
                               




                                <div className="m-search-bg">
                                  <div className="row">
                                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                                    <p className="b-sub-txt">Onward</p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.RoundTripDestinationRefMob}
                                        name="startDate"
                                        selected={this.startDate}
                                        value={dateFormat(
                                          this.state.objsectorlistRoundTrip
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setTravelDateRoundTrip(
                                            date,
                                            "S"
                                          )
                                        }
                                        minDate={new Date()} onFocus={(e) => e.target.blur()} className="date-cal"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                                    <p className="b-sub-txt">Return</p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.endDatePickerRefMob}
                                        name="endDate"
                                        selected={this.endDate}
                                        value={dateFormat(
                                          this.state
                                            .objsectorlistRoundTripReturn
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setTravelDateRoundTrip(
                                            date,
                                            "R"
                                          )
                                        }
                                        minDate={Date.parse(
                                          this.state.objsectorlistRoundTrip
                                            .departureDate
                                        )} onFocus={(e) => e.target.blur()} className="date-cal"
                                      />
                                    </div>
                                  </div>
   </div>
         </div>                          <div className="m-search-bg">  
                                  <div className="col-lg-4 col-md-4 col-12">
                                    <p className="b-sub-txt-2">Traveller(s)</p>
                                    <div className="row">

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Adult (12+)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Adult"
                                          name="adult"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          value={this.state.adult}
                                          fdprocessedid="jtwsm"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.child, 10),
                                          }).map(
                                            (_, index) =>
                                              index > 0 && (
                                                <option key={index} value={index}>
                                                  {index < 10
                                                    ? `0${index}`
                                                    : index}
                                                </option>
                                              )
                                          )}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Child (2-11)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Child"
                                          name="child"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          value={this.state.child}
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.adult, 10),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4 position-relative">
                                        <label
                                          className="small-label"
                                          style={{ position: "absolute", top: "3px", left: "0" }}
                                        >
                                          Infant(Below 2yrs)
                                        </label>
                                        <select
                                          className="form-control t-select" style={{ marginTop: "24px", }}
                                          value={this.state.infant}
                                          id="Infant"
                                          name="infant"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length: parseInt(
                                              this.state.adult + 1
                                            ),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>


                                    </div>
                                  </div>
 </div>
<div className="m-search-bg">
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 class">
                                  
                                   
                                    <select
                                      className="form-control t-class"
                                      defaultValue={"Economy"}
                                      name="airlineClass"
                                      id="airline_class"
                                      fdprocessedid="92g40h"
                                      onChange={(e) =>
                                        this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                          e
                                        )
                                      }
                                    >
                                      <option value="Economy">Economy</option>
                                      <option value="StandardEconomy">
                                        Standard Economy
                                      </option>
                                      <option value="PremiumEconomy">
                                        Premium Economy
                                      </option>
                                      <option value="Business">Business</option>
                                      <option value="PremiumBusiness">
                                        Premium Business
                                      </option>
                                      <option value="First">First</option>
                                      <option value="PremiumFirst">
                                        Premium First
                                      </option>
                                    </select>
                                  </div>
                                   </div>
                                  <div className="col-lg-4 col-md-4 col-12">
                                    <button
                                      type="button"
                                      className="btn btn-primary f-search-btn"
                                      onClick={() =>
                                        this.SearchFlightsRoundTrip("R")
                                      }
                                      disabled={
                                        this.state.originErrorR ||
                                        this.state.destinationErrorR ||
                                        SameAirportsError
                                      }
                                    >
                                      Search Flights
                                    </button>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-2 col-md-2" />
                                    <div className="col-lg-10 col-md-10">



                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="ex1-pills-3"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-3"
                            ></div>
                          </div>
                        
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

{/* Mobile Search ends */}


        {/* Desktop search starts */}
        <section className="main-banner map-area main-desk" id="map-area">
          <div className="home-banner position-relative">
            <OwlCarousel className="owl-theme" {...this.state.options}>
              <div className="slider-item">
                <img src="assets/img/banner/tm-banner1.webp" alt="Slide 1" />
              </div>
              <div className="slider-item">
                <img src="assets/img/banner/tm-banner2.webp" alt="Slide 2" />
              </div>
              <div className="slider-item">
                <img src="assets/img/banner/tm-banner3.webp" alt="Slide 3" />
              </div>
              <div className="slider-item">
                <img src="assets/img/banner/tm-banner4.webp" alt="Slide 4" />
              </div>
            </OwlCarousel>
          </div>


          <div className="search-widget">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="container pt-2">
                    <div className="col-lg-12 col-md-12 text-center">
                      {" "}
                      <h1>It’s time to Fly again!</h1> 
                    </div>

                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        <form className="booking-form" id="booking-form">
                          {/* Pills navs */}
                          <ul
                            className="nav nav-pills mb-3"
                            id="ex1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active"
                                id="ex1-tab-1"
                                data-bs-toggle="pill"
                                href="#ex1-pills-1"
                                role="tab"
                                aria-controls="ex1-pills-1"
                                aria-selected="true"
                              >
                                One Way
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                id="ex1-tab-2"
                                data-bs-toggle="pill"
                                role="tab"
                                href="#ex1-pills-2"
                                aria-controls="ex1-pills-2"
                                aria-selected="false"
                              >
                                Round Trip
                              </a>
                            </li>

                          </ul>
                          {/* Pills navs */}
                          {/* Pills content */}
                          <div className="tab-content" id="ex1-content">
                            <div
                              className="tab-pane fade show active"
                              id="ex1-pills-1"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-1"
                            >
                              <div className="booking-content">
                                <div className="row">
                                  <div className="col-md-3 col-lg-3 col-12">

                                    <p className="b-sub-txt">&nbsp;</p>

                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            autoFocus
                                            type="text"
                                            value={OnewayOriginSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                OnewayOriginSearchResults.length
                                              );
                                            }} // Add this line
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Origin"
                                              )
                                            }
                                            placeholder="Origin"
                                            className="form-control text-start"
                                            name="onewayOrigin"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {originError &&
                                            OnewayOriginSearchTerm.length > 2 &&
                                            OnewayOriginSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid origin!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !OnewayOriginSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Departure Airport
                                              </span>
                                            )}
                                          {showSearchResultsOnewayOrigin &&
                                            OnewayOriginSearchTerm.length > 0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef}
                                              >
                                                {OnewayOriginSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "onewayOrigin",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      {
                                                        <div
                                                          className={
                                                            index === 0 &&
                                                              selectedIndex == -1
                                                              ? "auto-result-block-selected "
                                                              : "auto-result-block"
                                                          }
                                                        >
                                                          <div className="float-start">
                                                            <span>
                                                              {airport.cityName} (
                                                              <span>
                                                                {airport.code}
                                                              </span>
                                                              )
                                                            </span>
                                                            <br />
                                                            <span>
                                                              {
                                                                airport.airportName
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="float-end pt-2">
                                                            <span>
                                                              {
                                                                airport.countryCode
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="clearfix"></div>
                                                        </div>
                                                      }
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsOnewayOrigin &&
                                            showNoResultsMessageOnewayOrigin && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>




                                  <div className="col-md-1 pt-4 text-center position-relative">
                                    <div className="swap-image">
                                      <a onClick={this.swapOriginAndDestination}>
                                        <img
                                          src="assets/img/swap.webp"
                                          width={35}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>{" "}
                                  <div className="col-md-3 col-lg-3 col-12">

                                    <p className="b-sub-txt">&nbsp;</p>

                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            ref={this.onewayOriginRef}
                                            type="text"
                                            value={OnewayDestinationSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                OnewayDestinationSearchResults.length
                                              );
                                            }}
                                            onFocus={(e) => {
                                              this.changeAirportListShowStatus("Destination");
                                              e.target.select();
                                            }}
                                            placeholder="Destination"
                                            className="form-control"
                                            name="onewayDestination"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {destinationError &&
                                            OnewayDestinationSearchTerm.length >
                                            2 &&
                                            OnewayDestinationSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid destination!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !OnewayDestinationSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Destination Airport
                                              </span>
                                            )}
                                          {SameAirportsError && (
                                            <span className="sameaierr">
                                              Origin and Destination cannot be the
                                              same.
                                            </span>
                                          )}
                                          {showSearchResultsOnewayDestination &&
                                            OnewayDestinationSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef2}
                                              >
                                                {OnewayDestinationSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "onewayDestination",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex2
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      {
                                                        <div
                                                          className={
                                                            index === 0 &&
                                                              selectedIndex2 == -1
                                                              ? "auto-result-block-selected "
                                                              : "auto-result-block"
                                                          }
                                                        >
                                                          <div className="float-start">
                                                            <span>
                                                              {airport.cityName} (
                                                              <span>
                                                                {airport.code}
                                                              </span>
                                                              )
                                                            </span>
                                                            <br />
                                                            <span>
                                                              {
                                                                airport.airportName
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="float-end pt-2">
                                                            <span>
                                                              {
                                                                airport.countryCode
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="clearfix"></div>
                                                        </div>
                                                      }
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsOnewayDestination &&
                                            showNoResultsMessageOnewayDestination && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>




                                  </div>
                                  <div className="col-lg-1 col-md-1 col-12"></div>
                                  <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                                    <p className="b-sub-txt">
                                      Departure Date
                                    </p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.onewayDestinationRef}
                                        selected={this.state.startDate}
                                        value={dateFormat(
                                          this.state.objsectorlistOneWay
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setStartDate(date)
                                        }
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                                    <p className="b-sub-txt">Return Date</p>


                                    <div
                                      className="origin-form"
                                      onClick={this.handleReturnDateClick}
                                    >
                                      <DatePicker />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                    <p className="b-sub-txt-2">Traveller(s)</p>
                                    <div className="row">

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Adult (12+)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Adult"
                                          name="adult"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          value={this.state.adult}
                                          fdprocessedid="jtwsm"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.child, 10),
                                          }).map(
                                            (_, index) =>
                                              index > 0 && (
                                                <option key={index} value={index}>
                                                  {index < 10
                                                    ? `0${index}`
                                                    : index}
                                                </option>
                                              )
                                          )}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Child (2-11)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Child"
                                          name="child"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          value={this.state.child}
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.adult, 10),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4 position-relative">
                                        <label
                                          className="small-label"
                                          style={{ position: "absolute", top: "3px" }}
                                        >
                                          Infant(Below 2yrs)
                                        </label>
                                        <select
                                          className="form-control t-select" style={{ marginTop: "24px", }}
                                          value={this.state.infant}
                                          id="Infant"
                                          name="infant"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandler(
                                              e
                                            )
                                          }
                                          fdprocessedid="pxdj69"
                                        >

                                          {Array.from({
                                            length: parseInt(
                                              this.state.adult + 1
                                            ),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 class">
                                    <p className="b-sub-txt-2">Class</p>
                                    <label
                                      className="small-label"
                                      style={{ width: "100%" }}
                                    >
                                      Select Class
                                    </label>
                                    <select
                                      className="form-control t-select"
                                      defaultValue={"Economy"}
                                      name="airlineClass"
                                      id="airline_class"
                                      onChange={(e) =>
                                        this.inputOnewaySearchRequestChangeHandler(
                                          e
                                        )
                                      }
                                      fdprocessedid="92g40h"
                                    >
                                      <option value="Economy">Economy</option>
                                      <option value="StandardEconomy">
                                        Standard Economy
                                      </option>
                                      <option value="PremiumEconomy">
                                        Premium Economy
                                      </option>
                                      <option value="Business">Business</option>
                                      <option value="PremiumBusiness">
                                        Premium Business
                                      </option>
                                      <option value="First">First</option>
                                      <option value="PremiumFirst">
                                        Premium First
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>
                                  <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                                    <button
                                      type="button"
                                      className="btn btn-primary f-search-btn"
                                      onClick={() => this.SearchFlights("O")}
                                      disabled={
                                        this.state.originError ||
                                        this.state.destinationError ||
                                        SameAirportsError
                                      }
                                    >
                                      Search Flights
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="ex1-pills-2"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-2"
                            >
                              <div className="booking-content">
                                <div className="row">
                                  <div className="col-md-3 col-lg-3 col-12">

                                    <p className="b-sub-txt">&nbsp;</p>
                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            type="text"
                                            value={RoundTripOriginSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                RoundTripOriginSearchResults.length
                                              );
                                            }}
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Origin"
                                              )
                                            }
                                            placeholder="Origin"
                                            className="form-control"
                                            name="RoundTripOrigin"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {originErrorR &&
                                            RoundTripOriginSearchTerm.length >
                                            2 &&
                                            RoundTripOriginSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid origin!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !RoundTripOriginSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Departure Airport
                                              </span>
                                            )}
                                          {showSearchResultsRoundTripyOrigin &&
                                            RoundTripOriginSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef3}
                                              >
                                                {RoundTripOriginSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "RoundTripOrigin",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex3
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      <div
                                                        className={
                                                          index === 0 &&
                                                            selectedIndex3 == -1
                                                            ? "auto-result-block-selected "
                                                            : "auto-result-block"
                                                        }
                                                      >
                                                        <div className="float-start">
                                                          <span>
                                                            {airport.cityName} (
                                                            <span>
                                                              {airport.code}
                                                            </span>
                                                            )
                                                          </span>
                                                          <br />
                                                          <span>
                                                            {airport.airportName}
                                                          </span>
                                                        </div>
                                                        <div className="float-end pt-2">
                                                          <span>
                                                            {airport.countryCode}
                                                          </span>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsRoundTripyOrigin &&
                                            showNoResultsMessageRoundTripOrigin && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-1 pt-4 text-center position-relative">
                                    <div className="swap-image">
                                      <a onClick={this.swapOriginAndDestination}>
                                        <img
                                          src="assets/img/swap.webp"
                                          width={35}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>{" "}
                                  <div className="col-md-3 col-lg-3 col-12">

                                    <p className="b-sub-txt">&nbsp;</p>
                                    <div className="origin-form">
                                      <div className="origin">
                                        <div className="relative-position">
                                          <input
                                            ref={this.RoundTripOriginRef}
                                            type="text"
                                            value={RoundTripDestinationSearchTerm}
                                            onChange={this.handleSearch}
                                            onKeyDown={(e) => {
                                              this.handleKeyDown(
                                                e,
                                                RoundTripDestinationSearchResults.length
                                              );
                                            }}
                                            onFocus={() =>
                                              this.changeAirportListShowStatus(
                                                "Destination"
                                              )
                                            }
                                            placeholder="Destination"
                                            className="form-control"
                                            name="RoundTripDestination"
                                            autoComplete="off"
                                            onClick={(e) => e.target.select()}
                                            spellCheck="false"
                                          />
                                          {destinationErrorR &&
                                            RoundTripDestinationSearchTerm.length >
                                            2 &&
                                            RoundTripDestinationSearchResults.length ==
                                            0 && (
                                              <div className="error-message">
                                                Select valid Destination!
                                              </div>
                                            )}
                                          {showValidationMessage &&
                                            !RoundTripDestinationSearchTerm && (
                                              <span style={{ color: "red" }}>
                                                Please enter Destination Airport
                                              </span>
                                            )}
                                          {SameAirportsError && (
                                            <span className="sameaierr">
                                              Origin and Destination cannot be the
                                              same.
                                            </span>
                                          )}
                                          {showSearchResultsRoundTripDestination &&
                                            RoundTripDestinationSearchTerm.length >
                                            0 && (
                                              <div
                                                className="auto-suggest-result"
                                                ref={this.autoSuggestResultRef4}
                                              >
                                                {RoundTripDestinationSearchResults.map(
                                                  (airport, index) => (
                                                    <div
                                                      key={airport.code}
                                                      onClick={() =>
                                                        this.SelectAirport(
                                                          airport.code,
                                                          airport.airportName,
                                                          "RoundTripDestination",
                                                          airport,
                                                          airport.countryCode,
                                                          airport.cityName
                                                        )
                                                      }
                                                      className={
                                                        index === selectedIndex4
                                                          ? "auto-result-block-selected "
                                                          : "auto-result-block"
                                                      }
                                                      data-index={index}
                                                    >
                                                      <div
                                                        className={
                                                          index === 0 &&
                                                            selectedIndex4 == -1
                                                            ? "auto-result-block-selected "
                                                            : "auto-result-block"
                                                        }
                                                      >
                                                        <div className="float-start">
                                                          <span>
                                                            {airport.cityName} (
                                                            <span>
                                                              {airport.code}
                                                            </span>
                                                            )
                                                          </span>
                                                          <br />
                                                          <span>
                                                            {airport.airportName}
                                                          </span>
                                                        </div>
                                                        <div className="float-end pt-2">
                                                          <span>
                                                            {airport.countryCode}
                                                          </span>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {!showSearchResultsRoundTripDestination &&
                                            showNoResultsMessageRoundTripDestination && (
                                              <div>Sorry, no results found.</div>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  {/* Round trip date selection */}




                                  <div className="col-lg-1 col-md-1 col-12"></div>
                                  <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                                    <p className="b-sub-txt">Onward Date</p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.RoundTripDestinationRef}
                                        name="startDate"
                                        selected={this.startDate}
                                        value={dateFormat(
                                          this.state.objsectorlistRoundTrip
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setTravelDateRoundTrip(
                                            date,
                                            "S"
                                          )
                                        }
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                                    <p className="b-sub-txt">Return Date</p>

                                    <div className="origin-form">
                                      <DatePicker
                                        ref={this.endDatePickerRef}
                                        name="endDate"
                                        selected={this.endDate}
                                        value={dateFormat(
                                          this.state
                                            .objsectorlistRoundTripReturn
                                            .departureDate,
                                          "dd-mm-yyyy"
                                        )}
                                        onChange={(date) =>
                                          this.setTravelDateRoundTrip(
                                            date,
                                            "R"
                                          )
                                        }
                                        minDate={Date.parse(
                                          this.state.objsectorlistRoundTrip
                                            .departureDate
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-12">
                                    <p className="b-sub-txt-2">Traveller(s)</p>
                                    <div className="row">

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Adult (12+)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Adult"
                                          name="adult"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          value={this.state.adult}
                                          fdprocessedid="jtwsm"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.child, 10),
                                          }).map(
                                            (_, index) =>
                                              index > 0 && (
                                                <option key={index} value={index}>
                                                  {index < 10
                                                    ? `0${index}`
                                                    : index}
                                                </option>
                                              )
                                          )}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label className="small-label">
                                          Child (2-11)
                                        </label>
                                        <select
                                          className="form-control t-select"
                                          id="Child"
                                          name="child"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          value={this.state.child}
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length:
                                              10 - parseInt(this.state.adult, 10),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-4">
                                        <label
                                          className="small-label"
                                          style={{ position: "absolute" }}
                                        >
                                          Infant(Below 2yrs)
                                        </label>
                                        <select
                                          className="form-control t-select" style={{ marginTop: "24px", }}
                                          value={this.state.infant}
                                          id="Infant"
                                          name="infant"
                                          onChange={(e) =>
                                            this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                              e
                                            )
                                          }
                                          fdprocessedid="pxdj69"
                                        >
                                          {Array.from({
                                            length: parseInt(
                                              this.state.adult + 1
                                            ),
                                          }).map((_, index) => (
                                            <option key={index} value={index}>
                                              {index < 10 ? `0${index}` : index}
                                            </option>
                                          ))}
                                        </select>
                                      </div>


                                    </div>
                                  </div>


                                  <div className="col-lg-3 col-md-3 col-12 class">
                                    <p className="b-sub-txt-2">Class</p>
                                    <label
                                      className="small-label"
                                      style={{ width: "100%" }}
                                    >
                                      Select Class
                                    </label>
                                    <select
                                      className="form-control t-select"
                                      defaultValue={"Economy"}
                                      name="airlineClass"
                                      id="airline_class"
                                      fdprocessedid="92g40h"
                                      onChange={(e) =>
                                        this.inputOnewaySearchRequestChangeHandlerRoundTrip(
                                          e
                                        )
                                      }
                                    >
                                      <option value="Economy">Economy</option>
                                      <option value="StandardEconomy">
                                        Standard Economy
                                      </option>
                                      <option value="PremiumEconomy">
                                        Premium Economy
                                      </option>
                                      <option value="Business">Business</option>
                                      <option value="PremiumBusiness">
                                        Premium Business
                                      </option>
                                      <option value="First">First</option>
                                      <option value="PremiumFirst">
                                        Premium First
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-lg-1 col-md-1 col-12"></div>
                                  <div className="col-lg-4 col-md-4 col-12">
                                    <button
                                      type="button"
                                      className="btn btn-primary f-search-btn"
                                      onClick={() =>
                                        this.SearchFlightsRoundTrip("R")
                                      }
                                      disabled={
                                        this.state.originErrorR ||
                                        this.state.destinationErrorR ||
                                        SameAirportsError
                                      }
                                    >
                                      Search Flights
                                    </button>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-2 col-md-2" />
                                    <div className="col-lg-10 col-md-10">



                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="ex1-pills-3"
                              role="tabpanel"
                              aria-labelledby="ex1-tab-3"
                            ></div>
                          </div>
                          {/* route content */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
{/* Desktop search ends */}






        {this.state.recentSearch.length > 0 && <section id="recent-search-area">
          <div className="container">
            <div className="bg-white p-2 recent-block">
              <div className="row gx-4 align-items-center">
                {shouldRenderRecentSearch && <div className="col-lg-3 col-md-3">
                  <h4>
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#212529"
                      strokeWidth="1.2"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2Z"
                          fill="#212529"
                        ></path>{" "}
                      </g>
                    </svg>{" "}
                    Recent Searches
                  </h4>
                </div>
                }



                {shouldRenderRecentSearch && this.state.recentSearch.map((item, index) => (
                  (item.objSectorList[0] && <div key={index} id={index} onClick={() => this.SearchFlightsRecent(this.state.recentSearch[index])} className="col-lg-3 col-md-3">
                    <Link to="">
                      <div className="recent-list p-2">
                        <div>
                          <div className="route-box">
                            <div className="f-route">
                              <div>
                                <div className="f-route-name t-ovrflw" title={item.objSectorList[0] && item.objSectorList[0].origin}>
                                  {item.objSectorList[0] && item.objSectorList[0].origin}
                                </div>
                              </div>
                            </div>
                            <span className="sc-n84dau-56 cOakUb">
                              <div alt="flight-icon" className="sc-n84dau-62 jUcXfioneway"></div>
                            </span>
                            <div className="s-route">
                              <div>
                                <div className="f-route-name t-ovrflw" title={item.objSectorList[0] && item.objSectorList[0].destination}>
                                  {item.objSectorList[0] && item.objSectorList[0].destination}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="route-box">
                            <div className="f-route">
                              <div>
                                <p className="no-margin small-text date-text">{dateFormat(item.objSectorList[0].departureDate, "dd-mm-yyyy")}</p>
                              </div>
                              <div>

                              </div>
                            </div>
                            <div className="f-route">
                              {item.objSectorList.length > 1 ? (
                                <div>
                                  <p className="no-margin small-text date-text text-right">{dateFormat(item.objSectorList[item.objSectorList.length - 1].departureDate, "dd-mm-yyyy")}</p>
                                </div>
                              ) : null}
                              <div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>)
                ))}







              </div>
            </div>
          </div>
        </section>}
<section className="m-mob-app">
  <div className="container">
  <div className="bg-orange app-container">
    <div className="d-flex align-items-center">
      <div>
        <img src="assets/img/app.png" width={85} />
      </div>
      <div className="m-app-title">
    <h4 className="no-margin">Download Our Mobile App</h4>
    <p>Get one-touch access to your next trip by downloading the Travelmythri app. You can access exclusive deals and hidden features with the Travelmythri mobile app. </p>
    </div>

    </div>
    
   
  <div className="flex social-btns">
  <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/travel-mythri/id6474062647" target="_blank">
  <svg
  fill="#ffffff"
  width="35px"
  height="35px"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  stroke="#ffffff"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path d="M18.71 19.5C17.88 20.74 17 21.95 15.66 21.97C14.32 22 13.89 21.18 12.37 21.18C10.84 21.18 10.37 21.95 9.09997 22C7.78997 22.05 6.79997 20.68 5.95997 19.47C4.24997 17 2.93997 12.45 4.69997 9.39C5.56997 7.87 7.12997 6.91 8.81997 6.88C10.1 6.86 11.32 7.75 12.11 7.75C12.89 7.75 14.37 6.68 15.92 6.84C16.57 6.87 18.39 7.1 19.56 8.82C19.47 8.88 17.39 10.1 17.41 12.63C17.44 15.65 20.06 16.66 20.09 16.67C20.06 16.74 19.67 18.11 18.71 19.5ZM13 3.5C13.73 2.67 14.94 2.04 15.94 2C16.07 3.17 15.6 4.35 14.9 5.19C14.21 6.04 13.07 6.7 11.95 6.61C11.8 5.46 12.36 4.26 13 3.5Z" />{" "}
  </g>
</svg>
    <p>
      Available on<br /><span className="big-txt">App Store</span>
    </p>
  </a>
  <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.travelmythri.travel_mythri" target="_blank">
  <svg
  fill="#ffffff"
  width="30px"
  height="30px"
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg"
  stroke="#ffffff"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <title>ionicons-v5_logos</title>
    <path d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z" />
    <path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" />
    <path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" />
    <path d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z" />
  </g>
</svg>
    <p>
      Get it on<br /><span className="big-txt">Google Play</span>
    </p>
  </a>

</div>
  </div>
  </div>
</section>




        {this.state.isFlightDealsData && <section id="top-flight-deals">
          <div className="container">
            <h2 className="text-center">Top Flight Deals</h2>

            <OwlCarousel
              items={5}
              className="owl-carousel top-flight-deals"

              autoplay
              margin={8}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 3,
                },
                1000: {
                  items: 5,
                },
              }}
            >


              {this.state.flightDealsList.map((item1, index) => (
                <div className="flight-route" key={index}>
                  <div className="flight-offer">
                    <h5>Flat {item1.discountPercentage}% Off!</h5>
                  </div>
                  <div className="flight-details">
                    <div className="route-box">
                      <div className="f-route">
                        <div>
                          <div className="f-route-short">{item1.origin}</div>
                          <div className="f-route-name t-ovrflw" title="Jaipur">
                            {item1.originCity}
                          </div>
                        </div>
                      </div>
                      <span className="sc-n84dau-56 cOakUb">
                        <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                      </span>
                      <div className="s-route">
                        <div>
                          <div className="f-route-short">{item1.destination}</div>
                          <div className="f-route-name t-ovrflw" title="Dehradun">
                            {item1.destinationCity}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-center">Travel between</p>
                    <h6 className="text-center text-blue offer-date">
                      {item1.fromDate} - {item1.toDate}
                    </h6>
                    <h6 className="offer-price">₹ {item1.amount}</h6>
                  </div>
                </div>
              ))}


            </OwlCarousel>

          </div>
        </section>}



        <section id="why-us">
          <div className="container">




            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-12 promo-mb">
                <img src="assets/img/promo2.jpg" className="img-fluid rounded" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <img src="assets/img/promo1.jpg" className="img-fluid rounded" />
              </div>

            </div>
            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-12 promo-mb">
                <Link to="https://www.alhindholidays.com/" target="_blank"><img src="assets/img/alhindholidays.jpg" className="img-fluid rounded" /></Link>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <Link to="https://alhindstudyabroad.com/" target="_blank"><img src="assets/img/alhindstudyabroad.jpg" className="img-fluid rounded" /></Link>
              </div>
            </div>
          </div>

        </section>

        <div>
          <section id="mobile-app" style={{ padding: '0px' }}>
            <div className="container">
              <div className="bg-white app-container">
                <div className="row justify-content-between align-items-center p-4">

                  <div className="col-lg-3 col-md-3">
                    <img src="assets/img/mobile-app.webp" className="img-fluid" />
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="d-flex">
                      <div className="app-icons flex-row">
                        <div className="mb-3">
                          <Link to="https://play.google.com/store/apps/details?id=com.travelmythri.travel_mythri" target="_blank"><img src="assets/img/playstore.svg" className="app-icon-ios" /></Link>
                        </div>
                        <div className="flex-row">

                          <Link to="https://apps.apple.com/in/app/travel-mythri/id6474062647" target="_blank"><img src="assets/img/appstore.svg" className="app-icon-android" /></Link>

                        </div>
                      </div>
                      <div className="app-icons flex-row">
                        <div className="mb-3 pl-20">
                          <img src="assets/img/qr.png" className="icon-qr" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <h2>Download Our Mobile App</h2>
                    <p>
                      Travel Mythri offers a comprehensive travel solution, serving as your one-stop destination for flight bookings. Book your flight tickets with a huge discount.

                    </p>

                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <section>
          <div className="container">

          </div>
        </section>




        {/* model popup login */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Sign in to your TravelMythri Account!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form className="login-form" id="login">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Enter Mobile Number"
                  />
                  <button type="submit" className="btn btn-primary">
                    GET OTP
                  </button>
                  <div className="pt-2 pb-2">
                    <p className="or-section">Or</p>
                  </div>
                  <div className="text-center">
                    <a className="google-login">
                      <img
                        src="assets/img/Google__G__Logo.svg"
                        width={30}
                        alt=""
                      />{" "}
                      Sign in with Google
                    </a>
                  </div>
                  <div className="pt-3 pb-3 small-text text-center">
                    <p>
                      By continuing, you agree to our{" "}
                      <a className="privacy-link">
                        privacy policy &amp; terms of use.
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* BODY END */}
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <HomePageNew {...props} navigate={navigate} />;
}

export default WithNavigate;
