import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function PrivacyPolicy(props) {
  const navigate = useNavigate();

  const deleteProfile = () => {
    navigate('/Delete');
  };



  return (
    <div>
      

      <div className="container" style={{ paddingTop: '25px' }}>
        <h2>Travel Mythri - Privacy Policy </h2>
        <p>Welcome to Travel Mythri! This Privacy Policy, along with our Terms and Conditions, outlines how we handle the collection and management of any identifiable information about you. Before utilizing our services, please visit <Link to="/Terms_Conditions">https://travelmythry.com/Terms_Conditions</Link> and provide your consent. </p>
       <p>Travel Mythri (referred to as ‘‘TM/Travel Mythri/'travelmythri.com"') respects your privacy concerns. This Privacy Policy applies to individuals ('User') who purchase, intend to purchase, or inquire about any product(s) or service(s) available through TM's various customer interface channels, including its website, mobile site, mobile app and offline channels such as call centers and offices.</p>
       <p>In this Privacy Policy, "you" or "your" refers to the User, and "we," "us," or "our" refers to TM. For the purposes of this Privacy Policy, "Portal" encompasses the website(s), mobile site(s) and mobile app(s).</p>
     <p>
     By visiting the portal, providing your information or utilizing our Services offered on the portal, you explicitly agree to abide by the terms and conditions outlined in this Privacy Policy. Your personal information will primarily be stored and processed in India, which may have data protection laws differing from those in your country of residence. Through your use of the Portal and our Services, you consent to the transfer of your information outside your country of residence. If you do not agree to the terms of this Privacy Policy, please refrain from accessing our portal or using our services.
     </p>
   <p>This Privacy Policy excludes any website(s), mobile sites and mobile apps operated by third parties, even if their websites/products are linked to our portal. Users should be aware that the information and privacy practices of TM’s business partners, advertisers, sponsors or other sites to which TM provides hyperlink(s) may significantly differ from this Privacy Policy. Therefore, it is advisable for you to examine the privacy statements and policies of any such third parties with whom you engage.</p>
    <h5><strong>Cookies</strong></h5>
     <p>
     We use cookies to deliver our services and to help us improve our portal. A cookie is a small text file that we store on your computer/system when you use our portal. Cookies are integral to the functioning of our portal. They include, for instance, cookies that facilitate logging into secure areas and completing transactions for travel products. Cookies are utilized to recognize you upon your return to our portal, enabling us to tailor content to your preferences, such as language, region and currency choices. Additionally, cookies enable us to track visitor numbers and analyze their navigation patterns, enhancing portal functionality by ensuring ease of use and relevance. These cookies capture your portal visits, accessed pages and internal links followed, allowing us to optimize our website and tailor displayed travel products to better suit your interests. Furthermore, they enable personalized advertisement delivery across the internet.
     </p>
 <h5><strong>Personal details and it’s utilization</strong></h5>
<p>
When you make a booking/purchase, sign up for newsletters, or engage in any e-commerce transactions on our portal, TM collects personal information like, Name, Contact Number, Address, Credit Card details, Age and Email ID. This information is collected for the following purposes:
</p>

<p>
Service Booking: Names, addresses, phone numbers and age details are shared with relevant service providers to facilitate reservations and bookings for customers or travelers.
</p>
<p>
Promotional Offers: TM utilizes details like mobile numbers and email IDs to send information about promotional offers.
</p>
<p>
Member Registration: If you choose to register as a member on our portal, information such as name, address, telephone number, email address, password or OTPs are requested. This information is collected for various purposes, including user recognition, completing travel reservations, connecting with you for customer service purposes, meeting specific needs, improving products and services and confirming new member registrations and bookings.
</p>

<p>
Surveys: TM values your opinions and conducts surveys to gather feedback. Personal identification information may be used to invite your participation in surveys. Survey participation is voluntary and the collected information remains anonymous, except as required by law. Surveys are typically conducted to understand user experiences with TM and enhance the user-friendliness of our website, mobile site, and mobile app.
</p>
<p>
Automatic Logging of Session Data: We log session data, including IP address, operating system, browser software and user activities on their device, to evaluate user behavior. This data helps identify server issues and improve systems. 
</p>
 <h5><strong>Permissions</strong></h5>
<p>While using our portal (mobile application/website), you may be prompted to grant permission for various aspects such as identity, location, contacts, device ID, application, history, calendar, SMS, audio / video and more, in order to collect details including operating system, name and version, mobile network, preferred language, and others. These inputs are utilized to enhance and personalize your travel booking experience.</p>
 <h5><strong>Sharing and authorization</strong></h5>
<p>Your personal information may be shared internally with our group companies or externally with third parties and affiliates to grant you access to the products and services they offer. By accessing our portal or providing your information, you agree to the collection, utilization, storage, disclosure, and processing of your information on the portal as outlined in this Privacy Policy. If you provide us with personal information concerning other individuals or make bookings on their behalf, you confirm that you have the necessary authority to do so and authorize us to use and share the information in accordance with this Privacy Policy.</p>
 <h5><strong>Eligibility to use TM</strong></h5>
<p>To engage in direct transactions with TM and consent to the processing of your personal data, you must be at least 18 years old. We do not intentionally request or gather personal information from individuals under the age of 18 years. If you have provided any personal information of individuals under the age of 18 years, you affirm that you have the appropriate authority to do so and authorize us to utilize the information in accordance with this Privacy Policy.</p>
      <h5><strong>Changes to the Privacy Policy</strong></h5>
      <p>We retain the right to periodically revise the Privacy Policy to accommodate various legal, business and customer needs. We urge you to carefully read this Policy and regularly check this page for any updates we may make.</p>
        <h5><strong>Contact Us</strong></h5>
       <p>If you have any questions, concerns or complaints regarding the collection or use of your personal information under this Privacy Policy, please reach out to us at customercare@travelmythri.com</p>
       
       
       
        <ul>
         

          <li>If users wish to delete their accounts, they can do so by following this 
          
                <a onClick={deleteProfile} style={{ color: 'blue', cursor: 'pointer' }}>Link</a>    
          </li>

        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
