import React from 'react'
import Bb from './Bb'
import Cc from './Cc'

function Aa() {
    if(window.innerWidth<=700)
    {
        return (
            <div>
                <Bb/>
            </div>
          )
    }
    else
    {
        return (
            <div>
                <Cc/>
            </div>
          )
    }
  
}

export default Aa