import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { ToastContainer, toast } from 'react-toastify';

export function GetStates(data)
{
    return AxiosConfig.post("Utilities/GetState",data, HeaderConfig());
}

export function GetStatesByCountryCode(data)
{
    return AxiosConfig.post("Utilities/GetStateByCountryCode",data, HeaderConfig());
}

export function GetCountries()
{
    return AxiosConfig.post("Utilities/GetCountry",{}, HeaderConfig());
}

export function GetAirportList()
{
    return AxiosConfig.post("Utilities/GetAirport",{}, HeaderConfig());
}
export function GetRecentSearchList(data)
{
    return AxiosConfig.post("Account/GetRecentSearchList",data, HeaderConfig());
}

export function DownloadFile(jsonData,filename) { 
  
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${filename}.json`;
    link.href = url;
    link.click();
  
}

export function CheckInternetConnection()
{
  let isConnectionOK=true;
   if(!window.navigator.onLine)
    {
      isConnectionOK=false;
        toast.success('No Internet Connection', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
    }

    return isConnectionOK;
}