import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import dateFormat, { masks } from "dateformat";
import { GetIRTSearch } from '../../Services/OneWaySearch';
import DatePicker from "react-datepicker";
import { useNavigate, Link, json } from 'react-router-dom'
import { GetFlightDetailsIR } from '../../Services/FlightDetails';
import { AES, enc } from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetAirportList } from '../../Services/UtilitiesServices';
import ModifySearchPopUp from "./ModifySearchPopUp";
import { GetPricingDetails } from "../../Services/FlightDetails"
import ReactSlider from 'react-slider'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { URL_EncodeKey } from "../../Services/EncryptionService"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
class SearchResultIndividualRoundTrip extends Component {
  OnewayDestination = "";
  RoundTripDestination = "";

  constructor(props) {
    super(props);
    this.onewayOriginRef = React.createRef();
    this.onewayDestinationRef = React.createRef();
    this.RoundTripOriginRef = React.createRef();
    this.RoundTripDestinationRef = React.createRef();
    this.autoSuggestResultRef = React.createRef();
    this.autoSuggestResultRef2 = React.createRef();
    this.autoSuggestResultRef3 = React.createRef();
    this.autoSuggestResultRef4 = React.createRef();
    this.state = {
      inputValue: '',
      showEmailModal: false,
      showCheckboxes: false,
      showCheckboxesR: false,
      selectedItems: [], // Array to store selected item details
      selectedItemsR: [],
      AirportJson: [],
      selectedIndex: -1,
      selectedIndex2: -1,
      selectedIndex3: -1,
      selectedIndex4: -1,
      originError: false,
      destinationError: false,
      originErrorR: false,
      destinationErrorR: false,
      RequestFields: [],
      RequestBody: {},
      originSectorRound: {},
      destinationSectorRound: {},

      originSectorOneway: [],

      RoundTripFullSearchResult: [],
      LowerFareResult: [],
      ItinSearchResult: [],
      ItinSearchResultCopy: [],
      AvlAirlineResult: [],
      ItinSearchResultR: [],
      ItinSearchResultRCopy: [],
      isLoaded: false,
      isResultFound: false,
      ResponseBodyCopy: {},
      ResponseItinListOnwardCopy: {},
      ResponseItinListReturnCopy: {},

      //Filter
      ResultFIlter: {},
      FilteredSearchResult: {},

      //Radio
      OnwardSector: {},
      ReturnSector: {},


      FlightDetailsResult: [],
      objitinResult: [],
      objitinResultR: [],
      objseglistResult: [],
      objseglistResultR: [],
      itinId: 0,
      fareId: 0,
      providerCode: "",
      itinIdR: 0,
      fareIdR: 0,
      providerCodeR: "",

      //Filter
      ResultFIlter: {
        steps: []
      },
      Airlines: [],
      AirlineCheckbox: [],
      ItinSearchResultCopy: [],
      priceRangeMin: 0,
      priceRangeMax: 0,
      priceRangeRMin: 0,
      priceRangeRMax: 0,
      DepartureTimes: [],
      DepartureOnwardTimesCheckbox: [],
      DepartureReturnTimesCheckbox: [],
      DepartureTimesCheckbox: [],
      DepartureTimesReturn: [],
      ArrivalTimes: [],
      ArrivalOnwardTimesCheckbox: [],
      ArrivalReturnTimesCheckbox: [],
      ArrivalTimesCheckbox: [],
      ArrivalTimesReturn: [],
      Stops: [],
      StopsCheckbox_Onward: [],
      StopsCheckbox_Return: [],
      StopsReturn: [],


      //Autocomplete...
      value: '',
      suggestions: [],
      OnewayOriginSearchTerm: '',
      OnewayDestinationSearchTerm: '',
      OnewayOriginSearchResults: [],
      OnewayDestinationSearchResults: [],
      OnewayOriginAirport: [],
      OnewayDestinationAirport: [],
      onewayDepartureDate: new Date(),

      isFastestActive: false,
      isCheapestActive: false,
      isEarlierActive: false,

      //RoundTrip
      RoundTripOriginSearchTerm: '',
      RoundTripDestinationSearchTerm: '',
      RoundTripOriginSearchResults: [],
      RoundTripDestinationSearchResults: [],
      RoundTripOriginAirport: [],
      RoundTripDestinationAirport: [],

      activeTab: 'home', // Set the default active tab to 'home'
      isShared: false,
      emailSent: false,
    };
    this.secondInputRef = React.createRef();
    this.originDestinationSectorChange = this.originDestinationSectorChange.bind(this);
    this.swapOriginAndDestination = this.swapOriginAndDestination.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  handleItineraryChange = (index, item1) => {
    const { ItinSearchResult, priceRangeMax, OnewayFullSearchResult } = this.state;

    // Create a copy of ItinSearchResult and update the selected index
    const updatedItinSearchResult = [...ItinSearchResult];
    updatedItinSearchResult[index] = {
      ...updatedItinSearchResult[index],
      freeBaggage: item1.checkinBaggage,
      fareName: item1.fareName,
      netAmount: item1.netAmount,
      amount: item1.netAmount + item1.discount,
      fareId: item1.fareId,
    };

    // Check if the netAmount exceeds the current priceRangeMax
    let updatedOnewayFullSearchResult = { ...OnewayFullSearchResult };
    let updatedPriceRangeMax = priceRangeMax;
    if (priceRangeMax < item1.netAmount) {
      updatedPriceRangeMax = item1.netAmount;
      updatedOnewayFullSearchResult.maximumFare = item1.netAmount;
    }

    // Update the state
    this.setState({
      ItinSearchResult: updatedItinSearchResult,
      priceRangeMax: updatedPriceRangeMax,
      OnewayFullSearchResult: updatedOnewayFullSearchResult,
    });
  };

  handleItineraryChangeRT = (index, item1) => {
    const { ItinSearchResultR, priceRangeMax, OnewayFullSearchResult } = this.state;

    // Create a copy of ItinSearchResultR for immutability
    const updatedItinSearchResultR = [...ItinSearchResultR];
    updatedItinSearchResultR[index] = {
      ...updatedItinSearchResultR[index],
      freeBaggage: item1.checkinBaggage,
      fareName: item1.fareName,
      netAmount: item1.netAmount,
      amount: item1.netAmount + item1.discount,
      fareId: item1.fareId,
    };

    // Check if the netAmount exceeds the current priceRangeMax
    let updatedOnewayFullSearchResult = { ...OnewayFullSearchResult };
    let updatedPriceRangeMax = priceRangeMax;
    if (priceRangeMax < item1.netAmount) {
      updatedPriceRangeMax = item1.netAmount;
      updatedOnewayFullSearchResult.maximumFare = item1.netAmount;
    }

    // Update the state
    this.setState({
      ItinSearchResultR: updatedItinSearchResultR,
      priceRangeMax: updatedPriceRangeMax,
      OnewayFullSearchResult: updatedOnewayFullSearchResult,
    });
  };




  handleChangeEmail = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleEmailShow = () => {
    const { selectedItems } = this.state;


    if (selectedItems.length == 0) {
      toast.error('Please select Itinerary...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    this.setState({ emailSent: true });
    this.setState({ showEmailModal: true });
  };

  handleEmailClose = () => {
    this.setState({ showEmailModal: false });
  };

  // Toggle checkbox visibility
  handleShareButtonClick = () => {
    this.setState((prevState) => ({
      showCheckboxes: !prevState.showCheckboxes,
      showCheckboxesR: !prevState.showCheckboxesR,
    }));
  };

  // Handle checkbox change
  handleCheckboxChange = (item, index, type, event) => {
    const { selectedItems } = this.state;
    const isChecked = event.target.checked;

    if (isChecked && selectedItems.length >= 2) {
      toast.error('Maximum 2 Itineraries Possible...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    if (isChecked) {
      // If checked, add the item to the selectedItems array with the type field
      const newItem = { ...item, index, type };
      this.setState({
        selectedItems: [...selectedItems, newItem]
      });
    } else {
      // If unchecked, remove the item from the selectedItems array based on index
      this.setState({
        selectedItems: selectedItems.filter(selectedItem => selectedItem.index !== index)
      });
    }


  };
  handleCheckboxChangeR = (item, index, type, event) => {
    const { selectedItemsR } = this.state;
    const isChecked = event.target.checked;

    if (isChecked && selectedItemsR.length >= 2) {
      toast.error('Maximum 2 Itineraries Possible...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    if (isChecked) {
      // If checked, add the item to the selectedItems array with the type field
      const newItem = { ...item, index, type };
      this.setState({
        selectedItemsR: [...selectedItemsR, newItem]
      });
    } else {
      // If unchecked, remove the item from the selectedItems array based on index
      this.setState({
        selectedItemsR: selectedItemsR.filter(selectedItemR => selectedItemR.index !== index)
      });
    }

    if (isChecked && selectedItemsR.length >= 2) {
      toast.error('Maximum 2 Itineraries Possible...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
  };

  swapOriginAndDestination() {
    let tempData;
    tempData = this.state.OnewayOriginAirport;

    let origin = this.state.OnewayOriginSearchTerm;

    this.setState({
      OnewayOriginAirport: this.state.OnewayDestinationAirport,
      OnewayDestinationAirport: tempData,

      OnewayOriginSearchTerm: this.state.OnewayDestinationSearchTerm,
      OnewayDestinationSearchTerm: origin
    })

    //Round
    let tempData1;
    tempData1 = this.state.RoundTripOriginAirport;

    let origin1 = this.state.RoundTripOriginSearchTerm;

    this.setState({
      RoundTripOriginAirport: this.state.RoundTripDestinationAirport,
      RoundTripDestinationAirport: tempData1,

      RoundTripOriginSearchTerm: this.state.RoundTripDestinationSearchTerm,
      RoundTripDestinationSearchTerm: origin1
    })
  }



  resetFilter() {
    //Clear Airline Filter...
    this.setState({
      Airlines: [],
      Stops: [],
      StopsReturn: [],
      DepartureTimes: [],
      DepartureTimesReturn: [],
      ArrivalTimes: [],
      ArrivalTimesReturn: [],


      AirlineCheckbox: [],
      StopsCheckbox_Onward: [],
      StopsCheckbox_Return: [],
      DepartureTimesCheckbox: [],
      ArrivalTimesCheckbox: [],

      DepartureOnwardTimesCheckbox: [],
      DepartureReturnTimesCheckbox: [],
      ArrivalOnwardTimesCheckbox: [],
      ArrivalReturnTimesCheckbox: [],
    });

    if (this.state.isFastestActive == true || this.state.isCheapestActive == true || this.state.isEarlierActive == true) {
      this.setState({
        ItinSearchResult: this.state.ItinSearchResultCopy,
        ItinSearchResultR: this.state.ItinSearchResultRCopy,
        isFastestActive: false,
        isCheapestActive: false,
        isEarlierActive: false,
      });
    }

    if (this.state.ResponseBodyCopy.minimumFare != undefined) {
      this.setState({
        priceRangeMin: this.state.ResponseBodyCopy.minimumFare,
        priceRangeMax: this.state.ResponseBodyCopy.maximumFare,
        priceRangeRMin: this.state.ResponseBodyCopy.minimumFareR,
        priceRangeRMax: this.state.ResponseBodyCopy.maximumFareR,
      })
    }

  }


  formattedDate = (date) => {
    const options = { day: '2-digit', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  //AutoComplete fn...
  handleSearch = (event) => {
    let airportsTemp = this.state.AirportJson;

    const { value } = event.target;
    if (event.target.name == "onewayOrigin") {
      this.setState({ OnewayOriginSearchTerm: value });
      this.OnewayOrigin = "";
    }
    else if (event.target.name == "onewayDestination") {
      this.setState({ OnewayDestinationSearchTerm: value });
      this.OnewayDestination = ""
    }
    else if (event.target.name == "RoundTripOrigin") {
      this.setState({ RoundTripOriginSearchTerm: value });
      this.RoundTripOrigin = ""
    }
    else if (event.target.name == "RoundTripDestination") {
      this.setState({ RoundTripDestinationSearchTerm: value });
      this.RoundTripDestination = ""
    }


    if (value.length >= 2) {
      let index = -1;
      let SortedAirportArray = [];
      const matchingAirports = airportsTemp.filter(
        (airport) =>
          airport.code.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.airportName.toLowerCase().includes(value.toLowerCase()) ||
          airport.countryCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityName.toLowerCase().includes(value.toLowerCase())
      );

      // selected code to top of the Array.apply..
      for (let i = 0; i < matchingAirports.length; i++) {
        if (matchingAirports[i].code.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;

        }
        else if (matchingAirports[i].code.toLowerCase() === matchingAirports[i].cityCode.toLowerCase()) {
          index = i;
        }
      }

      if (index >= 0) {
        SortedAirportArray.push(matchingAirports[index]);
        for (let j = 0; j < matchingAirports.length; j++) {
          if (index != j) {
            SortedAirportArray.push(matchingAirports[j]);
          }
        }

      }
      else {
        SortedAirportArray = [];
        SortedAirportArray = matchingAirports;
      }


      if (SortedAirportArray.length === 1) {
        if (event.target.name == "onewayOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.onewayOriginRef.current.focus();
          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            this.onewayOriginRef.current.focus();
            this.setState({
              originError: false,
              SameAirportsError: false
            });
            return
          }
        }
        else if (event.target.name == "onewayDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);

          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            if (this.onewayDestinationRef && this.onewayDestinationRef.current && this.onewayDestinationRef.current.input) {
              this.onewayDestinationRef.current.input.focus();
              this.setState({
                destinationError: false,
                SameAirportsError: false
              });
            }
            return
          }
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.RoundTripOriginRef.current.focus();

          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            this.RoundTripOriginRef.current.focus();
            this.setState({
              originErrorR: false,
              SameAirportsError: false
            });
            return
          }

        }
        else if (event.target.name == "RoundTripDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);

          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            if (this.RoundTripDestinationRef && this.RoundTripDestinationRef.current && this.RoundTripDestinationRef.current.input) {
              this.RoundTripDestinationRef.current.input.focus();
              this.setState({
                destinationErrorR: false,
                SameAirportsError: false
              });
            }
            return
          }

        }
      }
      else {
        if (event.target.name == "onewayOrigin") {
          this.setState({ originError: true });
        }
        else if (event.target.name == "onewayDestination") {
          this.setState({ destinationError: true });
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.setState({ originErrorR: true });
        }
        else if (event.target.name == "RoundTripDestination") {
          this.setState({ destinationErrorR: true });
        }
      }

      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "onewayDestination") {

        this.setState({ OnewayDestinationSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: SortedAirportArray });
      }
    }
    else if (event.target.value == "")   //IF nothing in the textbox..
    {
      if (event.target.name == "onewayOrigin" || event.target.name == "RoundTripOrigin") {
        this.OnewayOrigin = "";
        this.RoundTripOrigin = "";

        this.setState({
          SameAirportsError: false,
          OnewayOriginSearchTerm: "",
          RoundTripOriginSearchTerm: "",
          OnewayOriginAirport: {},
          RoundTripOriginAirport: {},
        });

      }
      else if (event.target.name == "onewayDestination" || event.target.name == "RoundTripDestination") {
        this.OnewayDestination = "";
        this.RoundTripDestination = "";

        this.setState({
          SameAirportsError: false,
          OnewayDestinationSearchTerm: "",
          RoundTripDestinationSearchTerm: "",
          OnewayDestinationAirport: {},
          RoundTripDestinationAirport: {},
        });
      }


    }
    else {
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: [] });
      }
      else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: [] });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: [] });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: [] });
      }
    }
  };

  formChange(event) {
  }

  handleSelectAirport = (code, name, type, airportDetails) => {
    const selectedAirport = `${code}-${airportDetails.cityName}-${name}-${airportDetails.countryCode}`;
    if (type == "onewayOrigin") {
      this.OnewayOrigin = code;
      this.setState({
        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,

        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,
        OnewayOriginSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "onewayDestination") {
      this.OnewayDestination = code;
      this.RoundTripDestination = selectedAirport;
      this.setState({
        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,

        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,
        OnewayDestinationSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripOrigin") {
      this.RoundTripOrigin = code;
      this.setState({
        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,

        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,
        RoundTripOriginSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripDestination") {
      this.RoundTripDestination = code;
      this.setState({
        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,

        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,
        RoundTripDestinationSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }

    //If same Airport...
    if (type == "onewayOrigin" || type == "onewayDestination") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }
    }
    else {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }

    }

  };

  changeAirportListShowStatus(FieldName) {
    if (FieldName == "Destination") {
      this.setState({
        OnewayOriginSearchResults: [],
        RoundTripOriginSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayOrigin == "") {
        this.setState({
          OnewayOriginSearchTerm: "",
        })
      }

      if (this.RoundTripOrigin == "") {
        this.setState({
          RoundTripOriginSearchTerm: "",
        })
      }
    }
    else {
      this.setState({
        OnewayDestinationSearchResults: [],
        RoundTripDestinationSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayDestination == "") {
        this.setState({
          OnewayDestinationSearchTerm: "",
        })
      }


      if (this.RoundTripDestination == "") {
        this.setState({
          RoundTripDestinationSearchTerm: "",
        })
      }
    }
  }


  handleKeyDown = (event, airportListLength) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.handleArrowUp();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.handleArrowDown();
    }
    else if (event.key === "Enter" && airportListLength > 0) {
      event.preventDefault();
      this.handleEnterKey(event);
    }
  };

  handleArrowUp = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex > 0 ? selectedIndex - 1 : OnewayOriginSearchResults.length - 1;
    const newIndex2 = selectedIndex2 > 0 ? selectedIndex2 - 1 : OnewayDestinationSearchResults.length - 1;
    const newIndex3 = selectedIndex3 > 0 ? selectedIndex3 - 1 : RoundTripOriginSearchResults.length - 1;
    const newIndex4 = selectedIndex4 > 0 ? selectedIndex4 - 1 : RoundTripDestinationSearchResults.length - 1;
    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  handleArrowDown = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex < OnewayOriginSearchResults.length - 1 ? selectedIndex + 1 : 0;
    const newIndex2 = selectedIndex2 < OnewayDestinationSearchResults.length - 1 ? selectedIndex2 + 1 : 0;
    const newIndex3 = selectedIndex3 < RoundTripOriginSearchResults.length - 1 ? selectedIndex3 + 1 : 0;
    const newIndex4 = selectedIndex4 < RoundTripDestinationSearchResults.length - 1 ? selectedIndex4 + 1 : 0;
    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  scrollSelectedIntoView = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    if (this.autoSuggestResultRef.current) {
      const selectedElement = this.autoSuggestResultRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef2.current) {
      const selectedElement2 = this.autoSuggestResultRef2.current.querySelector(`[data-index="${selectedIndex2}"]`);
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef3.current) {
      const selectedElement3 = this.autoSuggestResultRef3.current.querySelector(`[data-index="${selectedIndex3}"]`);
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef4.current) {
      const selectedElement4 = this.autoSuggestResultRef4.current.querySelector(`[data-index="${selectedIndex4}"]`);
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  handleEnterKey = (event) => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    let defaultIndex = selectedIndex;
    let defaultIndex2 = selectedIndex2;
    let defaultIndex3 = selectedIndex3;
    let defaultIndex4 = selectedIndex4;
    if (defaultIndex >= -1 && defaultIndex < OnewayOriginSearchResults.length) {
      if (defaultIndex == -1)

        defaultIndex = 0;
      const selectedAirport = OnewayOriginSearchResults[defaultIndex];
      if (event.target.name == "onewayOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.onewayOriginRef.current.focus();
        this.setState({ originError: false });
        return
      }
    }
    if (defaultIndex2 >= -1 && defaultIndex2 < OnewayDestinationSearchResults.length) {
      if (defaultIndex2 == -1)

        defaultIndex2 = 0;
      const selectedAirport = OnewayDestinationSearchResults[defaultIndex2];
      if (event.target.name == "onewayDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          OnewayOriginSearchTerm,
        } = this.state;

        if (this.OnewayDestination == OnewayOriginSearchTerm) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          if (this.onewayDestinationRef && this.onewayDestinationRef.current && this.onewayDestinationRef.current.input) {
            this.onewayDestinationRef.current.input.focus();
            this.setState({ destinationError: false });
          }
          return
        }
      }
    }
    if (defaultIndex3 >= -1 && defaultIndex3 < RoundTripOriginSearchResults.length) {
      if (defaultIndex3 == -1)

        defaultIndex3 = 0;
      const selectedAirport = RoundTripOriginSearchResults[defaultIndex3];
      if (event.target.name == "RoundTripOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.RoundTripOriginRef.current.focus();
        this.setState({ originErrorR: false });
        return
      }
    }
    if (defaultIndex4 >= -1 && defaultIndex4 < RoundTripDestinationSearchResults.length) {
      if (defaultIndex4 == -1)

        defaultIndex4 = 0;
      const selectedAirport = RoundTripDestinationSearchResults[defaultIndex4];
      if (event.target.name == "RoundTripDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          RoundTripOriginSearchTerm,
        } = this.state;

        if (this.RoundTripDestination == RoundTripOriginSearchTerm) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          if (this.RoundTripDestinationRef && this.RoundTripDestinationRef.current && this.RoundTripDestinationRef.current.input) {
            this.RoundTripDestinationRef.current.input.focus();
            this.setState({ destinationErrorR: false });
          }
          return
        }
      }
    }

    //Reset same Airport Error...
    if (this.RoundTripOrigin == this.RoundTripDestination) {

      this.setState({ SameAirportsError: true });
      return;
    }
    else {

      this.setState({ SameAirportsError: false });
      if (this.onewayDestinationRef && this.onewayDestinationRef.current && this.onewayDestinationRef.current.input) {
        this.onewayDestinationRef.current.input.focus();
        this.setState({
          destinationError: false,
          SameAirportsError: false
        });
      }
      return
    }


  };




  componentDidMount() {
    window.scrollTo(0, 0);
    this.resetFilter();
    this.setState({
      isLoaded: false,
    })
    const params = new URLSearchParams(window.location.search);
    const IRSearchRequest = params.get('IRTSearchRequest');


    this.requestBody = JSON.parse(IRSearchRequest);

    if (JSON.parse(localStorage.getItem("TM_UDetails")) != null) {
      let userDetails = JSON.parse(localStorage.getItem("TM_UDetails"));

      this.requestBody.userId = userDetails.userId;

      if (this.requestBody.isDirect = "False")
        this.requestBody.isDirect = false;
      else
        this.requestBody.isDirect = true;
    }

    this.setAirportDetailsFromRequestBody();

    this.setState({
      originSectorRound: this.requestBody.objSectorList[0],
      destinationSectorRound: this.requestBody.objSectorList[1]
    })

    try {
      this.setState({ RequestBody: this.requestBody }, () => {
        this.getSearchResult();
      })
    } catch (error) {
      if (error.response.data.responseMessage == "InvalidToken") {
        this.props.navigate('/');
      }
      toast.success('Something went wrong...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    };

    //Get All Airports...
    this.getAllAirports();

  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };


  getAllAirports() {
    GetAirportList().then((response) => {
      if (response.data.status) {
        this.setState({
          AirportJson: response.data.objAirportList,
        })
      }
    })
  }

  setAirportDetailsFromRequestBody() {
    const params = new URLSearchParams(window.location.search);
    const IRSearchRequest = params.get('IRTSearchRequest');
    let dataOnward = {}, dataReturn = {};
    let requestDummyOnward = JSON.parse(IRSearchRequest).objSectorList[0];

    this.OnewayOrigin = requestDummyOnward.origin;
    this.RoundTripOrigin = requestDummyOnward.origin;
    dataOnward.cityCode = requestDummyOnward.origin;
    dataOnward.countryCode = requestDummyOnward.origincountry;

    this.OnewayDestination = requestDummyOnward.destination;
    this.RoundTripDestination = requestDummyOnward.destination;
    dataReturn.cityCode = requestDummyOnward.destination;
    dataReturn.countryCode = requestDummyOnward.destinationcountry;


    let date = {};
    date.departureDate = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd")

    this.setState({
      OnewayOriginAirport: dataOnward,
      OnewayDestinationAirport: dataReturn,

      RoundTripOriginAirport: dataOnward,
      RoundTripDestinationAirport: dataReturn,
      originSectorRound: date,
      onewayDepartureDate: date.departureDate
    })
  }


  //#region OnewaySection
  originDestinationSectorChange(event, type) {
    let originSectorMain = [];
    let originSector1 = this.state.RequestBody.objSectorList[0];

    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.origin = this.state.AirportJson[key].cityCode;
          originSector1.origincountry = this.state.AirportJson[key].countryCode;
          originSector1.tripmode = "O";

        }
      });
      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
        RoundTripFullSearchResult: originSectorMain

      });
    }
    else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.destination = this.state.AirportJson[key].cityCode;
          originSector1.destinationcountry = this.state.AirportJson[key].countryCode;

        }
      });

      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
        RoundTripFullSearchResult: originSectorMain

      })
    }

  }

  setStartDate(date) {
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    let onewayDepartureDate = selectedDate;
    RoundTripFullSearchResult.departureDate = selectedDate;

    this.setState({
      RoundTripFullSearchResult: RoundTripFullSearchResult,
      onewayDepartureDate: onewayDepartureDate
    })

    //set Round Trip Section...
    this.setTravelDateRoundTrip(date, "S");
  }


  dropDownSelectionChange = (event) => {
    const { name, value } = event.target;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 10 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 10 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      this.setState({
        infant: infantValue
      });
    }



    let testData = {}
    testData = this.state.RequestBody;

    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value)
      }
      else if (event.target.name == "child") {
        testData.child = Number(event.target.value)
      }
      else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value)
      }

      this.setState({
        RequestBody: testData,
        RoundTripFullSearchResult: testData
      })
      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value
      this.setState({
        RequestBody: testData,
        RoundTripFullSearchResult: testData
      })
    }
  }

  SearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originError == false && this.state.destinationError == false) {
      const {
        OnewayOriginSearchTerm,
        OnewayDestinationSearchTerm,
      } = this.state;
      if (!OnewayOriginSearchTerm || !OnewayDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = {}
        data.departureDate = this.state.onewayDepartureDate;
        data.origin = this.state.OnewayOriginAirport.cityCode;
        data.origincountry = this.state.OnewayOriginAirport.countryCode;
        data.destination = this.state.OnewayDestinationAirport.cityCode;
        data.destinationcountry = this.state.OnewayDestinationAirport.countryCode;
        data.Tripmode = type;

        requestToSend.objSectorList = [data];

        //TravelType...
        if (requestToSend.objSectorList[0].origincountry != "IN" || requestToSend.objSectorList[0].destinationcountry != "IN") {
          requestToSend.traveltype = "I"
          travelTypeToCheck = "I"
        }
        else {
          requestToSend.traveltype = "D"
          travelTypeToCheck = "D"
        }
        this.setState({
          RequestBody: requestToSend,
          RoundTripFullSearchResult: requestToSend

        });


        //Navigation....
        if (type == "O") {
          let url = "/Oneway?onewaySearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
          this.props.navigate(url);
        }
        else if (type == "R") {
          if (travelTypeToCheck == "D") {
          }
          else {

          }

        }
        else if (type == "S")
          this.props.navigate('/International');

        else if (type == "M")
          this.props.navigate('/Multicity');
      }

    }

  }

  //#endregion






  //#region FIlters...

  //Airline Filter
  filterCommonSettings = (event, item, i) => {
    window.scrollTo(0, 0);
    let value = item.airlineName
    let data = {};
    let isPresent = false;
    let airlines = [];
    airlines = this.state.Airlines;
    data = this.state.ResultFIlter;

    if (event.target.checked) {
      airlines.forEach((airline, index) => {
        if (airline == value) {
          return;
        }

      });
      airlines.push(value);
    }
    else {
      let ddd = [];
      airlines.map((item) => {

        if (item != value) {
          ddd.push(item);
        }
      });
      airlines = ddd
    }
    this.setState({
      Airlines: airlines,
    })
    let airlineData = {};
    airlineData.Airlines = airlines
    this.setState({
      ResultFIlter: airlineData
    })


    this.render();


    let value1 = this.state.AirlineCheckbox;
    value1[i] = event.target.checked;
    this.setState({ AirlineCheckbox: value1 })
  }

  priceRangeChange = (event) => {


    this.setState({

      priceRangeMin: event[0] - 1,
      priceRangeMax: event[1] + 1,
    })


  }

  priceRangeChangeR = (event) => {

    this.setState({

      priceRangeRMin: event[0] - 1,
      priceRangeRMax: event[1] + 1,
    })


  }

  stopsChanged = (event, i) => {
    window.scrollTo(0, 0);
    let data = [];
    let resultFIlter = {};
    data = this.state.Stops;
    resultFIlter = this.state.ResultFIlter;

    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }


    this.setState({
      Stops: data
    })



    let value1 = this.state.StopsCheckbox_Onward;
    value1[i] = event.target.checked;
    this.setState({ StopsCheckbox_Onward: value1 })
  }

  stopsReturnChanged = (event, i) => {
    window.scrollTo(0, 0);
    let data = [];
    let resultFIlter = {};
    data = this.state.StopsReturn;
    resultFIlter = this.state.ResultFIlter;

    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }


    this.setState({
      StopsReturn: data
    })



    let value1 = this.state.StopsCheckbox_Return;
    value1[i] = event.target.checked;
    this.setState({ StopsCheckbox_Return: value1 })
  }

  SortList = (type) => {
    let data = {}
    data = this.state.ResultFIlter;
    data.sortList = type;
    this.setState({
      ResultFIlter: data
    })

    //Run Filter...
    this.getFilteredSearchResult("sort");
  }

  //DepartureTImeSort...
  departreTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.DepartureTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }

    this.setState({
      DepartureTimes: data
    })



    let value1 = this.state.DepartureOnwardTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ DepartureOnwardTimesCheckbox: value1 })
  }

  departureTimeReturn = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.DepartureTimesReturn;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);
        }
      });
      data = ddd
    }

    this.setState({
      DepartureTimesReturn: data
    })



    let value1 = this.state.DepartureReturnTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ DepartureReturnTimesCheckbox: value1 })
  }

  //ArrivalTImeSort...
  arrivalTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.ArrivalTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }

    this.setState({
      ArrivalTimes: data
    })

    //REsetFIlter
    let value1 = this.state.ArrivalOnwardTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ ArrivalOnwardTimesCheckbox: value1 })
  }

  arrivalTimeReturn = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.ArrivalTimesReturn;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }

    this.setState({
      ArrivalTimesReturn: data
    })

    //ResetFIlter...
    let value1 = this.state.ArrivalReturnTimesCheckbox;
    value1[i] = event.target.checked;
    this.setState({ ArrivalReturnTimesCheckbox: value1 })
  }

  //Sort By Cheapest Fastest Earliest...
  sortArray = (event) => {
    window.scrollTo(0, 0);
    let type = event.target.value;
    if (type == "cheapest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount),
        ItinSearchResultR: prevState.ItinSearchResultR.slice().sort((a, b) => a.netAmount - b.netAmount),
        isCheapestActive: true,
        isFastestActive: false,
        isEarlierActive: false,
      }));
    }
    else if (type == "fastest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.durationInMinutes - b.durationInMinutes),
        ItinSearchResultR: prevState.ItinSearchResultR.slice().sort((a, b) => a.durationInMinutes - b.durationInMinutes),
        isFastestActive: true,
        isCheapestActive: false,
        isEarlierActive: false,
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        }),
        ItinSearchResultR: prevState.ItinSearchResultR.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        }),
        isEarlierActive: true,
        isCheapestActive: false,
        isFastestActive: false,
      }));
    }

  };

  //Here Filter the data...
  getFilteredSearchResult = (type) => {

    let tempArray = [];

    if (type == "airline")//AIrline FIlter ...)
    {

      let list = [], airlineList = [];
      list = this.state.ItinSearchResultCopy;
      airlineList = this.state.ResultFIlter.Airlines;
      if (this.state.Airlines.length) {
        list.length > 0 && list.map((item, index) => (
          this.state.Airlines.map((airline, index2) => {
            if (airline == item.airlineName) {
              tempArray.push(item);
            }
          })

        ))
      }
      else {
        tempArray = list;
      }

    }
    else if (type == "price")//Price Range Filter... 
    {
      this.state.ItinSearchResult.map((item2, index3) => {
        if (item2.netAmount < this.state.ResultFIlter.priceRangeMax) {
          tempArray.push(item2);
        }
      })

    }
    else if (type == "stop") {


      if (this.state.ResultFIlter.steps.length) {
        let isAllSelected = false;
        if (this.state.ResultFIlter.steps.length == 3) {
          return;
        }

        this.state.ItinSearchResult.length > 0 && this.state.ItinSearchResult.map((item, index) => (
          this.state.ResultFIlter.steps.map((stop, index2) => {
            if (stop == item.noofStop) {
              tempArray.push(item);
            }
          })

        ))
      }

    }
    else if (type == "sort") {
      tempArray = this.state.ItinSearchResult.sort((a, b) => new Date(...a.initialRegistration.split('/').reverse()) - new Date(...b.initialRegistration.split('/').reverse()))


    }
    else if (type == "departureRange") {

      let departRange = this.state.ResultFIlter.departureRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.departureDate} ${item.departureTime}`);
        const hours = getDate.getHours();


        if (departRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (departRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }
    else if (type == "arrivalRange") {

      let arrivalRange = this.state.ResultFIlter.arrivalRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.arrivalDate} ${item.arrivalTime}`);
        const hours = getDate.getHours();


        if (arrivalRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (arrivalRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }

    this.setState({
      ItinSearchResult: tempArray
    })

    this.render();
  }

  sortByDropDown(type) {

    if (type == "1") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount)
      }));
    }
    else if (type == "2") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.duration - b.duration)
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        })
      }));
    }
  }

  //#endregion




  //#region RoundTrip ModifySearch

  inputSearchSectorChangeFocusOutRoundTrip = (event, type) => {
    let objSector = [];
    let sectorValues = {}, sectorValuesReturn = {};
    sectorValues = this.state.originSectorRound;
    sectorValuesReturn = this.state.destinationSectorRound;
    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.origin = this.state.AirportJson[key].cityCode;
          sectorValues.origincountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.destination = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.destinationcountry = this.state.AirportJson[key].countryCode;
        }
      });
    }
    else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.destination = this.state.AirportJson[key].cityCode;
          sectorValues.destinationcountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.origin = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.origincountry = this.state.AirportJson[key].countryCode;
        }
      });
    }
    //Onward & Return...
    this.setState({
      originSectorRound: sectorValues,
      destinationSectorRound: sectorValuesReturn,
      RoundTripFullSearchResult: sectorValues,
      RoundTripFullSearchResult: sectorValuesReturn
    })

  }

  inputOnewaySearchRequestChangeHandlerRoundTrip = (event) => {

    let testData = {}
    testData = this.state.RequestBody;
    testData.departureDate = this.state.RoundTripFullSearchResult.departureDate;
    testData.returnDate = this.state.RoundTripFullSearchResult.returnDate;

    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value)
      }
      else if (event.target.name == "child") {
        testData.child = Number(event.target.value)
      }
      else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value)
      }

      this.setState({
        RequestBody: testData,
        RoundTripFullSearchResult: testData

      })


      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value
      this.setState({
        RequestBody: testData,
        RoundTripFullSearchResult: testData
      })
    }
  }

  //#region RoundTrip Date Picker 
  setTravelDateRoundTrip = (date, type) => {
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult, originSectorRound = {}, destinationSectorRound = [];
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    if (type == "S") {
      originSectorRound = this.state.originSectorRound;
      RoundTripFullSearchResult.departureDate = selectedDate;
      originSectorRound.departureDate = selectedDate;
      if (selectedDate > RoundTripFullSearchResult.returnDate) //Departure date GREATER THAN return Date...
      {
        destinationSectorRound = this.state.destinationSectorRound;
        RoundTripFullSearchResult.returnDate = selectedDate;
        destinationSectorRound.departureDate = selectedDate;

        //Set Return Date...
        this.setDatePicker(destinationSectorRound, RoundTripFullSearchResult);
      }
      let onewayDepartureDate = selectedDate;
      this.setState({
        originSectorRound: originSectorRound,
        RoundTripFullSearchResult: RoundTripFullSearchResult,
        onewayDepartureDate: onewayDepartureDate,
      })
    }
    else {
      destinationSectorRound = this.state.destinationSectorRound;
      RoundTripFullSearchResult.returnDate = selectedDate;
      destinationSectorRound.departureDate = selectedDate;

      //Set Return Date...
      this.setDatePicker(destinationSectorRound, RoundTripFullSearchResult);
    }
  }
  setDatePicker(data1, data2, data3) {

    this.setState({
      [data1]: data1,
      [data2]: data2,
    })
  }
  //#endregion




  ModifyRoundSearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originErrorR == false && this.state.destinationErrorR == false) {
      const {
        RoundTripOriginSearchTerm,
        RoundTripDestinationSearchTerm,
      } = this.state;
      if (!RoundTripOriginSearchTerm || !RoundTripDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = [];
        data.push(this.state.originSectorRound);
        data.push(this.state.destinationSectorRound);

        let onwardObj = {}, returnObj = {};
        onwardObj = this.state.originSectorRound;
        returnObj = this.state.destinationSectorRound;

        onwardObj.origin = this.state.RoundTripOriginAirport.cityCode
        onwardObj.origincountry = this.state.RoundTripOriginAirport.countryCode
        onwardObj.destination = this.state.RoundTripDestinationAirport.cityCode
        onwardObj.destinationcountry = this.state.RoundTripDestinationAirport.countryCode


        returnObj.origin = this.state.RoundTripDestinationAirport.cityCode
        returnObj.origincountry = this.state.RoundTripDestinationAirport.countryCode
        returnObj.destination = this.state.RoundTripOriginAirport.cityCode
        returnObj.destinationcountry = this.state.RoundTripOriginAirport.countryCode

        requestToSend.objSectorList = [onwardObj, returnObj]

        //TravelType...
        if (requestToSend.objSectorList[0].origincountry != "IN" || requestToSend.objSectorList[0].destinationcountry != "IN") {
          requestToSend.traveltype = "I"
          travelTypeToCheck = "I"
        }
        else {
          requestToSend.traveltype = "D"
          travelTypeToCheck = "D"
        }

        requestToSend.objSectorList[0].Tripmode = "O"
        requestToSend.objSectorList[1].Tripmode = "O"

        this.setState({
          RequestBody: requestToSend,
          RoundTripFullSearchResult: requestToSend
        });



        //Navigation....
        if (type == "O")
          this.props.navigate('/Oneway');
        else if (type == "R") {
          if (travelTypeToCheck == "D") {

            let url = "/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));

            this.props.navigate(url);
            this.componentDidMount();
          }

          else {

            let url = "/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
            this.props.navigate(url);
          }
          //Combained RoundTrip
        }
        else if (type == "S")
          this.props.navigate('/International');

        else if (type == "M")
          this.props.navigate('/Multicity');
      }
    }


  }
  //#endregion
  //Radio button
  OnwardEvent = (item) => {
    this.setState({
      OnwardSector: item,

      activeTab: 'profile',
    })
    window.scrollTo(0, 0);
    this.render();
  }
  ReturnEvent = (item) => {

    this.setState({
      ReturnSector: item,

    })
  }

  getSearchResult() {

    GetIRTSearch(this.requestBody).
      then((response) => {
        if (!CheckNetworkStatus()) {
          this.props.navigate('/issue');
        }
        if (!response.data.status) {
          if (response.data.responseMessage == "No Result Found") {
            this.setState({
              isResultFound: true
            })

          }
        }
        if (response.data.status) {
          //Initializing Object...
          let tempStopsCheckbox_Onward = new Array(3).fill(false);
          let tempStopsCheckbox_Return = new Array(3).fill(false);
          let tempDepartureOnwardTimesCheckbox = new Array(4).fill(false);
          let tempDepartureReturnTimesCheckbox = new Array(4).fill(false);
          let tempArrivalOnwardTimesCheckbox = new Array(4).fill(false);
          let tempArrivalReturnTimesCheckbox = new Array(4).fill(false);
          let StopsCheckbox_Return = new Array(3).fill(false);

          let avlAirlinesArray = new Array(response.data.objAvlairlineList.length).fill(false);

          this.setState({
            LowerFareResult: response.data.objlowfareList,
            ItinSearchResult: response.data.objItinList,
            ItinSearchResultCopy: response.data.objItinList,
            AvlAirlineResult: response.data.objAvlairlineList,
            RoundTripFullSearchResult: response.data,
            ItinSearchResultR: response.data.objItinListR,
            ItinSearchResultRCopy: response.data.objItinListR,
            ResponseBodyCopy: response.data,

            ResponseItinListOnwardCopy: response.data.objItinList,
            ResponseItinListReturnCopy: response.data.objItinListR,

            priceRangeMax: response.data.maximumFare,
            priceRangeRMax: response.data.maximumFareR,

            OnewayOriginSearchTerm: response.data.origin,
            OnewayDestinationSearchTerm: response.data.destination,

            RoundTripOriginSearchTerm: response.data.origin,
            RoundTripDestinationSearchTerm: response.data.destination,

            AirlineCheckbox: avlAirlinesArray,
            StopsCheckbox_Onward: tempStopsCheckbox_Onward,
            StopsCheckbox_Return: tempStopsCheckbox_Return,
            DepartureOnwardTimesCheckbox: tempDepartureOnwardTimesCheckbox,
            DepartureReturnTimesCheckbox: tempDepartureReturnTimesCheckbox,
            ArrivalOnwardTimesCheckbox: tempArrivalOnwardTimesCheckbox,
            ArrivalReturnTimesCheckbox: tempArrivalReturnTimesCheckbox,

            isLoaded: true
          });
        }
        //}
      })
      .catch((error) => {
        if (error.response && error.response.status == 403 && !window.navigator.onLine) {
          this.props.navigate('/issue');
        }
        else if (error.response && error.response.data && (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut")) {


          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");
          localStorage.removeItem("NonUsertokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.removeItem("RS");

          toast.success('Unauthorized Access...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.props.navigate('/');

        }
        else {

          toast.error(`Something went wrong`, {
            position: 'bottom-center',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            onClose: () => {
              // Redirect to the previous page
              this.props.navigate('/');
            },
          });
        }
      });

  }

  getFlightDetailsResult(id, fare, code, idR, fareR, codeR) {

    this.setState({
      FlightDetailsResult: [],
      objitinResult: [],
      objitinResultR: [],
      objseglistResult: [],
      objseglistResultR: [],
      RequestFields: [],
    })

    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
      itinIdR: idR,
      fareIdR: fareR,
      providerCodeR: codeR,
    };

    GetFlightDetailsIR(data).then((response) => {
      if (response.data.status) {
        this.setState({


          objseglistResult: response.data.objitin.objseglist,
          objseglistResultR: response.data.objitinR.objseglist,
          objitinResult: response.data.objitin,
          objitinResultR: response.data.objitinR,
          FlightDetailsResult: response.data,
          RequestFields: data,
        });
      }
    })
      .catch((error) => {
        if (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut") {


          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");
          localStorage.removeItem("NonUsertokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.removeItem("RS");

          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.props.navigate('/');

        }
        else {
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }

      });


  }

  BookingRequest(id, fare, code, idR, fareR, codeR) {

    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
      itinIdR: idR,
      fareIdR: fareR,
      providerCodeR: codeR,
    };


    const secretKey = URL_EncodeKey(); // Secret key used for encryption
    const jsonData = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonData, secretKey).toString();
    const bookparam = encodeURIComponent(encryptedData);
    this.props.navigate(`/checkout?checkoutRequest=${bookparam}`)


  }

  shareOnWhatsApp = () => {
    this.setState({ isShared: true });
    const currentUrl = window.location.href;
    const { selectedItems, selectedItemsR, RoundTripFullSearchResult } = this.state;

    if (selectedItems.length == 0) {
      toast.error('Please select Itinerary...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    // Construct the message for each item in the list
    const message = selectedItems.map(item => `
      Onward Flight: ${item.airlineName} ${item.flightDetails}
      From: ${item.sourceAirport} ${item.departureDate} ${item.departureTime}
      To: ${item.destinationAirport} ${item.arrivalDate} ${item.arrivalTime}  
      Travellers: Adult - ${RoundTripFullSearchResult.adult}, Child - ${RoundTripFullSearchResult.child}, Infant - ${RoundTripFullSearchResult.infant}
      Fare: ₹${item.amount}       
      `).join('\n');

    const messageR = selectedItemsR.map(item => `            
      Return Flight: ${item.airlineName} ${item.flightDetails}
      From: ${item.sourceAirport} ${item.departureDate} ${item.departureTime}
      To: ${item.destinationAirport} ${item.arrivalDate} ${item.arrivalTime}
      Travellers: Adult - ${RoundTripFullSearchResult.adult}, Child - ${RoundTripFullSearchResult.child}, Infant - ${RoundTripFullSearchResult.infant}
      Fare: ₹${item.amount}        
      `).join('\n');


    const fullMessage = `${message}-------------------------------------------------${messageR}`;

    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(fullMessage)}`;

    // Open WhatsApp with the message
    window.open(url, '_blank');
  };

  shareByEmail = (recipient) => {
    const currentUrl = window.location.href;
    const { selectedItems, selectedItemsR, RoundTripFullSearchResult } = this.state;

    // Construct the body for each item in the list
    const body = selectedItems.map(item => `
      Onward Flight: ${item.airlineName} ${item.flightDetails}
      From: ${item.sourceAirport} ${item.departureDate} ${item.departureTime}
      To: ${item.destinationAirport} ${item.arrivalDate} ${item.arrivalTime}
      Travellers: Adult - ${RoundTripFullSearchResult.adult}, Child - ${RoundTripFullSearchResult.child}, Infant - ${RoundTripFullSearchResult.infant}
      Fare: ₹${item.amount}
      
      -------------------------------------------------
          `).join('\n');

    const bodyR = selectedItemsR.map(item => `
      
      Return Flight: ${item.airlineName} ${item.flightDetails}
      From: ${item.sourceAirport} ${item.departureDate} ${item.departureTime}
      To: ${item.destinationAirport} ${item.arrivalDate} ${item.arrivalTime}
      Travellers: Adult - ${RoundTripFullSearchResult.adult}, Child - ${RoundTripFullSearchResult.child}, Infant - ${RoundTripFullSearchResult.infant}
      Fare: ₹${item.amount}
      -------------------------------------------------
          `).join('\n');

    const subject = `Flight Details`;
    const fullBody = `
${body} ${bodyR}
Please click on the following link to view the full availability:
${currentUrl}
    `;

    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(recipient)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(fullBody)}`;

    // Open Gmail's compose page with the subject and body pre-filled
    window.open(gmailLink, '_blank');
  };

  handleShareViaEmail = () => {
    const recipient = this.state.inputValue;
    this.handleEmailClose();

    if (recipient) {
      // Validate the email format (simple regex)
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(recipient)) {
        this.shareByEmail(recipient);
      } else {
        toast.error('Please enter a valid email address...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } else {
      toast.error('Email address is required...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  handleFilter = (item) => {
    const newDate = item.date;

    // Modify the request body
    this.modifiedRequestBody = {
      ...this.state.RequestBody, // Copy the existing requestBody
      isDirect: true, // Update the isDirect property
      objSectorList: [
        {
          ...this.state.RequestBody.objSectorList[0], // Copy the first element of objSectorList
          departureDate: newDate, // Update the first departureDate
        },
        ...this.state.RequestBody.objSectorList.slice(1), // Keep the rest of the objSectorList unchanged
      ],
    };

    const Param = encodeURIComponent(JSON.stringify(this.modifiedRequestBody));
    this.props.navigate(`/Roundtrip?IRTSearchRequest=${Param}`)
    this.componentDidMount();
  };

  handleFilterReturn = (item) => {
    const newDate = item.date;

    // Modify the request body
    this.modifiedRequestBody = {
      ...this.state.RequestBody, // Copy the existing requestBody
      isDirect: true, // Update the isDirect property
      objSectorList: [
        ...this.state.RequestBody.objSectorList.slice(0, 1), // Keep the first element unchanged
        {
          ...this.state.RequestBody.objSectorList[1], // Copy the second element of objSectorList
          departureDate: newDate, // Update the second departureDate
        },
        ...this.state.RequestBody.objSectorList.slice(2), // Keep the rest of the objSectorList unchanged (if exists)
      ],
    };

    const Param = encodeURIComponent(JSON.stringify(this.modifiedRequestBody));
    this.props.navigate(`/Roundtrip?IRTSearchRequest=${Param}`)
    this.componentDidMount();
  };

  handlePrevFilter = (date, tripmode) => {
    const formatteddate = new Date(date);

    formatteddate.setDate(formatteddate.getDate() - 1);

    const newDate = formatteddate.toLocaleDateString('en-CA'); // Format as 'YYYY-MM-DD'

    const item = { date: newDate };

    if (tripmode == 'O') {
      this.handleFilter(item);
    }
    else if (tripmode == 'R') {
      this.handleFilterReturn(item);
    }

  };

  handleNextFilter = (date, tripmode) => {
    const formatteddate = new Date(date);

    formatteddate.setDate(formatteddate.getDate() + 1);

    const newDate = formatteddate.toLocaleDateString('en-CA'); // Format as 'YYYY-MM-DD'

    const item = { date: newDate };

    if (tripmode == 'O') {
      this.handleFilter(item);
    }
    else if (tripmode == 'R') {
      this.handleFilterReturn(item);
    }

  };

  render() {
    const { showCheckboxes, showCheckboxesR, selectedItems, selectedItemsR } = this.state;
    //AUtoComplete...
    const { showValidationMessage, SameAirportsError } = this.state;
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    const { OnewayOriginSearchTerm, OnewayDestinationSearchTerm, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchTerm, RoundTripDestinationSearchTerm, RoundTripOriginSearchResults, RoundTripDestinationSearchResults, originError, destinationError, originErrorR, destinationErrorR } = this.state;
    const showSearchResultsOnewayOrigin = OnewayOriginSearchResults.length > 0;
    const showSearchResultsOnewayDestination = OnewayDestinationSearchResults.length > 0;

    const showSearchResultsRoundTripyOrigin = RoundTripOriginSearchResults.length > 0;
    const showSearchResultsRoundTripDestination = RoundTripDestinationSearchResults.length > 0;

    const showNoResultsMessageOnewayOrigin = RoundTripOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageOnewayDestination = OnewayDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;

    const showNoResultsMessageRoundTripOrigin = OnewayOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageRoundTripDestination = RoundTripDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;
    const whatsappTooltip = <Tooltip id="whatsapp-tooltip">Share on WhatsApp</Tooltip>;
    const emailTooltip = <Tooltip id="email-tooltip">Share via Email</Tooltip>;
    const shareTooltip = <Tooltip id="share-tooltip">Share Itinerary</Tooltip>;

    return (this.state.isLoaded && !this.state.isResultFound) ? (
      <div>
        {/* Modify search desktop view starts */}
        {(window.innerWidth > 700) && <div className="inner-search-area desk-modify">
          <div className="inner-search" id="inner-search">
            <div className="container">


              <div>
                <ModifySearchPopUp parentFunction={this.componentDidMount} />
              </div>


              <Modal show={this.state.showEmailModal} onHide={this.handleEmailClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title><strong>Email this search</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <div>
                    <input
                      type="text"
                      value={this.state.inputValue}
                      onChange={this.handleChangeEmail}
                      placeholder="Email ID"
                      className="form-control"
                    />
                  </div>

                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" className="w-100" onClick={() => this.handleShareViaEmail()} style={{ textTransform: 'none' }}>
                    Share
                  </Button>
                </Modal.Footer>

              </Modal>
            </div>
          </div>

        </div>}
        {/* Modify search desktop view ends */}

        {/* Modify search mobile view starts */}
        {(window.innerWidth < 700) && <div className="inner-search-area mob-modify">
          <div className="inner-search" id="inner-search" alt="">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="mob-mod-list">
                    <li>{this.state.RoundTripFullSearchResult.origin}</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg></li>
                    <li>{this.state.RoundTripFullSearchResult.destination}</li>

                    <li>|</li>
                    <li>{this.formattedDate(this.state.RoundTripFullSearchResult.departureDate)} - {this.formattedDate(this.state.RoundTripFullSearchResult.returnDate)}</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg> {this.state.RoundTripFullSearchResult.adult + this.state.RoundTripFullSearchResult.child + this.state.RoundTripFullSearchResult.infant}</li>
                    <li> <a className="mod-btn-mob" data-bs-toggle="modal" data-bs-target="#modifyModal" >MODIFY</a></li>
                  </ul>






                </div>


              </div>
            </div>
          </div>
        </div>}
        {/* Modify search mobile view ends */}
        {/* Modify popup */}


        <div className="modal fade" id="modifyModal" tabIndex={-1} aria-labelledby="modifyModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Modify Search.</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <ModifySearchPopUp parentFunction={this.componentDidMount} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">

              {/* Sidebar desktop starts */}
              <div className="side-bar side-bar-desk">
                <div className="indv-scroll" id="scrollbarind">
                  <div className="bg-white" id="sidebar-wrapper">
                    {/*Airline Price area starts*/}
                    <div className="airline-area">
                      <div className="d-flex flex-row reset-area">
                        <div>&nbsp;</div>
                        <span className="reset-filter" onClick={this.resetFilter}>Reset filters</span>
                      </div>
                      <div className="side-bar-title"><h4>Airlines</h4></div>
                      {this.state.AvlAirlineResult && this.state.AvlAirlineResult.length > 0 ? (
                        <form className="airline" id="scrollbar1">
                          {this.state.AvlAirlineResult.map((item, index) => (
                            <div className='side-loop' key={index}>
                              <label style={{ width: '100%', fontSize: '1.1em' }}>
                                <div className="d-flex airline-price mb-2">
                                  <div className="flex-row">
                                    <img src={`assets/img/logos/${item.airlineCode}.gif`} width={30} />
                                  </div>
                                  <div className="flex-row pt-2 pl-5 me-auto">
                                    <h6>{item.airlineName}<span>({item.count})</span></h6>
                                  </div>
                                  <div className="flex-row pt-2 mr-10">
                                    <h6>₹ {Math.round(item.minAmount)}</h6>
                                  </div>
                                  <div className="air-check-box d-flex align-items-center">
                                    <input type="checkbox"
                                      checked={this.state.AirlineCheckbox[index]}
                                      name='airlineFilter' className="form-control" value={item.airlineName} onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}

                        </form>
                      ) : (
                        <div className="p-1"><img src="assets/img/flight2.svg" width={40} />
                          Sorry,no airlines found</div>
                      )}
                    </div>
                    {/*Airline Price area ends*/}
                    {/*Stops area starts*/}
                    <div className="stops-area">
                      <div className="side-bar-title"><h4>Stops</h4></div>
                      <div id="stop-area-on">
                        <p className="no-margin small-text">Onward</p>
                        <input type="checkbox" id="non-stop-on" name="non-stop-on" defaultValue="0" checked={this.state.StopsCheckbox_Onward[0]} onChange={(e) => this.stopsChanged(e, 0)} />
                        <label htmlFor="non-stop-on">0<br />Non-stop</label>
                        <input type="checkbox" id="one-stop-on" name="one-stop-on" defaultValue="1" checked={this.state.StopsCheckbox_Onward[1]} onChange={(e) => this.stopsChanged(e, 1)} />
                        <label htmlFor="one-stop-on">1<br />stop</label>
                        <input type="checkbox" id="oneplus-stop-on" name="oneplus-stop-on" defaultValue="+1" checked={this.state.StopsCheckbox_Onward[2]} onChange={(e) => this.stopsChanged(e, 2)} />
                        <label htmlFor="oneplus-stop-on">1+<br />stop</label>
                      </div>

                      <div id="stop-area-re">
                        <p className="no-margin small-text">Return</p>
                        <input type="checkbox" id="non-stop-re" name="non-stop-re" defaultValue="0" checked={this.state.StopsCheckbox_Return[0]} onChange={(e) => this.stopsReturnChanged(e, 0)} />
                        <label htmlFor="non-stop-re">0<br />Non-stop</label>
                        <input type="checkbox" id="one-stop-re" name="one-stop-re" defaultValue="1" checked={this.state.StopsCheckbox_Return[1]} onChange={(e) => this.stopsReturnChanged(e, 1)} />
                        <label htmlFor="one-stop-re">1<br />stop</label>
                        <input type="checkbox" id="oneplus-stop-re" name="oneplus-stop-re" defaultValue="+1" checked={this.state.StopsCheckbox_Return[2]} onChange={(e) => this.stopsReturnChanged(e, 2)} />
                        <label htmlFor="oneplus-stop-re">1+<br />stop</label>
                      </div>


                    </div>
                    {/*Stops area ends */}
                    {/*Departure time area starts */}
                    <div className="departure-area">
                      <div className="side-bar-title"><h4>Departure Time</h4></div>
                      <div id="departure-time-on">
                        <p className="no-margin small-text">Onward</p>
                        <input type="checkbox" id="chk1" name="chkD" defaultValue="before6" checked={this.state.DepartureOnwardTimesCheckbox[0]} onChange={(e) => this.departreTime(e, 0)} />
                        <label htmlFor="chk1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk2" name="chkD" defaultValue="6to12" checked={this.state.DepartureOnwardTimesCheckbox[1]} onChange={(e) => this.departreTime(e, 1)} />
                        <label htmlFor="chk2"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk3" name="chkD" defaultValue="12to6" checked={this.state.DepartureOnwardTimesCheckbox[2]} onChange={(e) => this.departreTime(e, 2)} />
                        <label htmlFor="chk3"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk4" name="chkD" defaultValue="after6" checked={this.state.DepartureOnwardTimesCheckbox[3]} onChange={(e) => this.departreTime(e, 3)} />
                        <label htmlFor="chk4"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>
                      <div id="departure-time-re">
                        <p className="no-margin small-text">Return</p>
                        <input type="checkbox" id="chk5" name="chkD" defaultValue="before6" checked={this.state.DepartureReturnTimesCheckbox[0]} onChange={(e) => this.departureTimeReturn(e, 0)} />
                        <label htmlFor="chk5"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk6" name="chkD" defaultValue="6to12" checked={this.state.DepartureReturnTimesCheckbox[1]} onChange={(e) => this.departureTimeReturn(e, 1)} />
                        <label htmlFor="chk6"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk7" name="chkD" defaultValue="12to6" checked={this.state.DepartureReturnTimesCheckbox[2]} onChange={(e) => this.departureTimeReturn(e, 2)} />
                        <label htmlFor="chk7"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk8" name="chkD" defaultValue="after6" checked={this.state.DepartureReturnTimesCheckbox[3]} onChange={(e) => this.departureTimeReturn(e, 3)} />
                        <label htmlFor="chk8"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>


                    </div>
                    {/*Departure time area ends */}
                    {/*Arrival time area starts */}
                    <div className="arrival-area">
                      <div className="side-bar-title"><h4>Arrival Time</h4></div>
                      <div id="arrival-time-on">
                        <p className="no-margin small-text">Onward</p>
                        <input type="checkbox" id="chk01" name="chkR" defaultValue="before6" checked={this.state.ArrivalOnwardTimesCheckbox[0]} onChange={(e) => this.arrivalTime(e, 0)} />
                        <label htmlFor="chk01"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk02" name="chkR" defaultValue="6to12" checked={this.state.ArrivalOnwardTimesCheckbox[1]} onChange={(e) => this.arrivalTime(e, 1)} />
                        <label htmlFor="chk02"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk03" name="chkR" defaultValue="12to6" checked={this.state.ArrivalOnwardTimesCheckbox[2]} onChange={(e) => this.arrivalTime(e, 2)} />
                        <label htmlFor="chk03"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk04" name="chkR" defaultValue="after6" checked={this.state.ArrivalOnwardTimesCheckbox[3]} onChange={(e) => this.arrivalTime(e, 3)} />
                        <label htmlFor="chk04"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>

                      <div id="arrival-time-re">
                        <p className="no-margin small-text">Return</p>
                        <input type="checkbox" id="chk05" name="chkR" defaultValue="before6" checked={this.state.ArrivalReturnTimesCheckbox[0]} onChange={(e) => this.arrivalTimeReturn(e, 0)} />
                        <label htmlFor="chk05"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                          <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                        </svg><br />Before<br />6AM</label>
                        <input type="checkbox" id="chk06" name="chkR" defaultValue="6to12" checked={this.state.ArrivalReturnTimesCheckbox[1]} onChange={(e) => this.arrivalTimeReturn(e, 1)} />
                        <label htmlFor="chk06"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />6AM-<br />12PM</label>
                        <input type="checkbox" id="chk07" name="chkR" defaultValue="12to6" checked={this.state.ArrivalReturnTimesCheckbox[2]} onChange={(e) => this.arrivalTimeReturn(e, 2)} />
                        <label htmlFor="chk07"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg><br />12PM-<br />6PM</label>
                        <input type="checkbox" id="chk08" name="chkR" defaultValue="after6" checked={this.state.ArrivalReturnTimesCheckbox[3]} onChange={(e) => this.arrivalTimeReturn(e, 3)} />
                        <label htmlFor="chk08"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                        </svg><br />AFTER<br />6PM</label>
                      </div>
                    </div>
                    {/*Arrival time area ends */}

                    {/*Price range slider */}
                    {(this.state.RoundTripFullSearchResult.minimumFare != this.state.RoundTripFullSearchResult.maximumFare) && <div>
                      <div className="price-slider-area">
                        <div className="side-bar-title"><h4>Onward Price</h4></div>
                        <ReactSlider
                          min={Math.floor(this.state.RoundTripFullSearchResult.minimumFare)}
                          max={Math.round(this.state.RoundTripFullSearchResult.maximumFare)}
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          defaultValue={[parseInt(Math.round(this.state.RoundTripFullSearchResult.minimumFare)), parseInt(Math.round(this.state.RoundTripFullSearchResult.maximumFare))]}
                          value={[this.state.priceRangeMin, this.state.priceRangeMax]}
                          ariaLabel={['Lower thumb', 'Upper thumb']}
                          ariaValuetext={state => `Thumb value ${state.valueNow}`}
                          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                          pearling
                          onChange={(e) => this.priceRangeChange(e)}
                        />
                      </div>
                      <div className="price-slider-area">
                        <div className="side-bar-title"><h4>Return Price</h4></div>
                        <ReactSlider
                          min={Math.floor(this.state.RoundTripFullSearchResult.minimumFareR)}
                          max={Math.round(this.state.RoundTripFullSearchResult.maximumFareR)}
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          defaultValue={[parseInt(Math.round(this.state.RoundTripFullSearchResult.minimumFareR)), parseInt(Math.round(this.state.RoundTripFullSearchResult.maximumFareR))]}
                          value={[this.state.priceRangeRMin, this.state.priceRangeRMax]}
                          ariaLabel={['Lower thumb', 'Upper thumb']}
                          ariaValuetext={state => `Thumb value ${state.valueNow}`}
                          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                          pearling
                          onChange={(e) => this.priceRangeChangeR(e)}
                        />
                      </div>
                    </div>}

                  </div>
                </div>                
              </div>
               {/* Sidebar desktop ends */}

               {/* Sidebar Mobile starts */}
               <div className="side-bar no-sticky side-bar-mob">
                  <div className="accordion accordion-flush" id="accordionFilter">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Filters
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFilter"
                      >
                        <div className="accordion-body">

                          <div className="bg-white" id="sidebar-wrapper">
                            {/*Airline Price area starts*/}
                            <div className="airline-area">
                              <div className="d-flex flex-row reset-area">

                                <span className="reset-filter" onClick={this.resetFilter}>Reset filters</span>
                              </div>
                              <div className="side-bar-title"><h4>Airlines</h4></div>
                              {this.state.AvlAirlineResult && this.state.AvlAirlineResult.length > 0 ? (
                                <form className="airline" id="scrollbar1">
                                  {this.state.AvlAirlineResult.map((item, index) => (
                                    <div className='side-loop' key={index}>
                                      <label style={{ width: '100%', fontSize: '1.1em' }}>
                                        <div className="d-flex airline-price mb-3">
                                          <div className="flex-row">
                                            <img src={`assets/img/logos/${item.airlineCode}.gif`} width={30} />
                                          </div>
                                          <div className="flex-row pt-2 pl-5 me-auto">
                                            <h6>{item.airlineName}<span>({item.count})</span></h6>
                                          </div>
                                          <div className="flex-row pt-2 mr-10">
                                            <h6>₹ {Math.round(item.minAmount)}</h6>
                                          </div>
                                          <div className="air-check-box d-flex align-items-center">
                                            <input type="checkbox"
                                              checked={this.state.AirlineCheckbox[index]}
                                              name='airlineFilter' className="form-control" value={item.airlineName} onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  ))}

                                </form>
                              ) : (
                                <div className="p-1"><img src="assets/img/flight2.svg" width={40} />
                                  Sorry,no airlines found</div>
                              )}
                            </div>
                            {/*Airline Price area ends*/}
                            {/*Stops area starts*/}
                            <div className="stops-area">
                              <div className="side-bar-title"><h4>Stops</h4></div>
                              <div id="stop-area-on">
                                <p className="no-margin small-text">Onward</p>
                                <input type="checkbox" id="non-stop-on" name="non-stop-on" defaultValue="0" checked={this.state.StopsCheckbox_Onward[0]} onChange={(e) => this.stopsChanged(e, 0)} />
                                <label htmlFor="non-stop-on">0<br />Non-stop</label>
                                <input type="checkbox" id="one-stop-on" name="one-stop-on" defaultValue="1" checked={this.state.StopsCheckbox_Onward[1]} onChange={(e) => this.stopsChanged(e, 1)} />
                                <label htmlFor="one-stop-on">1<br />stop</label>
                                <input type="checkbox" id="oneplus-stop-on" name="oneplus-stop-on" defaultValue="+1" checked={this.state.StopsCheckbox_Onward[2]} onChange={(e) => this.stopsChanged(e, 2)} />
                                <label htmlFor="oneplus-stop-on">1+<br />stop</label>
                              </div>

                              <div id="stop-area-re">
                                <p className="no-margin small-text">Return</p>
                                <input type="checkbox" id="non-stop-re" name="non-stop-re" defaultValue="0" checked={this.state.StopsCheckbox_Return[0]} onChange={(e) => this.stopsReturnChanged(e, 0)} />
                                <label htmlFor="non-stop-re">0<br />Non-stop</label>
                                <input type="checkbox" id="one-stop-re" name="one-stop-re" defaultValue="1" checked={this.state.StopsCheckbox_Return[1]} onChange={(e) => this.stopsReturnChanged(e, 1)} />
                                <label htmlFor="one-stop-re">1<br />stop</label>
                                <input type="checkbox" id="oneplus-stop-re" name="oneplus-stop-re" defaultValue="+1" checked={this.state.StopsCheckbox_Return[2]} onChange={(e) => this.stopsReturnChanged(e, 2)} />
                                <label htmlFor="oneplus-stop-re">1+<br />stop</label>
                              </div>


                            </div>
                            {/*Stops area ends */}
                            {/*Departure time area starts */}
                            <div className="departure-area">
                              <div className="side-bar-title"><h4>Departure Time</h4></div>
                              <div id="departure-time-on">
                                <p className="no-margin small-text">Onward</p>
                                <input type="checkbox" id="chk1" name="chkD" defaultValue="before6" checked={this.state.DepartureOnwardTimesCheckbox[0]} onChange={(e) => this.departreTime(e, 0)} />
                                <label htmlFor="chk1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg><br />Before<br />6AM</label>
                                <input type="checkbox" id="chk2" name="chkD" defaultValue="6to12" checked={this.state.DepartureOnwardTimesCheckbox[1]} onChange={(e) => this.departreTime(e, 1)} />
                                <label htmlFor="chk2"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />6AM-<br />12PM</label>
                                <input type="checkbox" id="chk3" name="chkD" defaultValue="12to6" checked={this.state.DepartureOnwardTimesCheckbox[2]} onChange={(e) => this.departreTime(e, 2)} />
                                <label htmlFor="chk3"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />12PM-<br />6PM</label>
                                <input type="checkbox" id="chk4" name="chkD" defaultValue="after6" checked={this.state.DepartureOnwardTimesCheckbox[3]} onChange={(e) => this.departreTime(e, 3)} />
                                <label htmlFor="chk4"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg><br />AFTER<br />6PM</label>
                              </div>
                              <div id="departure-time-re">
                                <p className="no-margin small-text">Return</p>
                                <input type="checkbox" id="chk5" name="chkD" defaultValue="before6" checked={this.state.DepartureReturnTimesCheckbox[0]} onChange={(e) => this.departureTimeReturn(e, 0)} />
                                <label htmlFor="chk5"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg><br />Before<br />6AM</label>
                                <input type="checkbox" id="chk6" name="chkD" defaultValue="6to12" checked={this.state.DepartureReturnTimesCheckbox[1]} onChange={(e) => this.departureTimeReturn(e, 1)} />
                                <label htmlFor="chk6"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />6AM-<br />12PM</label>
                                <input type="checkbox" id="chk7" name="chkD" defaultValue="12to6" checked={this.state.DepartureReturnTimesCheckbox[2]} onChange={(e) => this.departureTimeReturn(e, 2)} />
                                <label htmlFor="chk7"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />12PM-<br />6PM</label>
                                <input type="checkbox" id="chk8" name="chkD" defaultValue="after6" checked={this.state.DepartureReturnTimesCheckbox[3]} onChange={(e) => this.departureTimeReturn(e, 3)} />
                                <label htmlFor="chk8"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg><br />AFTER<br />6PM</label>
                              </div>


                            </div>
                            {/*Departure time area ends */}
                            {/*Arrival time area starts */}
                            <div className="arrival-area">
                              <div className="side-bar-title"><h4>Arrival Time</h4></div>
                              <div id="arrival-time-on">
                                <p className="no-margin small-text">Onward</p>
                                <input type="checkbox" id="chk01" name="chkR" defaultValue="before6" checked={this.state.ArrivalOnwardTimesCheckbox[0]} onChange={(e) => this.arrivalTime(e, 0)} />
                                <label htmlFor="chk01"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg><br />Before<br />6AM</label>
                                <input type="checkbox" id="chk02" name="chkR" defaultValue="6to12" checked={this.state.ArrivalOnwardTimesCheckbox[1]} onChange={(e) => this.arrivalTime(e, 1)} />
                                <label htmlFor="chk02"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />6AM-<br />12PM</label>
                                <input type="checkbox" id="chk03" name="chkR" defaultValue="12to6" checked={this.state.ArrivalOnwardTimesCheckbox[2]} onChange={(e) => this.arrivalTime(e, 2)} />
                                <label htmlFor="chk03"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />12PM-<br />6PM</label>
                                <input type="checkbox" id="chk04" name="chkR" defaultValue="after6" checked={this.state.ArrivalOnwardTimesCheckbox[3]} onChange={(e) => this.arrivalTime(e, 3)} />
                                <label htmlFor="chk04"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg><br />AFTER<br />6PM</label>
                              </div>

                              <div id="arrival-time-re">
                                <p className="no-margin small-text">Return</p>
                                <input type="checkbox" id="chk05" name="chkR" defaultValue="before6" checked={this.state.ArrivalReturnTimesCheckbox[0]} onChange={(e) => this.arrivalTimeReturn(e, 0)} />
                                <label htmlFor="chk05"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                  <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                </svg><br />Before<br />6AM</label>
                                <input type="checkbox" id="chk06" name="chkR" defaultValue="6to12" checked={this.state.ArrivalReturnTimesCheckbox[1]} onChange={(e) => this.arrivalTimeReturn(e, 1)} />
                                <label htmlFor="chk06"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />6AM-<br />12PM</label>
                                <input type="checkbox" id="chk07" name="chkR" defaultValue="12to6" checked={this.state.ArrivalReturnTimesCheckbox[2]} onChange={(e) => this.arrivalTimeReturn(e, 2)} />
                                <label htmlFor="chk07"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                </svg><br />12PM-<br />6PM</label>
                                <input type="checkbox" id="chk08" name="chkR" defaultValue="after6" checked={this.state.ArrivalReturnTimesCheckbox[3]} onChange={(e) => this.arrivalTimeReturn(e, 3)} />
                                <label htmlFor="chk08"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                </svg><br />AFTER<br />6PM</label>
                              </div>
                            </div>
                            {/*Arrival time area ends */}

                            {/*Price range slider */}
                            {(this.state.RoundTripFullSearchResult.minimumFare != this.state.RoundTripFullSearchResult.maximumFare) && <div>
                              <div className="price-slider-area">
                                <div className="side-bar-title"><h4>Onward Price</h4></div>
                                <ReactSlider
                                  min={Math.round(this.state.RoundTripFullSearchResult.minimumFare)}
                                  max={Math.round(this.state.RoundTripFullSearchResult.maximumFare)}
                                  className="horizontal-slider"
                                  thumbClassName="example-thumb"
                                  trackClassName="example-track"
                                  defaultValue={[parseInt(Math.round(this.state.RoundTripFullSearchResult.minimumFare)), parseInt(Math.round(this.state.RoundTripFullSearchResult.maximumFare))]}
                                  value={[this.state.priceRangeMin, this.state.priceRangeMax]}
                                  ariaLabel={['Lower thumb', 'Upper thumb']}
                                  ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                  pearling
                                  onChange={(e) => this.priceRangeChange(e)}
                                />
                              </div>
                              <div className="price-slider-area">
                                <div className="side-bar-title"><h4>Return Price</h4></div>
                                <ReactSlider
                                  min={Math.round(this.state.RoundTripFullSearchResult.minimumFareR)}
                                  max={Math.round(this.state.RoundTripFullSearchResult.maximumFareR)}
                                  className="horizontal-slider"
                                  thumbClassName="example-thumb"
                                  trackClassName="example-track"
                                  defaultValue={[parseInt(Math.round(this.state.RoundTripFullSearchResult.minimumFareR)), parseInt(Math.round(this.state.RoundTripFullSearchResult.maximumFareR))]}
                                  value={[this.state.priceRangeRMin, this.state.priceRangeRMax]}
                                  ariaLabel={['Lower thumb', 'Upper thumb']}
                                  ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                  pearling
                                  onChange={(e) => this.priceRangeChangeR(e)}
                                />
                              </div>
                            </div>}

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {/* Sidebar Mobile ends */}
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="top-sort-area position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-row">
                    <div id="top-sort-form">
                      <input type="radio" id="cheapest" name="sortOption" defaultValue="cheapest" checked={this.state.isCheapestActive} onChange={(e) => this.sortArray(e)} />
                      <label htmlFor="cheapest"><span className="selection"><img src="assets/img/cheapest.svg" width={25} /></span> CHEAPEST</label>
                      <input type="radio" id="fastest" name="sortOption" defaultValue="fastest" checked={this.state.isFastestActive} onChange={(e) => this.sortArray(e)} />
                      <label htmlFor="fastest"><span className="selection"><img src="assets/img/fastest.svg" width={25} /></span> FASTEST</label>
                      <input type="radio" id="earliest" name="sortOption" defaultValue="earliest" checked={this.state.isEarlierActive} onChange={(e) => this.sortArray(e)} />
                      <label htmlFor="earliest"><span className="selection"><img src="assets/img/earliest.svg" width={25} /></span> EARLIEST</label>

                    </div>
                  </div>
                </div>
                <OverlayTrigger placement="top" overlay={shareTooltip} className="custom-tooltip">
                  <button onClick={this.handleShareButtonClick} className="it_share_btn">
                    {showCheckboxes && showCheckboxesR ? 'X' : (
                      <svg
                        fill="#ffffff"
                        width="15px"
                        height="15px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#ffffff"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                          <path d="M8.137,11.631a.908.908,0,0,1-.17.038A3.016,3.016,0,0,1,8,12a2.962,2.962,0,0,1-.033.328.949.949,0,0,1,.173.042L16.9,16.485a.991.991,0,0,1,.242.18A2.969,2.969,0,0,1,19,16a3.037,3.037,0,1,1-2.911,2.306c-.014-.006-.029,0-.044-.01L7.289,14.181a.953.953,0,0,1-.134-.1,3,3,0,1,1,0-4.162,1,1,0,0,1,.133-.1L16.045,5.7c.014-.007.029,0,.044-.011A2.93,2.93,0,0,1,16,5a3,3,0,1,1,3,3,2.969,2.969,0,0,1-1.862-.665,1.03,1.03,0,0,1-.242.18Z" />
                        </g>
                      </svg>
                    )}
                  </button>
                </OverlayTrigger>
                {showCheckboxes && showCheckboxesR && (
                  <div className="share-show">
                    <>

                      <OverlayTrigger placement="top" overlay={whatsappTooltip}>
                        <img src="assets/img/whatsapp.png" width={30} onClick={this.shareOnWhatsApp} />
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={emailTooltip}>
                        <img src="assets/img/mail.png" width={30} onClick={this.handleEmailShow} />
                      </OverlayTrigger>
                    </>
                  </div>
                )}
              </div>


              {/*Two way result*/}

              {/* Individual round trip desktop starts */}
              <div className="oneway-desk">
                <div className="row">
                  {/*round trip desktop left area starts*/}
                  {this.state.ItinSearchResult && this.state.ItinSearchResult.length > 0 ? (
                    <div className="col-lg-6 col-md-6">
                      <div className="next-prev-block d-flex justify-content-between">

                        <div>
                          <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripFullSearchResult.departureDate, 'O')}>Onward Prev Day</button>
                        </div>
                        <div>
                          <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripFullSearchResult.departureDate, 'O')}>Onward Next Day</button>
                        </div>

                      </div>
                      <form name='ItinResult' onChange={(e) => { this.formChange(e) }}>

                        {/*round route1 area starts*/}
                        <div className="round-route-info">
                          <div className="row">
                            <div className="col-md-6 col-lg-6"><p className="no-margin small-text">{this.state.RoundTripFullSearchResult.origin} - {this.state.RoundTripFullSearchResult.destination}</p></div>
                            <div className="col-md-6 col-lg-6 text-right"><p className="no-margin small-text"> {dateFormat(this.state.RoundTripFullSearchResult.departureDate, "dd-mm-yyyy")}</p></div>
                          </div>
                        </div>
                        {/*round route1 area starts*/}
                        {/*trip area starts*/}
                        {this.state.ItinSearchResult.map((item, index) => {

                          let arrivalTime = "";


                          if ((parseInt(item.arrivalTime.split(":")[0]) < 6)) {
                            arrivalTime = "before6";
                          }
                          else if (((parseInt(item.arrivalTime.split(":")[0]) >= 6 && parseInt(item.arrivalTime.split(":")[0]) < 12))) {
                            arrivalTime = "6to12";
                          }
                          else if (((parseInt(item.arrivalTime.split(":")[0]) >= 12 && parseInt(item.arrivalTime.split(":")[0]) < 18))) {
                            arrivalTime = "12to6";
                          }
                          else if (((parseInt(item.arrivalTime.split(":")[0]) >= 18))) {
                            arrivalTime = "after6";
                          }



                          //Arrival Time...
                          let departureTime = "";


                          if ((parseInt(item.departureTime.split(":")[0]) < 6)) {
                            departureTime = "before6";
                          }
                          else if (((parseInt(item.departureTime.split(":")[0]) >= 6 && parseInt(item.departureTime.split(":")[0]) < 12))) {
                            departureTime = "6to12";
                          }
                          else if (((parseInt(item.departureTime.split(":")[0]) >= 12 && parseInt(item.departureTime.split(":")[0]) < 18))) {
                            departureTime = "12to6";
                          }
                          else if (((parseInt(item.departureTime.split(":")[0]) >= 18))) {
                            departureTime = "after6";
                          }


                          return <div key={index}>



                            {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.airlineName))) && this.state.priceRangeMin <= item.netAmount && this.state.priceRangeMax >= item.netAmount && (this.state.Stops.length == 0 || (this.state.Stops.length > 0 && this.state.Stops.includes(item.noofStop.toString()))) && (this.state.DepartureTimes.length == 0 || (this.state.DepartureTimes.length > 0 && this.state.DepartureTimes.includes(departureTime))) && (this.state.ArrivalTimes.length == 0 || (this.state.ArrivalTimes.length > 0 && this.state.ArrivalTimes.includes(arrivalTime))) &&

                              <div className="col-lg-12 col-md-12 col-12">
                                <div className="deal-wrapper">
                                  <div className="flight-route-display">
                                    {showCheckboxes && (
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.some(selectedItem => selectedItem.index === index && selectedItem.type === "O")}
                                        onChange={(e) => this.handleCheckboxChange(item, index, "O", e)} className="share-check"
                                      />
                                    )}
                                    <label className="round-selection">
                                      <input name="plan" className="radio" type="radio" onChange={(e) => this.OnwardEvent(item)} />
                                      <div className="row align-items-center">
                                        <div className="airline-company col-lg-3 col-md-3">
                                          <img src={`assets/img/logos/${item.airlineCode}.gif`} />
                                          <p className="small-text">{item.airlineName}<br />{item.flightDetails}</p>
                                        </div>
                                        <div className="route-details-area col-lg-3 col-md-3 text-right">
                                          <div className="row">
                                            <p className="small-text">{item.source}</p>
                                            <h6 className="time-text">{item.departureTime}</h6>
                                            <p className="small-text text-grey">{item.departureDate}<br />{item.sourceAirport}</p>
                                          </div>
                                        </div>
                                        <div className="travel-time col-lg-3 col-md-3 text-center">
                                          <p className="small-text text-grey">{item.duration}</p>
                                          <span><img src="assets/img/arrow.png" width={80} /></span>
                                          <p className="small-text text-grey">{item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}</p>
                                        </div>
                                        <div className="route-details-area col-lg-3 col-md-3 text-left">
                                          <div className="row">
                                            <p className="small-text">{item.destination}</p>
                                            <h6 className="time-text">{item.arrivalTime}</h6>
                                            <p className="small-text text-grey">{item.arrivalDate}<br />{item.destinationAirport}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flight-details-area d-flex flex-row-reverse pt-2 align-content-center align-items-center">


                                        <div className="flex-row mr-10"><h4 className="price-text">₹{Math.round(item.netAmount)}</h4></div>
                                        {item.pricingList && (
                                          <div className="flex-row mr-10"><span className="mr-10 text-grey small-text">
                                            {item.pricingList.length > 1 ? (
                                              <a className="more-fare-btn indi-more-fare" data-bs-toggle="collapse" data-bs-target={'#collapsesMore' + `${index}`} aria-controls="collapseMore">More Fares</a>
                                            ) : <div></div>}



                                          </span></div>
                                        )}
                                        <div className="flex-row">
                                          {item.freeBaggage !== "" && (
                                            <p>
                                              <strong>
                                                {" "}
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={16}
                                                  height={16}
                                                  fill="currentColor"
                                                  className="bi bi-briefcase"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                                </svg>{" "}
                                                {item.freeBaggage}
                                              </strong>
                                            </p>
                                          )}
                                        </div>
                                        <div className="flex-row mr-10">
                                          <strong>{item.fareName}</strong>
                                        </div>
                                      </div>
                                    </label>

                                    {/* Invidual more fare starts */}
                                    <div className="collapse" id={'collapsesMore' + `${index}`}>
                                      <div className="card card-body" style={{ marginTop: '10px' }}>
                                        <OwlCarousel
                                          items={2}
                                          className="owl-carousel more-ind"
                                          margin={10}
                                          nav={true}
                                          dots={false}
                                          stagePadding={25}
                                          responsive={{
                                            0: {
                                              items: 2,
                                              stagePadding: 10,
                                            },
                                            600: {
                                              items: 3,
                                              stagePadding: 10,
                                            },
                                            1000: {
                                              items: 2,
                                              stagePadding: item.pricingList.length > 2 ? 25 : 0,
                                            },
                                          }}
                                        >
                                          {item.pricingList.map((item1, index1) => (
                                            <div className="item" key={index1}>
                                              <div className="card">
                                                <label className="fare-selection">
                                                  <input
                                                    name={`plan` + `${index}`}
                                                    className="single-fare"
                                                    id={`single1` + `${index1}`}
                                                    type="radio"
                                                    defaultChecked={index1 == 0}
                                                    //onChange={() => { let data = this.state.ItinSearchResult; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResult: data }) }}
                                                    onChange={() => this.handleItineraryChange(index, item1)}
                                                  />
                                                  <div className="card-header text-center" style={{ backgroundColor: '#adcdf5', fontWeight: '700' }}>
                                                    <div>{item1.fareName}</div>
                                                  </div>
                                                  <div className="card-body" style={{ padding: '2px' }}>
                                                    <h4 className="no-margin text-center">{Math.round(item1.netAmount)}</h4>
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="briefcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 512 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
                                                              ></path>
                                                            </svg>
                                                            Hand Bag
                                                          </td>
                                                          <td className="small-text">{item1.cabinBaggage}</td>
                                                        </tr>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="cart-flatbed-suitcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 640 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M0 32C0 14.3 14.3 0 32 0H48c44.2 0 80 35.8 80 80V368c0 8.8 7.2 16 16 16H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H541.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H253.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H144c-44.2 0-80-35.8-80-80V80c0-8.8-7.2-16-16-16H32C14.3 64 0 49.7 0 32zM432 96V56c0-4.4-3.6-8-8-8H344c-4.4 0-8 3.6-8 8V96h96zM288 96V56c0-30.9 25.1-56 56-56h80c30.9 0 56 25.1 56 56V96 320H288V96zM512 320V96h16c26.5 0 48 21.5 48 48V272c0 26.5-21.5 48-48 48H512zM240 96h16V320H240c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"
                                                              ></path>
                                                            </svg>
                                                            Check-in
                                                          </td>
                                                          <td className="small-text">{item1.checkinBaggage}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </label>
                                              </div>



                                            </div>
                                          ))}








                                        </OwlCarousel>
                                      </div>
                                    </div>

                                    {/* Invidual more fare ends */}


                                  </div>

                                </div>

                              </div>




                            }





                            {/* No AIrline Selected...
                      
                         //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                          </div>
                        }


                        )}
                        {/*trip area ends*/}


                      </form>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6">
                      no data
                    </div>
                  )}
                  {/*round trip desktop left area ends*/}




                  {/*round trip desktop right area starts*/}
                  {this.state.ItinSearchResultR && this.state.ItinSearchResultR.length > 0 ? (
                    <div className="col-lg-6 col-md-6">
                      <div className="next-prev-block d-flex justify-content-between">
                        <div>
                          <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripFullSearchResult.returnDate, 'R')}>Return Prev Day</button>
                        </div>

                        <div>
                          <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripFullSearchResult.returnDate, 'R')}>Return Next Day</button>
                        </div>
                      </div>
                      <form name='itinResultR' onChange={(e) => { this.formChange(e) }}>

                        {/*round route1 area starts*/}
                        <div className="round-route-info">
                          <div className="row">
                            <div className="col-md-6 col-lg-6"><p className="no-margin small-text">{this.state.RoundTripFullSearchResult.originR} - {this.state.RoundTripFullSearchResult.destinationR}</p></div>
                            <div className="col-md-6 col-lg-6 text-right"><p className="no-margin small-text"> {dateFormat(this.state.RoundTripFullSearchResult.returnDate, "dd-mm-yyyy")}</p></div>
                          </div>
                        </div>
                        {/*round route1 area starts*/}
                        {/*trip area starts*/}
                        {this.state.ItinSearchResultR.map((item, index) => {

                          let arrivalTime = "";

                          if (parseInt(item.arrivalTime.split(":")[0]) < 6) {
                            arrivalTime = "before6";
                          }
                          else if (parseInt(item.arrivalTime.split(":")[0]) >= 6 && parseInt(item.arrivalTime.split(":")[0]) < 12) {
                            arrivalTime = "6to12";
                          }
                          else if (parseInt(item.arrivalTime.split(":")[0]) >= 12 && parseInt(item.arrivalTime.split(":")[0]) < 18) {
                            arrivalTime = "12to6";
                          }
                          else if (parseInt(item.arrivalTime.split(":")[0]) >= 18) {
                            arrivalTime = "after6";
                          }



                          //Arrival Time...
                          let departureTime = "";


                          if (parseInt(item.departureTime.split(":")[0]) < 6) {
                            departureTime = "before6";
                          }
                          else if (parseInt(item.departureTime.split(":")[0]) >= 6 && parseInt(item.departureTime.split(":")[0]) < 12) {
                            departureTime = "6to12";
                          }
                          else if (parseInt(item.departureTime.split(":")[0]) >= 12 && parseInt(item.departureTime.split(":")[0]) < 18) {
                            departureTime = "12to6";
                          }
                          else if (parseInt(item.departureTime.split(":")[0]) >= 18) {
                            departureTime = "after6";
                          }


                          return <div key={index}>



                            {/* Stops >0 */}
                            {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.airlineName))) && this.state.priceRangeRMin <= item.netAmount && this.state.priceRangeRMax >= item.netAmount && (this.state.StopsReturn.length == 0 || (this.state.StopsReturn.length > 0 && this.state.StopsReturn.includes(item.noofStop.toString()))) && (this.state.DepartureTimesReturn.length == 0 || (this.state.DepartureTimesReturn.length > 0 && this.state.DepartureTimesReturn.includes(departureTime))) && (this.state.ArrivalTimesReturn.length == 0 || (this.state.ArrivalTimesReturn.length > 0 && this.state.ArrivalTimesReturn.includes(arrivalTime))) &&

                              <div className="col-lg-12 col-md-12 col-12">
                                <div className="deal-wrapper">
                                  <div className="flight-route-display">
                                    {showCheckboxesR && (
                                      <input
                                        type="checkbox"
                                        checked={selectedItemsR.some(selectedItemR => selectedItemR.index === index && selectedItemR.type === "R")}
                                        onChange={(e) => this.handleCheckboxChangeR(item, index, "R", e)} className="share-check"
                                      />
                                    )}
                                    <label className="round-selection">
                                      <input name="plan" className="radio" type="radio" onChange={(e) => this.ReturnEvent(item)} />
                                      <div className="row align-items-center">
                                        <div className="airline-company col-lg-3 col-md-3">
                                          <img src={`assets/img/logos/${item.airlineCode}.gif`} />
                                          <p className="small-text">{item.airlineName}<br />{item.flightDetails}</p>
                                        </div>
                                        <div className="route-details-area col-lg-3 col-md-3 text-right">
                                          <div className="row">
                                            <p className="small-text">{item.source}</p>
                                            <h6 className="time-text">{item.departureTime}</h6>
                                            <p className="small-text text-grey">{item.departureDate}
                                              <br />{item.sourceAirport}
                                              {item.depTerminal && item.depTerminal.length > 0 && (
                                                <>
                                                  Terminal:{item.depTerminal}
                                                  <br />
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="travel-time col-lg-3 col-md-3 text-center">
                                          <p className="small-text text-grey">{item.duration}</p>
                                          <span><img src="assets/img/arrow.png" width={80} /></span>
                                          <p className="small-text text-grey">{item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}</p>
                                        </div>
                                        <div className="route-details-area col-lg-3 col-md-3 text-left">
                                          <div className="row">
                                            <p className="small-text">{item.destination}</p>
                                            <h6 className="time-text">{item.arrivalTime}</h6>
                                            <p className="small-text text-grey">{item.arrivalDate}
                                              <br />{item.destinationAirport}
                                              {item.arrTerminal && item.arrTerminal.length > 0 && (
                                                <>
                                                  Terminal:{item.arrTerminal}
                                                  <br />
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flight-details-area d-flex flex-row-reverse pt-2 align-content-center align-items-center">

                                        <div className="flex-row mr-10"><h4 className="price-text">₹{Math.round(item.netAmount)}</h4>

                                        </div>
                                        {item.pricingList && (
                                          <div className="flex-row mr-10"><span className="mr-10 text-grey small-text">
                                            {item.pricingList.length > 1 ? (
                                              <a className="more-fare-btn indi-more-fare" data-bs-toggle="collapse" data-bs-target={'#collapsesMoreRight' + `${index}`} aria-controls="collapseMoreRight">More Fares</a>
                                            ) : <div></div>}
                                          </span></div>
                                        )}
                                        <div className="flex-row">
                                          {item.freeBaggage !== "" && (
                                            <p>
                                              {" "}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-briefcase"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                              </svg>{" "}
                                              {item.freeBaggage}
                                            </p>
                                          )}
                                        </div>
                                        <div className="d-flex flex-row mr-10">
                                          <strong>{item.fareName}</strong>
                                        </div>
                                      </div>

                                    </label>
                                    {/* Invidual more fare starts */}
                                    <div className="collapse" id={'collapsesMoreRight' + `${index}`}>
                                      <div className="card card-body" style={{ marginTop: '10px' }}>
                                        <OwlCarousel
                                          items={2}
                                          className="owl-carousel more-ind"


                                          margin={5}
                                          nav={true}
                                          dots={false}
                                          responsive={{
                                            0: {
                                              items: 2,
                                            },
                                            600: {
                                              items: 3,
                                            },
                                            1000: {
                                              items: 2,
                                              stagePadding: item.pricingList.length > 2 ? 25 : 0,
                                            },
                                          }}
                                        >
                                          {item.pricingList.map((item1, index1) => (
                                            <div className="item" key={index1}>
                                              <div className="card">
                                                <label className="fare-selection">
                                                  <input
                                                    name={`plan` + `${index}`}
                                                    className="single-fare"
                                                    id={`single1` + `${index1}`}
                                                    type="radio"
                                                    defaultChecked={index1 == 0}
                                                    //onChange={() => { let data = this.state.ItinSearchResultR; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResultR: data }) }}
                                                    onChange={() => this.handleItineraryChangeRT(index, item1)}
                                                  />
                                                  <div className="card-header text-center" style={{ backgroundColor: '#adcdf5', fontWeight: '700' }}>
                                                    <div>{item1.fareName}</div>
                                                  </div>
                                                  <div className="card-body" style={{ padding: '2px' }}>
                                                    <h4 className="no-margin text-center">{Math.round(item1.netAmount)}</h4>
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="briefcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 512 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
                                                              ></path>
                                                            </svg>
                                                            Hand Bag
                                                          </td>
                                                          <td className="small-text">{item1.cabinBaggage}</td>
                                                        </tr>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="cart-flatbed-suitcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 640 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M0 32C0 14.3 14.3 0 32 0H48c44.2 0 80 35.8 80 80V368c0 8.8 7.2 16 16 16H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H541.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H253.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H144c-44.2 0-80-35.8-80-80V80c0-8.8-7.2-16-16-16H32C14.3 64 0 49.7 0 32zM432 96V56c0-4.4-3.6-8-8-8H344c-4.4 0-8 3.6-8 8V96h96zM288 96V56c0-30.9 25.1-56 56-56h80c30.9 0 56 25.1 56 56V96 320H288V96zM512 320V96h16c26.5 0 48 21.5 48 48V272c0 26.5-21.5 48-48 48H512zM240 96h16V320H240c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"
                                                              ></path>
                                                            </svg>
                                                            Check-in
                                                          </td>
                                                          <td className="small-text">{item1.checkinBaggage}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </label>
                                              </div>



                                            </div>
                                          ))}







                                        </OwlCarousel>
                                      </div>
                                    </div>

                                    {/* Invidual more fare ends */}
                                  </div>
                                </div>
                              </div>




                            }





                            {/* No AIrline Selected...
    
       //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                          </div>
                        }


                        )}
                        {/*trip area ends*/}

                      </form>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6">
                      no data
                    </div>
                  )}
                  {/*round trip desktop right area ends*/}
                </div>
              </div>
              {/* Individual round trip desktop ends */}

              {/* Individual round trip Mobile view starts */}
              <div className="oneway-mob">

              <div className="next-prev-block d-flex justify-content-between">

                <div>
                  <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripFullSearchResult.departureDate, 'O')}>Onward Prev Day</button>
                </div>
                <div>
                  <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripFullSearchResult.departureDate, 'O')}>Onward Next Day</button>
                </div>
                <div>
                  <button className="btn btn-default" onClick={() => this.handlePrevFilter(this.state.RoundTripFullSearchResult.returnDate, 'R')}>Return Prev Day</button>
                </div>

                <div>
                  <button className="btn btn-default" onClick={() => this.handleNextFilter(this.state.RoundTripFullSearchResult.returnDate, 'R')}>Return Next Day</button>
                </div>

              </div>

                <Tabs
                  activeKey={this.state.activeTab}
                  id="individual-tab"
                  className="mb-3 mob-sector-tab sticky-top"
                  fill
                  onSelect={(key) => this.setState({ activeTab: key })}
                >
                  <Tab eventKey="home" title={`${this.state.RoundTripFullSearchResult.origin} - ${this.state.RoundTripFullSearchResult.destination}`}>
                    <form name='ItinResult' onChange={(e) => { this.formChange(e) }}>


                      {/*trip area starts*/}
                      {this.state.ItinSearchResult.map((item, index) => {

                        let arrivalTime = "";


                        if ((parseInt(item.arrivalTime.split(":")[0]) < 6)) {
                          arrivalTime = "before6";
                        }
                        else if (((parseInt(item.arrivalTime.split(":")[0]) >= 6 && parseInt(item.arrivalTime.split(":")[0]) < 12))) {
                          arrivalTime = "6to12";
                        }
                        else if (((parseInt(item.arrivalTime.split(":")[0]) >= 12 && parseInt(item.arrivalTime.split(":")[0]) < 18))) {
                          arrivalTime = "12to6";
                        }
                        else if (((parseInt(item.arrivalTime.split(":")[0]) >= 18))) {
                          arrivalTime = "after6";
                        }



                        //Arrival Time...
                        let departureTime = "";


                        if ((parseInt(item.departureTime.split(":")[0]) < 6)) {
                          departureTime = "before6";
                        }
                        else if (((parseInt(item.departureTime.split(":")[0]) >= 6 && parseInt(item.departureTime.split(":")[0]) < 12))) {
                          departureTime = "6to12";
                        }
                        else if (((parseInt(item.departureTime.split(":")[0]) >= 12 && parseInt(item.departureTime.split(":")[0]) < 18))) {
                          departureTime = "12to6";
                        }
                        else if (((parseInt(item.departureTime.split(":")[0]) >= 18))) {
                          departureTime = "after6";
                        }


                        return <div key={index}>



                          {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.airlineName))) && this.state.priceRangeMin <= item.netAmount && this.state.priceRangeMax >= item.netAmount && (this.state.Stops.length == 0 || (this.state.Stops.length > 0 && this.state.Stops.includes(item.noofStop.toString()))) && (this.state.DepartureTimes.length == 0 || (this.state.DepartureTimes.length > 0 && this.state.DepartureTimes.includes(departureTime))) && (this.state.ArrivalTimes.length == 0 || (this.state.ArrivalTimes.length > 0 && this.state.ArrivalTimes.includes(arrivalTime))) &&

                            // onward starts
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="deal-wrapper">

                                <div className="flight-route-display oneway-mob">
                                {showCheckboxes && (
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.some(selectedItem => selectedItem.index === index && selectedItem.type === "O")}
                                        onChange={(e) => this.handleCheckboxChange(item, index, "O", e)} className="share-check"
                                      />
                                    )}
                                  <label className="round-selection">
                                    <input name="plan" className="radio" type="radio" onChange={(e) => this.OnwardEvent(item)} />

                                    <ul className="d-flex flex-row">
                                      <li> <img
                                        src={`assets/img/logos/${item.airlineCode}.gif`}
                                        alt="." className="air-logo-mob"
                                      /></li>
                                      <li>{item.airlineName}</li>
                                      <li>{item.flightDetails}</li>

                                    </ul>



                                    <div className="row">

                                      <div className="route-details-area col-lg-4 col-md-4 col-4">
                                        <div className="row">
                                          <p className="small-text">{item.source}</p>
                                          <h6 className="time-text">{item.departureTime}</h6>
                                          <p className="small-text text-grey">{item.departureDate}<br />{item.sourceAirport}</p>
                                        </div>
                                      </div>
                                      <div className="travel-time col-lg-4 col-md-4 text-center col-4">
                                        <p className="small-text text-grey">{item.duration}</p>
                                        <span><img src="assets/img/arrow.png" width={80} /></span>
                                        <p className="small-text text-grey">{item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}</p>
                                      </div>
                                      <div className="route-details-area col-lg-4 col-md-4 col-4">
                                        <div className="row">
                                          <p className="small-text">{item.destination}</p>
                                          <h6 className="time-text">{item.arrivalTime}</h6>
                                          <p className="small-text text-grey">{item.arrivalDate}<br />{item.destinationAirport}</p>
                                        </div>
                                      </div>

                                    </div>
                                    <div className="flight-details-area d-flex flex-row-reverse pt-2 align-content-center align-items-center">
                                      <div className="flex-row mr-10"><h4 className="price-text">₹{Math.round(item.netAmount)}</h4></div>
                                      {item.pricingList && (
                                          <div className="flex-row mr-10"><span className="mr-10 text-grey small-text">
                                            {item.pricingList.length > 1 ? (
                                              <a className="more-fare-btn indi-more-fare" data-bs-toggle="collapse" data-bs-target={'#collapsesMore' + `${index}`} aria-controls="collapseMore">More Fares</a>
                                            ) : <div></div>}



                                          </span></div>
                                        )}
                                      <div className="flex-row"><span className="mr-10 text-grey small-text">
                                      </span></div>
                                      <div className="flex-row">
                                        {item.freeBaggage !== "" && (
                                          <p>
                                            {" "}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              className="bi bi-briefcase"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                            </svg>{" "}
                                            {item.freeBaggage}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </label>

                                                                         {/* Invidual more fare starts */}
                                                                         <div className="collapse" id={'collapsesMore' + `${index}`}>
                                      <div className="card card-body" style={{ marginTop: '10px' }}>
                                        <OwlCarousel
                                          items={2}
                                          className="owl-carousel more-ind"
                                          margin={10}
                                          nav={true}
                                          dots={false}
                                          stagePadding={25}
                                          responsive={{
                                            0: {
                                              items: 2,
                                              stagePadding: 10,
                                            },
                                            600: {
                                              items: 3,
                                              stagePadding: 10,
                                            },
                                            1000: {
                                              items: 2,
                                              stagePadding: item.pricingList.length > 2 ? 25 : 0,
                                            },
                                          }}
                                        >
                                          {item.pricingList.map((item1, index1) => (
                                            <div className="item" key={index1}>
                                              <div className="card">
                                                <label className="fare-selection">
                                                  <input
                                                    name={`plan` + `${index}`}
                                                    className="single-fare"
                                                    id={`single1` + `${index1}`}
                                                    type="radio"
                                                    defaultChecked={index1 == 0}
                                                    //onChange={() => { let data = this.state.ItinSearchResult; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResult: data }) }}
                                                    onChange={() => this.handleItineraryChange(index, item1)}
                                                  />
                                                  <div className="card-header text-center" style={{ backgroundColor: '#adcdf5', fontWeight: '700' }}>
                                                    <div>{item1.fareName}</div>
                                                  </div>
                                                  <div className="card-body" style={{ padding: '2px' }}>
                                                    <h4 className="no-margin text-center">{Math.round(item1.netAmount)}</h4>
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="briefcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 512 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
                                                              ></path>
                                                            </svg>
                                                            Hand Bag
                                                          </td>
                                                          <td className="small-text">{item1.cabinBaggage}</td>
                                                        </tr>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="cart-flatbed-suitcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 640 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M0 32C0 14.3 14.3 0 32 0H48c44.2 0 80 35.8 80 80V368c0 8.8 7.2 16 16 16H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H541.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H253.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H144c-44.2 0-80-35.8-80-80V80c0-8.8-7.2-16-16-16H32C14.3 64 0 49.7 0 32zM432 96V56c0-4.4-3.6-8-8-8H344c-4.4 0-8 3.6-8 8V96h96zM288 96V56c0-30.9 25.1-56 56-56h80c30.9 0 56 25.1 56 56V96 320H288V96zM512 320V96h16c26.5 0 48 21.5 48 48V272c0 26.5-21.5 48-48 48H512zM240 96h16V320H240c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"
                                                              ></path>
                                                            </svg>
                                                            Check-in
                                                          </td>
                                                          <td className="small-text">{item1.checkinBaggage}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </label>
                                              </div>



                                            </div>
                                          ))}








                                        </OwlCarousel>
                                      </div>
                                    </div>

                                    {/* Invidual more fare ends */}

                                </div>
                              </div>
                            </div>

                            // onward ends




                          }





                          {/* No AIrline Selected...
                     
                        //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                        </div>
                      }


                      )}
                      {/*trip area ends*/}


                    </form>
                  </Tab>
                  <Tab eventKey="profile" title={`${this.state.RoundTripFullSearchResult.originR} - ${this.state.RoundTripFullSearchResult.destinationR}`}>
                    <form name='itinResultR' onChange={(e) => { this.formChange(e) }}>


                      {/*trip area starts*/}
                      {this.state.ItinSearchResultR.map((item, index) => {

                        let arrivalTime = "";

                        if (parseInt(item.arrivalTime.split(":")[0]) < 6) {
                          arrivalTime = "before6";
                        }
                        else if (parseInt(item.arrivalTime.split(":")[0]) >= 6 && parseInt(item.arrivalTime.split(":")[0]) < 12) {
                          arrivalTime = "6to12";
                        }
                        else if (parseInt(item.arrivalTime.split(":")[0]) >= 12 && parseInt(item.arrivalTime.split(":")[0]) < 18) {
                          arrivalTime = "12to6";
                        }
                        else if (parseInt(item.arrivalTime.split(":")[0]) >= 18) {
                          arrivalTime = "after6";
                        }



                        //Arrival Time...
                        let departureTime = "";


                        if (parseInt(item.departureTime.split(":")[0]) < 6) {
                          departureTime = "before6";
                        }
                        else if (parseInt(item.departureTime.split(":")[0]) >= 6 && parseInt(item.departureTime.split(":")[0]) < 12) {
                          departureTime = "6to12";
                        }
                        else if (parseInt(item.departureTime.split(":")[0]) >= 12 && parseInt(item.departureTime.split(":")[0]) < 18) {
                          departureTime = "12to6";
                        }
                        else if (parseInt(item.departureTime.split(":")[0]) >= 18) {
                          departureTime = "after6";
                        }


                        return <div key={index}>



                          {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.airlineName))) && this.state.priceRangeRMin <= item.netAmount && this.state.priceRangeRMax >= item.netAmount && (this.state.StopsReturn.length == 0 || (this.state.StopsReturn.length > 0 && this.state.StopsReturn.includes(item.noofStop.toString()))) && (this.state.DepartureTimesReturn.length == 0 || (this.state.DepartureTimesReturn.length > 0 && this.state.DepartureTimesReturn.includes(departureTime))) && (this.state.ArrivalTimesReturn.length == 0 || (this.state.ArrivalTimesReturn.length > 0 && this.state.ArrivalTimesReturn.includes(arrivalTime))) &&


                            // return starts

                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="deal-wrapper">

                                <div className="flight-route-display oneway-mob">
                                {showCheckboxesR && (
                                      <input
                                        type="checkbox"
                                        checked={selectedItemsR.some(selectedItemR => selectedItemR.index === index && selectedItemR.type === "R")}
                                        onChange={(e) => this.handleCheckboxChangeR(item, index, "R", e)} className="share-check"
                                      />
                                    )}
                                  <label className="round-selection">
                                    <input name="plan" className="radio" type="radio" onChange={(e) => this.ReturnEvent(item)} />
                                    <ul className="d-flex flex-row">
                                      <li> <img
                                        src={`assets/img/logos/${item.airlineCode}.gif`}
                                        alt="." className="air-logo-mob"
                                      /></li>
                                      <li>{item.airlineName}</li>
                                      <li>{item.flightDetails}</li>

                                    </ul>
                                    <div className="row">

                                      <div className="route-details-area col-lg-4 col-md-4 col-4">
                                        <div className="row">
                                          <p className="small-text">{item.source}</p>
                                          <h6 className="time-text">{item.departureTime}</h6>
                                          <p className="small-text text-grey">{item.departureDate}
                                            <br />{item.sourceAirport}
                                            {item.depTerminal && item.depTerminal.length > 0 && (
                                              <>
                                                Terminal:{item.depTerminal}
                                                <br />
                                              </>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="travel-time col-lg-4 col-md-4 col-4 text-center">
                                        <p className="small-text text-grey">{item.duration}</p>
                                        <span><img src="assets/img/arrow.png" width={80} /></span>
                                        <p className="small-text text-grey">{item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}</p>
                                      </div>
                                      <div className="route-details-area col-lg-4 col-md-4 col-4">
                                        <div className="row">
                                          <p className="small-text">{item.destination}</p>
                                          <h6 className="time-text">{item.arrivalTime}</h6>
                                          <p className="small-text text-grey">{item.arrivalDate}
                                            <br />{item.destinationAirport}
                                            {item.arrTerminal && item.arrTerminal.length > 0 && (
                                              <>
                                                Terminal:{item.arrTerminal}
                                                <br />
                                              </>
                                            )}
                                          </p>
                                        </div>
                                      </div>

                                    </div>
                                    <div className="flight-details-area d-flex flex-row-reverse pt-2 align-content-center align-items-center">

                                      <div className="flex-row mr-10"><h4 className="price-text">₹{Math.round(item.netAmount)}</h4>

                                      </div>
                                      {item.pricingList && (
                                          <div className="flex-row mr-10"><span className="mr-10 text-grey small-text">
                                            {item.pricingList.length > 1 ? (
                                              <a className="more-fare-btn indi-more-fare" data-bs-toggle="collapse" data-bs-target={'#collapsesMoreRight' + `${index}`} aria-controls="collapseMoreRight">More Fares</a>
                                            ) : <div></div>}
                                          </span></div>
                                        )}
                                      <div className="flex-row"><span className="mr-10 text-grey small-text">
                                      </span></div>
                                      <div className="flex-row">
                                        {item.freeBaggage !== "" && (
                                          <p>
                                            {" "}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              className="bi bi-briefcase"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                            </svg>{" "}
                                            {item.freeBaggage}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                  </label>

                                  {/* Invidual more fare starts */}
                                  <div className="collapse" id={'collapsesMoreRight' + `${index}`}>
                                      <div className="card card-body" style={{ marginTop: '10px' }}>
                                        <OwlCarousel
                                          items={2}
                                          className="owl-carousel more-ind"


                                          margin={5}
                                          nav={true}
                                          dots={false}
                                          responsive={{
                                            0: {
                                              items: 2,
                                            },
                                            600: {
                                              items: 3,
                                            },
                                            1000: {
                                              items: 2,
                                              stagePadding: item.pricingList.length > 2 ? 25 : 0,
                                            },
                                          }}
                                        >
                                          {item.pricingList.map((item1, index1) => (
                                            <div className="item" key={index1}>
                                              <div className="card">
                                                <label className="fare-selection">
                                                  <input
                                                    name={`plan` + `${index}`}
                                                    className="single-fare"
                                                    id={`single1` + `${index1}`}
                                                    type="radio"
                                                    defaultChecked={index1 == 0}
                                                    //onChange={() => { let data = this.state.ItinSearchResultR; data[index].freeBaggage = item1.checkinBaggage; data[index].fareName = item1.fareName;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResultR: data }) }}
                                                    onChange={() => this.handleItineraryChangeRT(index, item1)}
                                                  />
                                                  <div className="card-header text-center" style={{ backgroundColor: '#adcdf5', fontWeight: '700' }}>
                                                    <div>{item1.fareName}</div>
                                                  </div>
                                                  <div className="card-body" style={{ padding: '2px' }}>
                                                    <h4 className="no-margin text-center">{Math.round(item1.netAmount)}</h4>
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="briefcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 512 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
                                                              ></path>
                                                            </svg>
                                                            Hand Bag
                                                          </td>
                                                          <td className="small-text">{item1.cabinBaggage}</td>
                                                        </tr>
                                                        <tr>
                                                          <td className="small-text">
                                                            <svg
                                                              className="more-fare-bag"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="cart-flatbed-suitcase"
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 640 512"
                                                              data-fa-i2svg=""
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M0 32C0 14.3 14.3 0 32 0H48c44.2 0 80 35.8 80 80V368c0 8.8 7.2 16 16 16H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H541.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H253.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H144c-44.2 0-80-35.8-80-80V80c0-8.8-7.2-16-16-16H32C14.3 64 0 49.7 0 32zM432 96V56c0-4.4-3.6-8-8-8H344c-4.4 0-8 3.6-8 8V96h96zM288 96V56c0-30.9 25.1-56 56-56h80c30.9 0 56 25.1 56 56V96 320H288V96zM512 320V96h16c26.5 0 48 21.5 48 48V272c0 26.5-21.5 48-48 48H512zM240 96h16V320H240c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"
                                                              ></path>
                                                            </svg>
                                                            Check-in
                                                          </td>
                                                          <td className="small-text">{item1.checkinBaggage}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </label>
                                              </div>



                                            </div>
                                          ))}







                                        </OwlCarousel>
                                      </div>
                                    </div>

                                    {/* Invidual more fare ends */}
                                </div>
                              </div>
                            </div>

                            //return ends



                          }





                          {/* No AIrline Selected...
   
      //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                        </div>
                      }


                      )}
                      {/*trip area ends*/}

                    </form>
                  </Tab>


                </Tabs>
              </div>





              {/* Individual round trip Mobile view ends */}



            </div>
          </div>
        </div>


        {/*Flight details popup*/}
        {/* Modal */}
        <div className="modal fade" id="flightdetails" tabIndex={-1} aria-labelledby="flightdetailsLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="flightdetailsLabel">FLIGHT DETAILS</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">

                <div className="tab-content" id="ex1-content">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 f-details-max" id="scrollbar1">
                      <div className="col-lg-12 col-md-12" >

                        <div className="popup-route-details">
                          <h4>{this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity} <span>{this.state.objitinResult.departureDate}</span> <span>{this.state.objitinResult.adultCheckinBaggage}</span><span>{this.state.objitinResult.adultCabinBaggage}</span></h4>
                        </div>
                        {this.state.objseglistResult.map((item, index) => (

                          <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" key={index} >
                            <h6><img src={`assets/img/logos/${item.airlineCode}.gif`} className="tab-air" width={30} />{item.airlineName} {item.airlineCode} {item.flightNumber}
                              <span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                              </svg></span>{item.airlineFlightClass}</h6>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4 dep">
                                <h4>{item.departureAirportCode} {item.departureTime}</h4>
                                <p className="small-text">{item.departureDate}</p>
                                <p className="small-text">{item.departureAirport}</p>
                                {item.depTerminal && item.depTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.depTerminal}</p>
                                )}
                                <p className="small-text">{item.departureCity}</p>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                                <div className="flex-column"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-clock text-grey" viewBox="0 0 16 16">
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg></div>
                                <div className="flex-column">
                                  <p className="small-text text-grey">{item.travelDuration}</p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4 dep">
                                <h4>{item.arrivalAirportCode} {item.arrivalTime}</h4>
                                <p className="small-text">{item.arrivalDate}</p>
                                <p className="small-text">{item.arrivalAirport}</p>
                                {item.arrTerminal && item.arrTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.arrTerminal}</p>
                                )}
                                <p className="small-text">{item.arrivalCity}</p>
                              </div>

                            </div>
                            {index !== this.state.objseglistResult.length - 1 ? (
                              <div className="layover">
                                Flight change at {item.layoverSector} , layover of {item.layoverTime}
                              </div>
                            ) : <div></div>}


                          </div>

                        ))}
                      </div>


                      <div className="col-lg-12 col-md-12" >

                        <div className="popup-route-details">
                          <h4>{this.state.objitinResultR.originCity} - {this.state.objitinResultR.destinationCity} <span>{this.state.objitinResultR.departureDate}</span> <span>{this.state.objitinResultR.adultCheckinBaggage}</span><span>{this.state.objitinResult.adultCabinBaggage}</span></h4>
                        </div>

                        {this.state.objseglistResultR.map((item, index) => (


                          <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" key={index} >
                            <h6><img src={`assets/img/logos/${item.airlineCode}.gif`} className="tab-air" width={30} />{item.airlineName} {item.airlineCode} {item.flightNumber}
                              <span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                              </svg></span>{item.airlineFlightClass}</h6>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4 dep">
                                <h4>{item.departureAirportCode} {item.departureTime}</h4>
                                <p className="small-text">{item.departureDate}</p>
                                <p className="small-text">{item.departureAirport}</p>
                                {item.depTerminal && item.depTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.depTerminal}</p>
                                )}
                                <p className="small-text">{item.departureCity}</p>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                                <div className="flex-column"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-clock text-grey" viewBox="0 0 16 16">
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg></div>
                                <div className="flex-column">
                                  <p className="small-text text-grey">{item.travelDuration}</p>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4 dep">
                                <h4>{item.arrivalAirportCode} {item.arrivalTime}</h4>
                                <p className="small-text">{item.arrivalDate}</p>
                                <p className="small-text">{item.arrivalAirport}</p>
                                {item.arrTerminal && item.arrTerminal.length > 0 && (
                                  <p className="small-text">Terminal:{item.arrTerminal}</p>
                                )}
                                <p className="small-text">{item.arrivalCity}</p>
                              </div>

                            </div>
                            {index !== this.state.objseglistResultR.length - 1 ? (
                              <div className="layover">
                                Flight change at {item.layoverSector} , layover of {item.layoverTime}
                              </div>
                            ) : <div></div>}


                          </div>

                        ))}
                      </div>

                    </div>
                    <div className="col-lg-4 col-md-4 d-flex flex-column summary-area">
                      <div className="fare-summery-area">

                        <nav>
                          <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-fare-tab" data-bs-toggle="tab" data-bs-target="#nav-fare" type="button" role="tab" aria-controls="nav-fare" aria-selected="true">Fare Summary</button>
                            <button className="nav-link" id="nav-bag-tab" data-bs-toggle="tab" data-bs-target="#nav-bag" type="button" role="tab" aria-controls="nav-bag" aria-selected="false">Baggage</button>

                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade show active" id="nav-fare" role="tabpanel" aria-labelledby="nav-fare-tab">
                            <h4 className="text-white">{this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity}</h4>
                            <table className="f-smry-table">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Base fare</th>
                                  <th>Fees & Tax</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult x {this.state.FlightDetailsResult.adult}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtTax)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child x {this.state.FlightDetailsResult.child}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdTax)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant x {this.state.FlightDetailsResult.infant}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infBasic)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infTax)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                            <h4 className="text-white">{this.state.objitinResultR.originCity} - {this.state.objitinResultR.destinationCity}</h4>
                            <table className="f-smry-table border-btm">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Base fare</th>
                                  <th>Fees & Tax</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult x {this.state.FlightDetailsResult.adult}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtBasicR)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.adtTaxR)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child x {this.state.FlightDetailsResult.child}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdBasicR)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.chdTaxR)}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant x {this.state.FlightDetailsResult.infant}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infBasicR)}</td>
                                    <td>{Math.round(this.state.FlightDetailsResult.infTaxR)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <table className="f-smry-table">
                              <tbody>
                                <tr>
                                  <td><h4>Total :</h4></td>
                                  <td align="right"><h4>{this.state.FlightDetailsResult.totalFare ? Math.round(this.state.FlightDetailsResult.totalFare) : "N/A"}</h4></td>
                                </tr>
                              </tbody>
                            </table>
                            <p className="no-margin text-white small-text">*All fares & seats are subject to availability at the time of booking</p>
                          </div>
                          <div className="tab-pane fade" id="nav-bag" role="tabpanel" aria-labelledby="nav-bag-tab">
                            <h4 className="text-white">{this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity}</h4>
                            <table className="f-smry-table">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Check-in Baggage</th>
                                  <th>Cabin Baggage</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult</td>
                                    <td>{this.state.objitinResult.adultCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.adultCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child</td>
                                    <td>{this.state.objitinResult.childCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.childCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant</td>
                                    <td>{this.state.objitinResult.infantCheckinBaggage}</td>
                                    <td>{this.state.objitinResult.infantCabinBaggage}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                            <h4 className="text-white">{this.state.objitinResultR.originCity} - {this.state.objitinResultR.destinationCity}</h4>
                            <table className="f-smry-table">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Check-in Baggage</th>
                                  <th>Cabin Baggage</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.FlightDetailsResult.adult > 0 && (
                                  <tr>
                                    <td>Adult</td>
                                    <td>{this.state.objitinResultR.adultCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.adultCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.child > 0 && (
                                  <tr>
                                    <td>Child</td>
                                    <td>{this.state.objitinResultR.childCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.childCabinBaggage}</td>
                                  </tr>
                                )}
                                {this.state.FlightDetailsResult.infant > 0 && (
                                  <tr>
                                    <td>Infant</td>
                                    <td>{this.state.objitinResultR.infantCheckinBaggage}</td>
                                    <td>{this.state.objitinResultR.infantCabinBaggage}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <p className="text-white no-margin small-text">*The information is just for reference. Please check with airline before check-in</p>
                          </div>

                        </div>

                        <div className="popup-book-btn-area">

                          <button type="button" className="btn btn-primary button" onClick={() => this.BookingRequest(this.state.RequestFields.itinId, this.state.RequestFields.fareId, this.state.RequestFields.providerCode, this.state.RequestFields.itinIdR, this.state.RequestFields.fareIdR, this.state.RequestFields.providerCodeR)} data-bs-dismiss="modal" aria-label="Close">BOOK</button>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                <div className="layover">
                </div>

              </div>
            </div>
          </div>
        </div>





        {/*Round Trip Price area starts*/}
        {/* Bottom flight details desktop starts */}
        {this.state.OnwardSector.airlineCode != null && this.state.ReturnSector.airlineCode != null &&
          <div className="fixed-bottom bottom-price-area oneway-desk">
            <div className="container-fluid">
              <div className="row">
                <div className="col-l-g-4 col-md-4 right-dashed-border col-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 pt-3 col-4">
                      <div className="d-flex">
                        <img src={`assets/img/logos/${this.state.OnwardSector.airlineCode}.gif`} width={40} className="mr-10" />
                        <p className="small-text text-white">{this.state.OnwardSector.airlineName}<br />{this.state.OnwardSector.flightDetails}</p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 pt-2">
                      <p className="text-white small-text">{this.state.OnwardSector.source}-{this.state.OnwardSector.destination}</p>
                      <p className="small-text text-white">{this.state.OnwardSector.departureDate} </p>
                      <p className="small-text text-white"> {this.state.OnwardSector.arrivalDate}  ({this.state.OnwardSector.noofStop} Stops)</p>
                    </div>
                    <div className="col-lg-3 col-md-3 pt-4">
                      <div className="flex-row align-content-center align-items-center align-middle">
                        <h6 className="text-white text-bold">₹{Math.round(this.state.OnwardSector.netAmount)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-l-g-4 col-md-4 right-dashed-border">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 pt-3">
                      <div className="d-flex">
                        <img src={`assets/img/logos/${this.state.ReturnSector.airlineCode}.gif`} width={40} className="mr-10" />
                        <p className="small-text text-white">{this.state.ReturnSector.airlineName}<br />{this.state.ReturnSector.flightDetails}</p>

                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 pt-2">
                      <p className="text-white small-text">{this.state.ReturnSector.source} - {this.state.ReturnSector.destination}</p>
                      <p className="small-text text-white">{this.state.ReturnSector.departureDate} </p>
                      <p className="small-text text-white"> {this.state.ReturnSector.arrivalDate}  ({this.state.ReturnSector.noofStop} Stops)</p>
                    </div>
                    <div className="col-lg-3 col-md-3 pt-4">
                      <div className="flex-row align-content-center align-items-center align-middle">
                        <h6 className="text-white text-bold">₹{Math.round(this.state.ReturnSector.netAmount)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-l-g-2 col-md-2 right-dashed-border pt-3">
                  <div className="text-center">
                    <h6 className="text-white text-bold text-size-1 no-margin">₹{Math.round(this.state.OnwardSector.netAmount + this.state.ReturnSector.netAmount)}</h6>
                    <p className="no-margin"><a onClick={() => this.getFlightDetailsResult(this.state.OnwardSector.itinId, this.state.OnwardSector.fareId, this.state.OnwardSector.providerCode, this.state.ReturnSector.itinId, this.state.ReturnSector.fareId, this.state.ReturnSector.providerCode)} data-bs-toggle="modal" data-bs-target="#flightdetails" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}>Flight Details <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg></a></p>
                  </div>
                </div>
                <div className="col-l-g-2 col-md-2 right-dashed-border pt-3">
                  <button className="btn btn-primary bottom-search-button" onClick={() => this.BookingRequest(this.state.OnwardSector.itinId, this.state.OnwardSector.fareId, this.state.OnwardSector.providerCode, this.state.ReturnSector.itinId, this.state.ReturnSector.fareId, this.state.ReturnSector.providerCode)}>BOOK</button>
                </div>
              </div>
            </div>
          </div>



        }
        {/* Bottom flight details desktop ends */}

        {/* Bottom flight details mobile starts */}

        {this.state.OnwardSector.airlineCode != null && this.state.ReturnSector.airlineCode != null &&
          <div className="fixed-bottom bottom-price-area oneway-mob">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-6 p-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={`assets/img/logos/${this.state.OnwardSector.airlineCode}.gif`} width={30} className="mr-10" />
                    </div>
                    <div>
                      <p className="text-white small-text">{this.state.OnwardSector.source}-{this.state.OnwardSector.destination}</p>
                      <p className="text-white small-text">₹{Math.round(this.state.OnwardSector.netAmount)}</p>
                    </div>
                  </div>


                </div>
                <div className="col-lg-6 col-md-6 col-6 p-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={`assets/img/logos/${this.state.ReturnSector.airlineCode}.gif`} width={30} className="mr-10" />
                    </div>
                    <div>
                      <p className="text-white small-text">{this.state.ReturnSector.source} - {this.state.ReturnSector.destination}</p>
                      <p className="text-white small-text">₹{Math.round(this.state.ReturnSector.netAmount)}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="d-flex">
                    <h6 className="text-white text-bold text-size-1 mr-10">₹{Math.round(this.state.OnwardSector.netAmount + this.state.ReturnSector.netAmount)}</h6>
                    <p className="no-margin"><a onClick={() => this.getFlightDetailsResult(this.state.OnwardSector.itinId, this.state.OnwardSector.fareId, this.state.OnwardSector.providerCode, this.state.ReturnSector.itinId, this.state.ReturnSector.fareId, this.state.ReturnSector.providerCode)} data-bs-toggle="modal" data-bs-target="#flightdetails" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg></a></p>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-6">

                  <button className="btn btn-primary bottom-search-button" onClick={() => this.BookingRequest(this.state.OnwardSector.itinId, this.state.OnwardSector.fareId, this.state.OnwardSector.providerCode, this.state.ReturnSector.itinId, this.state.ReturnSector.fareId, this.state.ReturnSector.providerCode)}>BOOK</button>
                </div>
              </div>
            </div>
          </div>



        }
        {/* Bottom flight details mobile ends */}



      </div>

    ) : (!this.state.isLoaded && this.state.isResultFound) ? (
      <div>
        <div className="bg-white p-3">
          <div className="pt-6 align-items-center justify-content-center">
            <div className="text-center pt-5">
              <img src="assets/img/no-flight.png" className="no-flight" width={100} />
              <h4>Oops! No flights found</h4>
              <p>Sorry, There were no flights found for this route and date combination.<br />We suggest you modify your search and try again.</p>
              <Link to="/"><button className="btn btn-primary mb-5">Back to search</button></Link>
            </div>
          </div>
        </div>

      </div>
    ) : (
      <div>
        <div className="progress1 indeterminate"></div>
        <div className="loader">
          <div className="wait">
            <div className="typewriter">
              <div>
                <p>Finding the best options for you!</p>
              </div>
            </div>




          </div>

          <div className="plane1">
            <img src="assets/img/loader.gif" className="plane1-img" />
          </div>
          <div className="earth-wrapper">
            <div className="earth"></div>
          </div>

        </div>

      </div>
    )
  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  return <SearchResultIndividualRoundTrip {...props} navigate={navigate} />
}

export default WithNavigate
