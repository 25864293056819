/////// Production ////////////////////////////////////////////////////////////////////////////////////
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
  apiKey: "AIzaSyBUwMeAEKGp-_n9wZS_B8-v0illN7fUHk4",
  authDomain: "travel-mythri-87ad4.firebaseapp.com",
  projectId: "travel-mythri-87ad4",
  storageBucket: "travel-mythri-87ad4.appspot.com",
  messagingSenderId: "550485816555",
  appId: "1:550485816555:web:5e453074e6ae21212c7aae",
  measurementId: "G-TG14XZC83D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export {auth, provider};




// /////// Local Testing ///////////////////////////////////////////////////////////////////////////
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCPAHqOq4WvxHLJRjkIhXaDD5bkCSVqJQc",
//   authDomain: "tm3-web.firebaseapp.com",
//   projectId: "tm3-web",
//   storageBucket: "tm3-web.appspot.com",
//   messagingSenderId: "571099920994",
//   appId: "1:571099920994:web:935611b6b9c0db6c8e6f3b",
//   measurementId: "G-JTFGSHF008"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const provider = new GoogleAuthProvider();
// export {auth, provider};



