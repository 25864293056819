import React, { Component } from 'react'
import { GetAirlineTicketHistory } from '../../Services/AirlineService'
import { Link, useNavigate } from 'react-router-dom';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetTicketCopyUrl } from "../../Services/FlightDetails"
import { EmailTicket } from "../../Services/AirlineService"
import { ToastContainer, toast } from 'react-toastify';


class MyTrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            RequestBody: {},
            ResponseBody: [],
            HistoryList: [],
            cancel: false
        };
        this.bookedAndCancelledList = this.bookedAndCancelledList.bind(this);
    }
    componentDidMount() {
        this.setState({

            isLoaded: false,
        });
        this.bookedAndCancelledList("S");
    }

    bookedAndCancelledList(type) {

        //Check network connection...
        if (!CheckNetworkStatus())
            return;

        const BookDate = { status: type }

        GetAirlineTicketHistory(BookDate)
            .then(response => {

                if (response.data.status) {
                    let getData = {};
                    getData = response.data;
                    const cancel = (type === "S") ? false : true;
                    this.setState({
                        ResponseBody: getData,
                        HistoryList: getData.objAirlineTicketHistory,
                        isLoaded: true,
                        cancel: cancel

                    });
                }
                else {
                    this.setState({
                        ResponseBody: {},
                        HistoryList: [],
                        isLoaded: true,
                    });
                }
            })
            .catch((error) => {
                if (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut") {

                    localStorage.removeItem("TM_UDetails");
                    localStorage.removeItem("tokenValue");
                    localStorage.removeItem("onewaySearchResult");
                    localStorage.removeItem("NonUsertokenValue");
                    localStorage.removeItem("DeviceId");
                    localStorage.removeItem("RS");

                    toast.success('Something went wrong...', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                    this.props.navigate('/');

                }
            });
    }

    handleEmailTicketClick = (bookingReferenceId, email) => {

        let data = {};
        data.pnr = bookingReferenceId;
        data.email = email;

        EmailTicket(data).then((response) => {
            if (response.data.status) {
                toast.success('Email Sent Successfully...', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, // or any other configuration you want
                    theme: 'colored'
                });
            }
            else {
                toast.success('Something Went Wrong...', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, // or any other configuration you want
                    theme: 'colored'
                });
            }
        })
            .catch((error) => {
                console.error('Error applying promo code:', error);
                if (error.response.data.responseMessage == "InvalidToken") {

                    localStorage.removeItem("TM_UDetails");
                    localStorage.removeItem("tokenValue");
                    localStorage.removeItem("onewaySearchResult");

                    this.props.navigate('/');

                }




            });
    }


    render() {
        return (
            <div className="my-acnt-bg">
                <div className="container">
                    <div className="user-profile pt-3">
                        <h5 className="text-white">My Account</h5>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link to="/Profile"><button className="nav-link " id="nav-home-tab" type="button" >Profile</button></Link>
                                <Link to="/MyTrips"><button className="nav-link active" id="nav-trips-tab" type="button" >My Trips</button></Link>
                                <Link to="/Insurance"><button className="nav-link" id="nav-trips-tab" type="button" >Insurance</button></Link>
                                <Link to="/AccountStatement"><button className="nav-link" id="nav-account-tab" type="button">My Transactions</button></Link>
                            </div>
                        </nav>
                        <div className="tab-content pt-3" id="nav-tabContent">

                            <div>
                                <div className="profile-area mb-3">
                                    <h4>My trips</h4>
                                    <div className="my-trips-area">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => this.bookedAndCancelledList('S')}>Booked</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false" onClick={() => this.bookedAndCancelledList('C')}>Cancelled</button>
                                            </li>

                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                {this.state.isLoaded && this.state.HistoryList.length > 0 && this.state.HistoryList.map((item,index) => (


                                                    <ul className="trip-list" key={index}>


                                                        <li className="trip-area mb-3">
                                                            <div className="row">
                                                                <div className="col-md-6 col-lg-6 col-12">


                                                                    <h4>{item.departureCity} <span className="small-text">&#129122;</span> {item.arrivalCity}</h4>
                                                                    <p className="small-text">
                                                                        {item.tripMode === "O" ? (
                                                                            <span>One way flight</span>
                                                                        ) : item.tripMode === "R" ? (
                                                                            <span>Round trip flight</span>
                                                                        ) : (
                                                                            <span>Round trip flight</span>
                                                                        )} &nbsp;
                                                                        {this.state.cancel === false ? (
                                                                            <strong>
                                                                                Booking Date: {item.date}. Booking ID: {item.bookingReferenceId}. PNR: {item.airlinePnr}
                                                                            </strong>
                                                                        ) : (
                                                                            <strong>
                                                                                Cancelled Date: {item.date}. Booking ID: {item.bookingReferenceId}. PNR: {item.airlinePnr}
                                                                            </strong>
                                                                        )}

                                                                    </p>
                                                                </div>
                                                                <div className="col-md-6 col-lg-6 pt-3 col-12">
                                                                    <h6 className="float-end">Status :{item.bookingType}</h6>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    {this.state.isLoaded && item.objSegDetails.length > 0 && item.objSegDetails.map((item1,Segindex) => (
                                                                        <div className="col-lg-8 col-md-8 mb-3" key={Segindex}>
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-lg-4 col-6 dep">
                                                                                    <small>DEPARTURE</small>
                                                                                    <h6 className="no-margin"><strong>{item1.departureDate}</strong></h6>
                                                                                    <small>{item1.departureAirportDetails}<br />Terminal {item1.departureTerminal}</small>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-4 col-6 dep">
                                                                                    <small>ARRIVAL</small>
                                                                                    <h6 className="no-margin"><strong>{item1.arrivalDate}</strong></h6>
                                                                                    <small>{item1.arrivalAirportDetails}<br />Terminal {item1.arrivalTerminal}</small>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-4 pt-3 col-12">
                                                                                    <img src={`assets//img/logos/${item1.airlineCode}.gif`} alt="travelmythri" /> <small>{item1.airlineName}</small>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>

                                                                <div className="col-lg-4 col-md-4 col-12">
                                                                    {item.objPaxDetails.map((item2,Paxindex) => (
                                                                        <div className="col-md-12 col-lg-12 pt-3" key={Paxindex}>
                                                                            <h6 className="no-margin m-pax">{item2}</h6>
                                                                        </div>
                                                                    ))}
                                                                </div>


                                                                <hr />
                                                                <div className="col-md-6 col-lg-6 col-12">
                                                                    <h5 className="no-margin float-start booking-total">Total Amount : {item.totalAmount}</h5>
                                                                </div>
                                                                <div className="col-md-6 col-lg-6 col-12">
                                                                    <div className="d-flex justify-content-end">
                                                                        <a href={item.ticketUrl} target="_blank" className="mr-10 no-underline trip-button"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                                                        </svg> Print Ticket.</a>
                                                                        <a href="#" onClick={() => this.handleEmailTicketClick(item.bookingReferenceId, item.email)} className="mr-10 no-underline trip-button"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                                                                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                                                            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                                                        </svg> Email Ticket.</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                ))}
                                                {this.state.isLoaded && this.state.HistoryList.length == 0 && <div className="d-flex flex-row align-items-center justify-content-center">
                                                    <div className="mr-15">
                                                        <img src="assets/img/suitcase.svg" width={100} />
                                                    </div>
                                                    <div>
                                                        <h4>Looks empty, you've no bookings.</h4>
                                                        <p>When you book a trip, you will see your itinerary here.</p>
                                                    </div>
                                                </div>}
                                                {!this.state.isLoaded && <div className="text-center">
                                                    <span className="loader"></span>
                                                </div>}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <MyTrips {...props} navigate={navigate} />
}

export default WithNavigate
