import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection, EmptyResponse } from '../Services/UtilitiesServices';

  export function Authenticate(data)
  { 
      return AxiosConfig.post("Account/Authenticate",data, HeaderConfig());
  }
  export function SetUserDetails(data)
  {    
      localStorage.setItem('tokenValue',data.token);
  }
  export function SubmitOTPToLogin(data)
  {
      return AxiosConfig.post("Account/OtpSubmitForAuthentication",data, HeaderConfig());
  }
  export function LogoutUser(data)
  {
      return AxiosConfig.post("Account/LogoutUser",data, HeaderConfig());
  }
  export function DeleteProfileRequest(data)
  {
      return AxiosConfig.post("Account/ProfileDeleteRequest",data, HeaderConfig());
  }
  export function DeleteProfileOTPSubmission(data)
  {
      return AxiosConfig.post("Account/ProfileDeleteOtpSubmit",data, HeaderConfig());
  }
  export function GetDeleteProfilePageLoadData(data)
  {
      return AxiosConfig.post("Account/ProfileDeletePageloadRequest",data, HeaderConfig());
  }