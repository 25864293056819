import React from 'react'

function HeaderConfig() {
    let Auth="", Device="";
    let tokenValue=localStorage.getItem("tokenValue");
   
    if (tokenValue == null || tokenValue === "" || tokenValue === undefined) {
      Device = localStorage.getItem("DeviceId");
    }
   
    
    if( (tokenValue==null || tokenValue=="" || tokenValue==undefined))
    {  
      Auth =  'Bearer '+ localStorage.getItem("NonUsertokenValue");
    }
    else
    {
      Auth =  'Bearer '+ localStorage.getItem("tokenValue");
    }
    return  {
      headers:{
        Authorization: Auth, 
        DeviceId:Device
      }
    };
}

export default HeaderConfig