import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function AccountStatementServices(data)
{
    return AxiosConfig.post("ControlPanel/AccountStatments",data, HeaderConfig());
}
// export function MyTripServices(data)
// {
//     return AxiosConfig.post("Airline/GetAirlineTicketHistory",data);
// }