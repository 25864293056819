import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function GetPaymentDetails(data)
{
  return AxiosConfig.post("PaymentGateway/GetDetailsForPayment",data, HeaderConfig());
}

export function PaymentRequest(data)
{
  return AxiosConfig.post("PaymentGateway/paymentRequest",data, HeaderConfig());
}

export function GetPaymentGatewayCharge(data)
{
  return AxiosConfig.post("PaymentGateway/GetPaymentGatewayCharge",data, HeaderConfig());
}