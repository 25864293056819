import React, {useState, useEffect} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GetOneWaySearch } from '../Services/OneWaySearch';

function SearchResultMulticity() {

    const [requestBody, setRequestBody] = useState();
    const [responseBody, setResponseBody] = useState([]);
    const [LowerFareResult, SetLowerFareResult] = useState([]);
    const [AvlAirlineResult, SetAvlAirlineResult] = useState([]);
    const [ItinSearchResult, SetItinSearchResult] = useState([]);


    useEffect(()=> {
        setRequestBody(JSON.parse(localStorage.getItem("onewaySearchResult")));
      }, [1])

      
      function SearchFlight()
      {        
        getSearchResult();
      }

      function getSearchResult() {

        //return;
  
        GetOneWaySearch(requestBody).
            then((response) => {
                setResponseBody(response.data);
                SetLowerFareResult(response.data.objlowfareList)
                SetAvlAirlineResult(response.data.objAvlairlineList)
                SetItinSearchResult(response.data.objItinList)
            })
    }



    return (
        <div onLoad={getSearchResult}>
           
        <div className="inner-search-area">
            <form className="inner-search" id="inner-search" alt="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex">
                                <div className="flex-row mr-10">
                                    <p className="small-text text-white">
                                        Depart From
                                    </p>
                                    <input type="text" className="form-control inner-search-input" defaultValue="Dubai" name='origin' value={responseBody.origin} onChange={(event) => setResponseBody({ ...responseBody, origin:event.target.value})}  />
                                </div>
                                <div className="flex-row mr-10">
                                    <div className="swap-image text-center pt-3">
                                        <a  className="inner-swap">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <p className="small-text text-white">
                                        Going to
                                    </p>
                                    <input type="text" className="form-control inner-search-input" defaultValue="Calicut" name='destination' value={responseBody.destination} onChange={(event) => setResponseBody({ ...responseBody, destination:event.target.value})} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex">
                                <div className="flex-row mr-10">
                                    <p className="small-text text-white">
                                        Departure Date 
                                    </p>
                                    <input type="text" defaultValue="Choose Date" className="form-control inner-search-input" id="input_from" name='fromDate' value={responseBody.departureDate} onChange={(event) => setResponseBody({ ...responseBody, departureDate:event.target.value})}  />
                                </div>
                                <div className="flex-row">
                                    <p className="small-text text-white">
                                        Return Date
                                    </p>
                                    <input type="text" defaultValue="Choose Date" className="form-control inner-search-input" id="input_to"  name='toDate' value={responseBody.returnDate} onChange={(event) => setResponseBody({ ...responseBody, returnDate:event.target.value})} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex">
                                <div className="flex-row">
                                    <p className="small-text text-white">
                                        Traveller,s Class
                                    </p>
                                    <input type="text" className="form-control inner-search-input" defaultValue="1 Traveller, Economy" />
                                </div>
                                <div className="flex-row pt-3">
                                    <button type='button' className="btn btn-primary inner-search-button" onClick={SearchFlight}>SEARCH</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div>
           
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <div className="side-bar">
                            <div className="bg-white" id="sidebar-wrapper">
                                {/*Airline Price area starts*/}
                                <div className="airline-area">
                                    <div className="side-bar-title">
                                        <h4>Airlines</h4>
                                    </div>
                                    <form className="airline" id="airline" alt="">
                                    {AvlAirlineResult.map((item, index) => (
                                        <div className="d-flex airline-price mb-3" key={index}>
                                            <div className="flex-row">
                                                <img src="assets/img/airlines/6E.png" width={30} alt="" />
                                            </div>
                                            <div className="flex-row pt-2 pl-5 me-auto">
                                                <h6>{item.airlineName}<span>(5)</span></h6>
                                            </div>
                                            <div className="flex-row pt-2 mr-10">
                                                <h6>₹ {item.minAmount}</h6>
                                            </div>
                                            <div className="air-check-box d-flex align-items-center">
                                                <input type="checkbox" className="form-control" />
                                            </div>
                                        </div>
                                        ))}
                                       
                                    </form>
                                </div>
                                {/*Airline Price area ends*/}
                                {/*Stops area starts*/}
                                <div className="stops-area">
                                    <div className="side-bar-title">
                                        <h4>Stops</h4>
                                    </div>
                                    <div id="stop-area">
                                        <input type="checkbox" id="non-stop" name="non-stop" defaultValue="all" defaultChecked />
                                        <label htmlFor="non-stop">0<br />Non-stop</label>
                                        <input type="checkbox" id="one-stop" name="one-stop" defaultValue="all" />
                                        <label htmlFor="one-stop">1<br />stop</label>
                                        <input type="checkbox" id="oneplus-stop" name="oneplus-stop" defaultValue="all" />
                                        <label htmlFor="oneplus-stop">1+<br />stop</label>
                                    </div>
                                </div>
                                {/*Stops area ends */}
                                {/*Departure time area starts */}
                                <div className="departure-area">
                                    <div className="side-bar-title">
                                        <h4>Departure Time</h4>
                                    </div>
                                    <div id="departure-time">
                                        <input type="radio" id="chk1" name="chk" defaultValue="all" defaultChecked />
                                        <label htmlFor="chk1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                        </svg><br />Before<br />6AM</label>
                                        <input type="radio" id="chk2" name="chk" defaultValue="false" />
                                        <label htmlFor="chk2"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                        </svg><br />6AM-<br />12PM</label>
                                        <input type="radio" id="chk3" name="chk" defaultValue="true" />
                                        <label htmlFor="chk3"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                        </svg><br />12PM-<br />6PM</label>
                                        <input type="radio" id="chk4" name="chk" defaultValue="true" />
                                        <label htmlFor="chk4"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                        </svg><br />AFTER<br />6PM</label>
                                    </div>
                                </div>
                                {/*Departure time area ends */}
                                {/*Arrival time area starts */}
                                <div className="arrival-area">
                                    <div className="side-bar-title">
                                        <h4>Arrival Time</h4>
                                    </div>
                                    <div id="arrival-time">
                                        <input type="radio" id="chk01" name="chk" defaultValue="all" defaultChecked />
                                        <label htmlFor="chk01"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-alt-high" viewBox="0 0 16 16">
                                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                                        </svg><br />Before<br />6AM</label>
                                        <input type="radio" id="chk02" name="chk" defaultValue="false" />
                                        <label htmlFor="chk02"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                        </svg><br />6AM-<br />12PM</label>
                                        <input type="radio" id="chk03" name="chk" defaultValue="true" />
                                        <label htmlFor="chk03"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                        </svg><br />12PM-<br />6PM</label>
                                        <input type="radio" id="chk04" name="chk" defaultValue="true" />
                                        <label htmlFor="chk04"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
                                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                                        </svg><br />AFTER<br />6PM</label>
                                    </div>
                                </div>
                                {/*Arrival time area ends */}
                                {/*refund/non refund selection */}
                                <div className="refund-area">
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Refundable</label>
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                    </div>
                                </div>
                                {/*Price range slider */}
                                <div className="price-slider-area">
                                    <div className="side-bar-title">
                                        <h4>One Way Price</h4>
                                    </div>
                                    <div data-role="main" className="ui-content">
                                        <form method="post" action alt="">
                                            <div data-role="rangeslider">
                                                <label htmlFor="price-min">Price:</label>
                                                <input type="range" name="price-min" id="price-min" defaultValue={200} min={0} max={1000} />
                                                <label htmlFor="price-max">Price:</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/*Aircraft area starts */}
                               
                                {/*Aircraft area ends */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9">
                <div className="top-sort-area">
                    <div className="d-flex align-items-center">
                        <div className="flex-row">
                            <form action id="multicity-sort-form">
                                <input type="radio" id="city1" name="cheapest" defaultValue="all" defaultChecked />
                                <label htmlFor="city1"><span className="text-large">BOM - DEL</span><br />
                                    <span>Mumbai - Delhi<br />
                                        Mon, 10 April 2023
                                    </span>
                                </label>
                                <input type="radio" id="city2" name="fastest" defaultValue="false" />
                                <label htmlFor="city2"><span className="text-large">BOM - DEL</span><br />
                                    <span>Mumbai - Delhi<br />
                                        Mon, 10 April 2023
                                    </span></label>
                                <input type="radio" id="city3" name="earliest" defaultValue="false" />
                                <label htmlFor="city3"><span className="text-large">BOM - DEL</span><br />
                                    <span>Mumbai - Delhi<br />
                                        Mon, 10 April 2023
                                    </span></label>
                            </form>
                        </div>
                    </div>
                </div>
                {/*One way result*/}
                {ItinSearchResult.map((item, index) => (
                <div className="deal-wrapper" key={index}>
  <div className="flight-route-display">
    <div className="row">
      <div className="col-lg-8 col-md-8">
        <div className="row align-items-center round-inter">
          <div className="airline-company col-lg-3 col-md-3 text-center">
            <img src="assets/img/airlines/6E.png" />
            <p className="small-text">{item.airlineName}<br />{item.flightDetails}</p>
          </div>
          <div className="route-details-area col-lg-3 col-md-3 text-right">
            <div className="row">
              <p className="small-text">{item.source}</p>
              <h6 className="time-text">{item.departureTime}</h6>
              <p className="small-text text-grey">{item.departureDate}<br />{item.sourceAirport}</p>
            </div>
          </div> 
          <div className="travel-time col-lg-3 col-md-3 text-center">
            <p className="small-text text-grey">{item.duration}</p>
            <span><img src="assets/img/arrow.png" alt /></span>
            <p className="small-text text-grey">{item.noofStop}</p>
          </div>
          <div className="route-details-area col-lg-3 col-md-3 text-left">
            <div className="row">
              <p className="small-text">{item.destination}</p>
              <h6 className="time-text">{item.arrivalTime}</h6>
              <p className="small-text text-grey">{item.arrivalDate}<br />{item.destinationAirport}</p>
            </div>
          </div> 
        </div>
      </div>
      <div className="col-lg-4 col-md-4 pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="flex-row"><span className="mr-10 text-grey"><strike>₹{item.amount}</strike></span></div>
            <div className="flex-row"><h4 className="price-text">₹{item.discount}</h4></div> 
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="flex-row"><a className="btn btn-primary book-btn">SELECT</a></div>
          </div>
        </div>
      </div>
    </div>
    <div className="flight-details-area d-flex flex-row-reverse">
      <div className="flex-row">
        <p><a data-bs-toggle="modal" data-bs-target="#flightdetails">Flight Details <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg></a></p>
      </div>
    </div>
  </div>
                </div>
))}
                {/*One way result ends*/}
 

            </div>
                </div>
            </div>
        </div>
       


        {/*Login popup*/}
        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Sign in to your TravelMythri Account!</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form className="login-form" id="login" action alt="">
                            <input type="text" className="form-control" defaultValue="Enter Mobile Number" />
                            <button type="submit" className="btn btn-primary">GET OTP</button>
                            <div className="pt-2 pb-2">
                                <p className="or-section">Or</p>
                            </div>
                            <div className="text-center">
                                <a className="google-login"><img src="assets/img/Google__G__Logo.svg" width={30} alt="" /> Sign in with
                                    Google</a>
                            </div>
                            <div className="pt-3 pb-3 small-text text-center">
                                <p>By continuing, you agree to our <a  className="privacy-link">privacy policy &amp; terms of use.</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/*Flight details popup*/}

        <div className="modal fade" id="flightdetails" tabIndex={-1} aria-labelledby="flightdetailsLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="flightdetailsLabel">FLIGHT DETAILS</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="popup-route-details">
                            <h4>Dubai - Calicut <span>14 Mar Tuesday</span></h4>
                        </div>
                        {/* Tabs navs */}
                        <ul className="nav nav-tabs mb-4" id="ex1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">FLIGHT INFORMATION</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">BAGGAGE</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">FARE RULES</a>
                            </li>
                        </ul>
                        {/* Tabs navs */}
                        {/* Tabs content */}
                        <div className="tab-content" id="ex1-content">
                            <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                <h6><img src="assets/img/airlines/587b511a44060909aa603a81-(1).png" width={30} alt="" />Air-India
                                    Express<span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                    </svg></span>Economy<span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                    </svg></span>Narrow 3-3 (Limited seat tilt) <span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                    </svg></span>Narrow</h6>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                        <h4>CCJ 08:30</h4>
                                        <p className="small-text">Fri, 17 Mar</p>
                                        <p className="small-text">Calicut International Airport</p>
                                        <p className="small-text">Calicut</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                                        <div className="flex-column"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-clock text-grey" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                        </svg></div>
                                        <div className="flex-column">
                                            <p className="small-text text-grey">4hr 5min</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 me-auto">
                                        <h4>DXB 10:30</h4>
                                        <p className="small-text">Fri, 17 Mar</p>
                                        <p className="small-text">Dubai International Airport</p>
                                        <p className="small-text">Dubai</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 d-flex flex-column">
                                        <div className="flex-column">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-egg-fried text-grey" viewBox="0 0 16 16">
                                                <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                <path d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z" />
                                            </svg><small className="text-grey">Food</small>
                                        </div>
                                        <div className="flex-column">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-plug-fill text-grey" viewBox="0 0 16 16">
                                                <path d="M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c-.002.434-.01.845-.04 1.22-.041.514-.126 1.003-.317 1.424a2.083 2.083 0 0 1-.97 1.028C6.725 13.9 6.169 14 5.5 14c-.998 0-1.61.33-1.974.718A1.922 1.922 0 0 0 3 16H2c0-.616.232-1.367.797-1.968C3.374 13.42 4.261 13 5.5 13c.581 0 .962-.088 1.218-.219.241-.123.4-.3.514-.55.121-.266.193-.621.23-1.09.027-.34.035-.718.037-1.141A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0z" />
                                            </svg><small className="text-grey">In seat charger</small>
                                        </div>
                                        <div className="flex-column">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-wifi text-grey" viewBox="0 0 16 16">
                                                <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z" />
                                                <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z" />
                                            </svg>
                                            <small className="text-grey">Free Wifi</small>
                                        </div>
                                        <div className="flex-column">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-play-btn text-grey" viewBox="0 0 16 16">
                                                <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                            </svg>
                                            <small className="text-grey">On demand video</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                <div className="d-flex flex-row bg-light">
                                    <div className="p-3">
                                        <p>Flight</p>
                                        <div className="d-flex align-content-center align-items-center">
                                            <div className="flex-row pr-5">
                                                <img src="assets/img/airlines/airasia.svg" width={30} alt="" />
                                            </div>
                                            <div className="flex-row pr-5">
                                                <h6><span>CCJ-DXB</span></h6>
                                            </div>
                                            <div className="flex-row">
                                                <p className="small-text">Air Asia SG-356</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 text-center">
                                        <p>Cabbin Baggage</p>
                                        <p className="small-text">7 Kg</p>
                                    </div>
                                    <div className="p-3 text-center">
                                        <p>Check-in Baggage</p>
                                        <p className="small-text">30 Kg</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                <h6 className="text-red">Important Information</h6>
                                <p className="small-text">The airline fee is indicative. Travelmythri does not guarantee the accuracy of this
                                    information. All fees mentioned are per passenger. Date change charges are applicable only on selecting
                                    the same airline on a new date. The difference in fares between the old and the new booking will also be
                                    payable by the user.</p>
                            </div>
                        </div>
                        {/* Tabs content */}
                    </div>
                </div>
            </div>
        </div>
        <div className="fixed-bottom bottom-price-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-l-g-4 col-md-4 right-dashed-border">
              <div className="row">
                <div className="col-lg-4 col-md-4 pt-3">
                  <div className="d-flex">
                    <img src="assets/img/airlines/airasia.svg" alt width={30} />
                    <p className="small-text text-white">Air Asia<br />AS-365</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 pt-2">
                  <p className="text-white small-text">Mumbai - Delhi</p>
                  <p className="small-text text-white">Fri Mar 31,19:20 - Fri Mar 31,19:20, 0 Stops</p>
                </div>
                <div className="col-lg-3 col-md-3 pt-4">
                  <div className="flex-row align-content-center align-items-center align-middle">
                    <h6 className="text-white text-bold">₹ 4599</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-l-g-4 col-md-4 right-dashed-border">
              <div className="row">
                <div className="col-lg-4 col-md-4 pt-3">
                  <div className="d-flex">
                    <img src="assets/img/airlines/indigo-01.svg" alt width={40} />
                    <p className="small-text text-white">Air Asia<br />AS-365</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 pt-2">
                  <p className="text-white small-text">Mumbai - Delhi</p>
                  <p className="small-text text-white">Fri Mar 31,19:20 - Fri Mar 31,19:20, 0 Stops</p>
                </div>
                <div className="col-lg-3 col-md-3 pt-4">
                  <div className="flex-row align-content-center align-items-center align-middle">
                    <h6 className="text-white text-bold">₹ 4599</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-l-g-2 col-md-2 right-dashed-border pt-3">
              <div className="text-center">
                <h6 className="text-white text-bold text-size-1 no-margin">₹ 4599</h6>
                <p className="no-margin"><a  data-bs-toggle="modal" data-bs-target="#flightdetails" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}>Flight Details <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg></a></p>
              </div>
            </div>
            <div className="col-l-g-2 col-md-2 right-dashed-border pt-3">
              <button className="btn btn-primary bottom-search-button">BOOK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default SearchResultMulticity