import React from 'react'
import { Outlet } from 'react-router-dom';

import ErrorPage from '../Components/Errors/ErrorPage'

function NavigationGuard() {
    let Status=false;
    let userDetails=JSON.parse(localStorage.getItem("TM_UDetails"));
    //let tokenValue=localStorage.getItem("tokenValue").toString();
    let tokenValue=localStorage.getItem("tokenValue");
    let isNetworkOK=window.navigator.onLine;
   
    if(!isNetworkOK){
      Status=false;
    }
    else if(localStorage.getItem("NonUsertokenValue")!=null)
    {
      Status=false;
    }
    else if((userDetails != null && userDetails.fullName != null) && (tokenValue!=null || tokenValue!="" || tokenValue!=undefined)) 
    {
      Status=true;
    }
  return (
     Status?<Outlet/>:<ErrorPage/>
  )
}

export default NavigationGuard