import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function EditPersonalProfile(data)
{
  return AxiosConfig.post("Account/EditProfile",data, HeaderConfig());
}

export function GetPersonalProfile()
{
  return AxiosConfig.post("Account/GetProfile",{}, HeaderConfig());
}

export function EditLoginDetails(data)
{
  return AxiosConfig.post("Account/EditLoginDetails",data, HeaderConfig());
}

export function EmailUpdation(data)
{
  return AxiosConfig.post("Account/UpdateProfileEmail",data, HeaderConfig());
}

export function OtpForEmailUpdation(data)
{
  return AxiosConfig.post("Account/SubmitUpdateEmailOTP",data, HeaderConfig());
}