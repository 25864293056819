import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class ComingSoon extends Component {
  render() {
    return (
      <div>
        <div className="mobile-nav-menu1">
<ul className="nav justify-content-center">
  <li className="nav-item">
  <Link to="/" className="nav-link active"><img src="assets/img/flightm.png" width={25} className="mr-5" />Flights</Link>
  </li>
  <li className="nav-item">
<Link to="/ComingSoon" className="nav-link"><img src="assets/img/hotelm.png" width={25} className="mr-5" />Hotels</Link>

  </li>
  <li className="nav-item">
  <Link to="/ComingSoon" className="nav-link"><img src="assets/img/busm.png" width={25} className="mr-5" />Bus</Link>
  </li>
 
</ul>
    </div>
        <div className="text-center mb-3 cb-60">
        <h2 className="mb-2 mx-2 cs-title mt-3">Launching soon!</h2>
        <p className="cs-text">We're creating something awesome! Stay tuned.</p>
        <img src="assets/img/coming-soon.png" width={400} />
        </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ComingSoon {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
