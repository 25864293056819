import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import HeaderNew from './HeaderNew';

function DefaultLayout() {
  return (
    
        <div className='fullscreen'>
            <HeaderNew/>

                <Outlet/>

            <Footer />
    </div>
  )
}

export default DefaultLayout
