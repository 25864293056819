import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function GetNonUserToken(data)
{
  return AxiosConfig.post("Account/DefaultAuthenticate",data, HeaderConfig());
}

// export function GetNonUserToken()
// {
//   return AxiosConfig.post("Account/DefaultAuthenticate");
// }