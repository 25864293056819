import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

  export function GetOneWaySearch(data)
  {
    return AxiosConfig.post("AirlineSearch/GetAirlineAvailability",data, HeaderConfig());
  }

  export function GetIRTSearch(data)
  {
    return AxiosConfig.post("AirlineSearch/GetAirlineAvailabilityIRT",data, HeaderConfig());
  }

  export function GetCRTSearch(data)
  {
    return AxiosConfig.post("AirlineSearch/GetAirlineAvailabilityCRT",data, HeaderConfig());
  }