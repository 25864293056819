import React, { Component } from 'react'
import { Authenticate, SubmitOTPToLogin, LogoutUser } from '../../Services/AccountServices';
import { useNavigate, Link, json } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { GetNonUserToken } from "../../Services/NonUser"
import { ToastContainer, toast } from 'react-toastify';
import { CheckNetworkStatus, isValidateMobileNumber, isNumbersOnly, isValidateEmail } from '../../Utilities/Validations';
import { GoogleLogin } from '@react-oauth/google';
import { decodeJwt } from 'jose'
import { GoogleAuthentication } from "../../Services/GoogleAuthentication"
import { useLocation } from "react-router-dom"
import { auth, provider } from "../../Configuration/firebase";
import { signInWithPopup, signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";


class HeaderNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisibleApp: true,
      isEmailEntry: false,
      UserDetails: {},
      loginRequest: {},
      otpSubmitRequest: {},
      isOtpSent: false,
      isLoaded: false,
      isOtpSuccess: false,
      OtpResentOnce: 0,
      showButton: false,
      secondsRemaining: 60,
      IsRefresh: false,
      isGoogleAuthenticated: false,
      GoogleAuthenticatedEmail: "",
      isGoogleVerificationSuccess: false,
      credential: '',
      email: '',
      newEmail: '',
      psswd: ''


    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.LoginOTP = this.LoginOTP.bind(this);
    this.SubmitOTP = this.SubmitOTP.bind(this);
    this.UserLogout = this.UserLogout.bind(this);
    this.UseAnotherMobileNumber = this.UseAnotherMobileNumber.bind(this);
    this.BackToSignIn = this.BackToSignIn.bind(this);
  }

  ///////////Page OnLoad/////////////////////////////
  async componentDidMount() {

    if (!window.navigator.onLine) {
      //Connection  Lost toast here...
    }

    if (this.inputField) {
      this.inputField.focus(); // Focus on the input field
    }

    let data = {};

    if (localStorage.getItem("tokenValue") != null) {
      this.setState({
        UserDetails: JSON.parse(localStorage.getItem("TM_UDetails"))
      });

    }
    else if (!localStorage.getItem('DeviceId') || !localStorage.getItem('NonUsertokenValue')) {
      if (!localStorage.getItem('DeviceId')) {
        const DeviceId = uuidv4();


        data.Id = DeviceId;
        localStorage.setItem('DeviceId', data.Id);
      }
      else if (!localStorage.getItem('NonUsertokenValue')) {

        data.Id = localStorage.getItem('DeviceId');
      }
      await GetNonUserToken(data).then((response) => {

        if (response.data.status) {
          localStorage.setItem('NonUsertokenValue', response.data.token);
          this.setState({
            isLoaded: true,
          })

          this.setState({
            DefaultUserLoginResponse: response.data,
          });

        }
      })
        .catch((error) => {
          this.setState({
            isLoaded: false,
          })

          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          if (error.response.data.responseMessage == "InvalidToken") {
            this.props.navigate('/');
          }
        });
    }






    this.sleep(2000).then(() => {
      this.componentDidMount();
    })
      .catch((error) => {
        if (error.response.data.responseMessage == "InvalidToken") {
          this.props.navigate('/');
        }
        toast.success('Something went wrong...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });

  }

  signInWithGoogle = async () => {
    signOut(auth).then((data) => {
      try {
        provider.setCustomParameters({ prompt: 'select_account' });
        signInWithPopup(auth, provider).then((data) => {

          if (data.user.emailVerified) {
            // Call your API function with the necessary data

            this.IdTokenValidation(data._tokenResponse.oauthAccessToken, data.user.email);
          }
        });
      } catch (err) {
        console.error(err);
      }
    });

  };

  googleSignOut() {
    signOut(auth);
  }



  IdTokenValidation(credential, email) {
    let data = {};
    data.idToken = credential;
    data.emailId = email;
    this.setState({
      credential: credential,
      email: email,
    });
    GoogleAuthentication(data)
      .then((response) => {
        // Handle the API response here
        if (response.data.status && response.data.responseMessage == 'Logined Successfully') {
          this.setState({
            isGoogleAuthenticated: false,
            isGoogleVerificationSuccess: true,
          });
          localStorage.setItem('tokenValue', response.data.token);
          localStorage.removeItem('NonUsertokenValue');
          localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));

          this.setState({
            isOtpSuccess: false,
            IsRefresh: true,
          })


          window.location.reload();

        }
        else if (response.data.status && response.data.responseMessage == 'Success') {
          let data = this.state.loginRequest;
          data.emailId = response.data.email;
          this.setState({
            isGoogleAuthenticated: true,
            GoogleAuthenticatedEmail: response.data.email,
            loginRequest: data,
            isGoogleVerificationSuccess: true,
          });
        }
        else {
          this.setState({
            isGoogleAuthenticated: false,
            isGoogleVerificationSuccess: false,
          });
        }
      })
      .catch((error) => {
        // Handle API call errors here
        console.error('Google Authentication API error', error);
      });
  }

  onSuccess = (credentialResponse) => {
    const { credential } = credentialResponse;
    const payload = credential ? decodeJwt(credential) : undefined;

    if (payload) {

      // Call your API function with the necessary data
      this.IdTokenValidation(credential, payload.email);
    }
  };



  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  UseAnotherMobileNumber() {
    this.setState({
      isOtpSent: false,
    })
  }

  BackToSignIn() {
    this.setState({
      isOtpSent: false,
      isEmailEntry: false,
      isGoogleAuthenticated: false
    })
  }


  ///////////AUthentication/////////////////////////////
  LoginOTP() {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    //Check Valid Mobile...
    if (!isValidateMobileNumber(this.state.loginRequest.mobileNumber) && !isValidateEmail(this.state.loginRequest.mobileNumber)) {
      toast.success('Invalid Email/Mobile Number...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    if (isValidateEmail(this.state.loginRequest.mobileNumber)) {
      this.setState({
        email: this.state.loginRequest.mobileNumber,
        isEmailEntry: true,
        isGoogleAuthenticated: false
      });
      return;
    }


    let data = this.state.loginRequest;

    if (this.state.email.length > 0)
      data.emailId = this.state.email;


    data.isGoogleVerificationSuccess = this.state.isGoogleVerificationSuccess;

    if (this.state.OtpResentOnce <= 1)
      Authenticate(data).
        then((response) => {
          if (response.data.status) {
            this.setState({
              isOtpSent: true
            });
            this.startTimer();
            this.setState({
              secondsRemaining: 60,
            })
          }
          else {
            toast.warning(response.data.responseMessage, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        })
        .catch((error) => {
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
  }

  EnterBtnSubmit(event, type) {

    if (event.key == "Enter")
      if (type == "mob")
        this.LoginOTP();
      else
        this.SubmitOTP();

  }

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        secondsRemaining: prevState.secondsRemaining - 1
      }), () => {
        if (this.state.secondsRemaining === 0) {
          this.setState({ showButton: true });
          clearInterval(this.timer);
        }
      });
    }, 1000); // 1 second
  };


  handleResendOTP = () => {
    this.setState({
      OtpResentOnce: this.state.OtpResentOnce + 1,

    })
    // Handle resend OTP logic here
    // For example, make an API call to resend the OTP
    this.setState({ showButton: false, secondsRemaining: 60 }, () => {
      this.startTimer();
      this.LoginOTP()
    });
  };


  ///////////OTP Submit/////////////////////////////
  SubmitOTP() {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    //Check network connection...
    if (!isNumbersOnly(this.state.otpSubmitRequest.otp)) {
      toast.success('Invalid OTP...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }


    let data = {};
    data = this.state.otpSubmitRequest;
    data.mobileNumber = this.state.loginRequest.mobileNumber;
    data.otp = data.otp.trim();
    data.emailId = this.state.email;
    data.idToken = this.state.credential;
    data.isGoogleAuthSuccess = this.state.isGoogleVerificationSuccess;

    SubmitOTPToLogin(data).
      then((response) => {
        if (response.data.status && response.data.responseMessage == "AddMobile") {
          let l = this.state.loginRequest;
          l.emailId = response.data.objUser.email;

          this.setState({
            loginRequest: l,
            isOtpSent: false,
            isGoogleAuthenticated: true, //just for email login wchich not have mobile number
          })
        }
        else if (response.data.status) {
          localStorage.setItem('tokenValue', response.data.token);
          localStorage.removeItem('NonUsertokenValue');
          localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));
          localStorage.setItem('RS', JSON.stringify(response.data.recentSearchList));

          this.setState({
            isOtpSuccess: false,
            IsRefresh: true,
          })


          window.location.reload();

        }
        else {
          this.setState({
            isOtpSuccess: true
          })
        }
      })
  }

  //////////////////// User Logout /////////////////////////////////////////
  UserLogout() {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    LogoutUser({ UserId: this.state.UserDetails.userId }).
      then((response) => {
        this.googleSignOut();
        if (response.data.status) {
          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.setItem("RS", null);
          window.location.reload();


        }

      })


  }
  /////////////////Input value set//////////////////////////////////////////////
  mobileInputChangeHandler = (event) => {
    const { name, value } = event.target
    let data = this.state.loginRequest;
    data.mobileNumber = value.trim();
    this.setState({
      loginRequest: data,
    })
  }

  /////////////////Input value set//////////////////////////////////////////////
  otpInputChangeHandler = (event) => {
    const { name, value } = event.target;
    let data = {};
    data.otp = value.trim();
    this.setState({
      otpSubmitRequest: data,
    })
  }


  ///////////Goto////////////////////////////////////////////////////////////////
  goto = (nav) => {
    this.props.navigate(nav);
  }
  handleClosem = () => {
    this.setState({ isVisibleApp: false }); 
  };

  render() {

    const { showButton, secondsRemaining } = this.state;

    const { isVisible } = this.state;

    



    return (
      <div >
  { this.state.isVisibleApp && <div className="bg-orange app-promo-m position-relative">
    <img src="assets/img/close.png" className="app-close" onClick={this.handleClosem} />
    <div className="d-flex align-items-center">
      <div>
        <img src="assets/img/download.gif" width={65} />
      </div>
      <div className="m-app-title">
    <h4 className="no-margin">Download Mobile APP!<br />GET GREAT DISCOUNTS!</h4>
    
    </div>

    </div>
    
   
  <div className="flex social-btns">
  <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/travel-mythri/id6474062647" target="_blank">
  <svg
  fill="#000"
  height="23px"
  width="23px"
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 22.773 22.773"
  xmlSpace="preserve"
  stroke="#000"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    {" "}
    <g>
      {" "}
      <g>
        {" "}
        <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573 c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />{" "}
        <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334 c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0 c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019 c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464 c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648 c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />{" "}
      </g>{" "}
      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
    </g>{" "}
  </g>
</svg>
    <p>
     <span className="big-txt">App Store</span>
    </p>
  </a>
  <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.travelmythri.travel_mythri" target="_blank">
  <svg
  fill="#000"
  width="25px"
  height="25px"
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg"
  stroke="#000"
>
  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <title>ionicons-v5_logos</title>
    <path d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z" />
    <path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" />
    <path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" />
    <path d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z" />
  </g>
</svg>
    <p>
     <span className="big-txt">Google Play</span>
    </p>
  </a>

</div>
  </div>}
        <div className="main-nav">
          <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
            <div className="container">
              <p className="navbar-brand d-flex align-items-center m-0 text-primary"><Link to="/"><img className="logo-main me-2" src="assets/img/logo-travelmythri.webp" alt="" /></Link>
              </p>
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-start" id="navbarCollapse">
                <div className="navbar-nav py-4 py-lg-0">
                  <Link to="/" className="nav-item nav-link active hvr-underline-reveal flights">Flights</Link>
                  <Link to="ComingSoon" className="nav-item nav-link active hvr-underline-reveal hotel" style={{ paddingLeft: '2.5rem' }}>Hotels</Link>
                  <Link to="ComingSoon" className="nav-item nav-link active hvr-underline-reveal bus">Bus</Link>
                </div>
              </div>
              <div className="d-flex flex-row-reverse float-end align-items-center">

                {/* {HeaderContent} */}

                {
                  localStorage.getItem("tokenValue") != null && this.state.UserDetails ? <div className="dropdown" id="logged">
                    <a className="dropdown-toggle logged-text" type="button" id="account-login" data-bs-toggle="dropdown" aria-expanded="false">
                      Hi {this.state.UserDetails.firstName} {" "}{this.state.UserDetails.lastName} !</a>
                    <ul className="dropdown-menu" aria-labelledby="account-login">
                      <li><a onClick={() => { this.goto('/Profile') }} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-person mr-10" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                      </svg> My Profile</a></li>
                      <li><a onClick={() => { this.goto('/MyTrips') }} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-briefcase mr-10" viewBox="0 0 16 16">
                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                      </svg> My Trips</a></li>
                      {this.props.location.pathname != "/checkout" &&
                        <li><a onClick={this.UserLogout} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-box-arrow-left mr-10" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                          <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                        </svg> Logout</a></li>
                      }
                    </ul>
                  </div> :
                    <div></div>}
                {
                  (localStorage.getItem("tokenValue") == null) && <div>

                    <p data-bs-toggle="modal" data-bs-target="#exampleModal" className="login-link"><span><img src="assets/img/user.png" width={25} /></span> Login</p>


                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header login-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                              Sign in to TravelMythri!
                            </h6>
                            <button
                              type="button"
                              className="btn-close login-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                            {
                              ///////////////////////After Google Authentication//////////////////// 
                              !this.state.isOtpSent && this.state.isGoogleAuthenticated && !this.state.isEmailEntry && <div>
                                <h5 className="no-margin text-center">Mobile Number <span><a onClick={this.BackToSignIn}><img src="assets/img/edit.png" width={25} /></a></span></h5>
                                <div className="did-floating-label-content">
                                  <input
                                    type="text"
                                    name='mobileNumber'
                                    required=""
                                    spellCheck="false" placeholder=" "
                                    className="did-floating-input form-control mb-4 mt-4"
                                    ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                    onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                    onChange={(e) => this.mobileInputChangeHandler(e)}
                                  />
                                  <label className="did-floating-label">Enter Mobile</label>
                                </div>

                                <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
                                  SUBMIT
                                </button>

                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  <div className="text-center">
                                    <button className="login-with-google-btn" onClick={() => { this.signInWithGoogle() }}> Change Google Account</button>

                                  </div>
                                </div>


                                <div className="pt-3 pb-3 small-text text-center">
                                  <p>
                                    By continuing, you agree to our{" "}
                                    <span className="privacy-link">
                                      <Link to="Privacy_Policy" target="_blank">privacy policy</Link> &amp; <Link to="Terms_Conditions" target="_blank">Terms & Conditions.</Link>
                                    </span>
                                  </p>
                                </div>
                              </div>

                            }
                            {
                              ///////////////////////After Email Id//////////////////// 
                              !this.state.isOtpSent && !this.state.isGoogleAuthenticated && this.state.isEmailEntry && <div>
                                <h5 className="no-margin text-center">Mobile Number</h5>
                                <h5 className="no-margin text-center">Entered Email Id : {this.state.email}</h5>
                                <div className="did-floating-label-content">
                                  <input
                                    type="text"
                                    name='mobileNumber'
                                    required=""
                                    spellCheck="false" placeholder=" "
                                    className="did-floating-input form-control mb-4 mt-4"
                                    ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                    onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                    onChange={(e) => this.mobileInputChangeHandler(e)}
                                  />
                                  <label className="did-floating-label">Enter Mobile</label>
                                </div>

                                <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
                                  SUBMIT
                                </button>

                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  <div className="text-center">
                                    <button type="button" onClick={this.BackToSignIn} >Already have an account? Sign In </button>
                                  </div>
                                </div>


                                <div className="pt-3 pb-3 small-text text-center">
                                  <p>
                                    By continuing, you agree to our{" "}
                                    <span className="privacy-link no-margin">
                                      <Link to="Privacy_Policy" target="_blank">privacy policy</Link> &amp; <Link to="Terms_Conditions" target="_blank">Terms & Conditions.</Link>
                                    </span>
                                  </p>
                                </div>
                              </div>

                            }
                            {
                              ///////////////////////For Mobile/Email Entry//////////////////// 
                              !this.state.isOtpSent && !this.state.isGoogleAuthenticated && !this.state.isEmailEntry && <div>
                                <h5 className="no-margin text-center">Email ID / Mobile Number</h5>
                                <div className="did-floating-label-content">
                                  <input
                                    type="text"
                                    name='mobileNumber'
                                    required=""
                                    spellCheck="false" placeholder=" "
                                    className="did-floating-input form-control mb-4 mt-4"
                                    ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                    onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                    onChange={(e) => this.mobileInputChangeHandler(e)}
                                  />
                                  <label className="did-floating-label">Enter Email or Mobile</label>
                                </div>

                                <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn mb-3">
                                  SUBMIT
                                </button>

                                <h6 className="no-margin text-center">Or</h6>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  <div className="text-center">
                                    <button className="login-with-google-btn" onClick={this.signInWithGoogle}> Sign in with google</button>

                                  </div>
                                </div>
                                <div className="pt-3 pb-3 small-text text-center">
                                  <p>
                                    By continuing, you agree to our{" "}
                                    <span className="privacy-link">
                                      <Link to="Privacy_Policy" target="_blank">privacy policy</Link> &amp; <Link to="Terms_Conditions" target="_blank">Terms & Conditions.</Link>
                                    </span>
                                  </p>
                                </div>
                              </div>

                            }
                            {
                              ///////////////////////For OTP Entry//////////////////// 
                              this.state.isOtpSent && <div className="login-form">
                                <h4 className="text-center">OTP Verification</h4>
                                <p className="mb-3 text-center">An otp has been sent to : <span>{this.state.loginRequest.mobileNumber} <a onClick={this.UseAnotherMobileNumber}><img src="assets/img/edit.png" width={25} /></a></span>

                                </p>
                                <div className="did-floating-label-content">

                                  <input
                                    autoFocus
                                    type="text"
                                    name='otp'
                                    required=""
                                    spellCheck="false" placeholder=" "
                                    className="did-floating-input form-control"
                                    autoComplete='off'
                                    onKeyDown={(e) => this.EnterBtnSubmit(e, "otp")}
                                    onChange={(e) => this.otpInputChangeHandler(e)}
                                  />
                                  {this.state.isOtpSuccess && <span className="mb-3 small-text otp-valid">Please enter a valid OTP</span>}

                                  {showButton ? (

                                    this.state.OtpResentOnce < 1 &&
                                    <span onClick={this.handleResendOTP} className="mb-3 small-text" >Resend OTP</span>
                                  ) : (
                                    this.state.OtpResentOnce < 1 &&
                                    <div>
                                      <span className="mb-3 small-text" >Resend OTP in: {secondsRemaining} </span>

                                    </div>
                                  )}
                                  <label className="did-floating-label">Enter OTP</label>

                                </div>



                                <button type="button" onClick={this.SubmitOTP} className="btn btn-primary f-login-btn mt-3">
                                  LOGIN
                                </button>


                                <div className="pt-3 pb-3 small-text text-center">
                                  <p>
                                    By continuing, you agree to our{" "}
                                    <span className="privacy-link">
                                      <Link to="Privacy_Policy" target="_blank">privacy policy</Link> &amp; <Link to="Terms_Conditions" target="_blank">Terms & Conditions.</Link>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>

          </nav>

        </div>
        <ToastContainer />
      </div>
    )
  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  const location = useLocation();
  return <HeaderNew {...props} navigate={navigate} location={location} />
}

export default WithNavigate
