import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { EditPersonalProfile } from '../../Services/Profile'
import { GetPersonalProfile, EmailUpdation, OtpForEmailUpdation } from '../../Services/Profile'
import { EditLoginDetails } from '../../Services/Profile'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { GetNonUserToken } from "../../Services/NonUser"
import { LogoutUser } from '../../Services/AccountServices';
import { GetCountries, GetStatesByCountryCode } from '../../Services/UtilitiesServices'
import { isProfileValidationSuccess, CheckNetworkStatus, isProfileEachFieldValidationSuccess } from '../../Utilities/Validations';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';


class Profile extends Component {

  CountryJson = [];
  StateJson = [];
  selectedStateCOde = "";

  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      emailId: "",
      warningMessage: "",
      isEmailExist: false,
      showOtpBtn: true,
      error: "",
      profiledetails: {},
      userId: 0,
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      pincode: "",
      address: "",
      countryList: [
        { value: 0, label: "Select Country" },
        { value: 1, label: "India" },
        { value: 2, label: "UK" }
      ],
      stateList: [
        { value: 0, label: "Select State" },
        { value: 1, label: "Kerala" },
        { value: 2, label: "Assam" }
      ],
      selectedCountry: "",
      selectedState: "",
      selectedStateName: "",
      selectedCountryName: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      passwordStatus: 1,
      isProfileUpdated: false,
      UserDetails: {},
      loginRequest: {},
      otpSubmitRequest: {},
      isOtpSent: false,
      AllCountryList: [],
      StateList: [],
      isFormSubmitted: false,
      show: false,
      editModalShow: false,
      ProfileFieldValidity: {
        firstName: false,
        lastName: false,
        email: false,
        countryId: false,
        city: false,
        pincode: false,
        showOTPField: false,
      },



    };
    this.UserLogout = this.UserLogout.bind(this);
    this.getStateListByCountryCode = this.getStateListByCountryCode.bind(this);
  }
  handleClose = () => {

    //Email Submit...
    let UserDetails = JSON.parse(localStorage.getItem("TM_UDetails"));
    let data = {};
    data.otp = this.state.otp;
    data.emailId = this.state.emailId;
    OtpForEmailUpdation(data).then((response) => {
      if (response.data.status) {
        window.location.reload();
      }
      else {
        //Email ALready Exist...
        this.setState({
          warningMessage: "Wrong OTP",
        })
      }
    })


    this.setState({ show: false });
  };

  handleKeyDown(event, type) {
    if (event.key === "Enter") {
      if (type == "email" && this.state.emailId != "") {
        this.handleGetOTP();
      }
      else if (type == "otp" && this.state.otp != "") {
        this.handleClose();
      }
    }
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleProfileShow = () => {
    this.setState({ editModalShow: true });
  };
  handleProfileClose = () => {
    this.setState({ editModalShow: false });
  };
  deleteProfile = () => {
    this.props.navigate('/Delete');
  };

  getCountryNameById(countryId) {
  }

  getStateNameById(stateId) {
  }

  countryChange(event, data) {


    this.setState({
      selectedCountry: event.target.value,
    });

  }

  stateChange(event) {

    this.selectedStateCOde = event.target.value;
    this.setState({
      selectedState: event.target.value,
    });
  }

  handleChangeEmail(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChange = (event, type) => {

    let originSectorMain = [];

    let ProfileFieldValidity = this.state.ProfileFieldValidity;
    ProfileFieldValidity[event.target.name] = isProfileEachFieldValidationSuccess(event);


    this.setState({
      [event.target.name]: event.target.value,
      ProfileFieldValidity: ProfileFieldValidity
    });

  };

  loginhandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

  };

  handleSubmit = (event) => {
    event.preventDefault();



  };
  handleGetOTP = () => {

    //Email Submit...
    let UserDetails = JSON.parse(localStorage.getItem("TM_UDetails"));
    let data = {};
    data.emailId = this.state.emailId;
    EmailUpdation(data).then((response) => {
      if (response.data.status) {
        this.setState({
          showOTPField: true,
          showOtpBtn: false,
        });
      }
      else if (!response.data.status && response.data.responseMessage == "SuperUser") {
        this.setState({
          warningMessage: "Super User can not change Email",
        })
      }
      else if (!response.data.status && response.data.responseMessage == "Valid EmailID required") {
        this.setState({
          warningMessage: "Valid EmailID required",
        })
      }
      else {
        //Email ALready Exist...
        this.setState({
          warningMessage: "Email Already Exist",
        })
      }
    })

  };
  refreshList() {

    const data1 = {};
    GetPersonalProfile()
      .then((response) => {

        this.setState({
          profiledetails: response.data,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          selectedCountry: response.data.countryId,
          selectedState: response.data.stateId,
          city: response.data.city,
          pincode: response.data.pincode,
          address: response.data.address,
          passwordStatus: response.data.passwordStatus,
          userId: response.data.userId,
        });
        this.selectedStateCOde = response.data.stateId;
        this.getStateListByCountryCode(response.data.countryId);
      })
      .catch((error) => {

        if (error.response.data.responseMessage == "InvalidToken" || error.response.data.responseMessage == "TokenTimeOut") {

          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");
          localStorage.removeItem("NonUsertokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.removeItem("RS");

          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.props.navigate('/');

        }
      });
  }

  componentDidMount() {
    if (localStorage.getItem("tokenValue") != null) {
      this.setState({
        UserDetails: JSON.parse(localStorage.getItem("TM_UDetails"))
      });
    }
    this.GetCountryList();
    this.refreshList();
  }

  GetCountryList() {
    GetCountries()
      .then((response) => {
        if (response.data.status) {
          this.CountryJson = response.data.objCountry;
          this.setState({
            allCountryList: response.data.objCountry
          })
        }
      })
  }

  getStateListByCountryCode(id) {
    let data = {};
    data.countryCode = id;
    GetStatesByCountryCode(data)
      .then((response) => {
        if (response.data.status) {
          this.StateJson = response.data.objState;
          this.setState({
            StateList: response.data.objState
          })
        }
      })
  }

  editClick(dep) {
    this.setState({
      userId: dep.userId,
      firstName: dep.firstName,
      lastName: dep.lastName,
      email: dep.email,
      city: dep.city,
      pincode: dep.pincode,
      address: dep.address
    });
  }

  updateClick() {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    const data = {
      userId: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      city: this.state.city,
      pincode: this.state.pincode,
      address: this.state.address,
      countryId: this.state.selectedCountry,
      stateId: this.state.selectedState
    };

    //Form validation...
    let DataStatus = isProfileValidationSuccess(data);
    this.setState({
      ProfileFieldValidity: DataStatus,
      isFormSubmitted: true,
    })

    if (this.state.email != "") {
      if (!DataStatus.firstName || !DataStatus.lastName || !DataStatus.email || !DataStatus.countryId || !DataStatus.city || !DataStatus.pincode) {
        return;
      }
    }
    else {
      if (!DataStatus.firstName || !DataStatus.lastName || !DataStatus.countryId || !DataStatus.city || !DataStatus.pincode) {
        return;
      }
    }


    EditPersonalProfile(data)
      .then(res => {

        if (res.data.status) {

          toast.success('Successfully Updated Your Profile', {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          this.setState({
            isProfileUpdated: true
          })
          let data = {};
          data.fullName = res.data.fullname;
          data.userId = res.data.userId;
          data.firstName = res.data.firstName;
          data.lastName = res.data.lastName;

          localStorage.setItem('TM_UDetails', JSON.stringify(data));

          window.location.reload();
        }
        else if (res.data.responseMessage == "EmailAlreadyExist") {
          this.setState({
            isEmailExist: true,
            error: "Email Already Exist",
          })
        }
      })
      .catch((error) => {
        if (error.response.data.responseMessage == "InvalidToken") {

          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("onewaySearchResult");

          this.props.navigate('/');

        }
      });

  }

  loginupdateClick() {
    const data = {
      userId: this.state.userId,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
      passwordStatus: this.state.passwordStatus

    };
    if (this.state.passwordStatus == 1) {
      data.password = this.state.password;
    }
    else {
      data.password = " "
    }

    EditLoginDetails(data)
      .then(res => {
      })
  }

  //////////////////// User Logout /////////////////////////////////////////
  UserLogout() {
    LogoutUser({ UserId: this.state.UserDetails.userId }).
      then((response) => {
        if (response.data.status) {
          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          const DeviceId = uuidv4();



        }
        window.location.reload();
      })
  }



  render() {
    const { profiledetails, firstName, lastName, email, city, pincode, address, countryList, stateList, selectedCountry, selectedState } = this.state;
    const { password, newPassword, confirmPassword, isEmailExist, error } = this.state;
    const { show } = this.state;
    const { showOTPField } = this.state;
    return (
      <div className="my-acnt-bg">


        <div className="container">
          <div className="user-profile pt-3">
            <h5 className="text-white">My Account</h5>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <Link to="/Profile"><button className="nav-link active" type="button">Profile</button></Link>
                <Link to="/MyTrips"><button className="nav-link" type="button">My Trips</button></Link>
                <Link to="/Insurance"><button className="nav-link" type="button">Insurance</button></Link>
                <Link to="/AccountStatement"><button className="nav-link" type="button" >My Transactions</button></Link>
              </div>
            </nav>
            <div className="tab-content pt-3" id="nav-tabContent">
              <div >
                <div className="row">
                  <div className="col-md-3 col-lg-3 mb-3">
                    <div className="profile-area">
                      <div className="profile-photo text-center">
                        <img src="assets/img/avatar.svg" width={160} />
                      </div>
                      <div className="text-center pt-2 pb-2">
                        <h6>{this.state.profiledetails.firstName}&nbsp;{this.state.profiledetails.lastName}</h6>
                      </div>
                      <ul className="profile-links">
                        <li><a href="#personal" className="active"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-person-badge mr-10" viewBox="0 0 16 16">
                          <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                          <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                        </svg> Profile</a></li>
                        <li><a onClick={this.UserLogout}><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-box-arrow-right mr-10" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                          <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg> Logout</a></li>
                        <li className="del-text"><a onClick={this.deleteProfile}><svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#2a74d0 " strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14 11V17" stroke="#2a74d0 " strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 7H20" stroke="#2a74d0 " strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#2a74d0 " strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#2a74d0 " strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg> Delete my account</a></li>


                      </ul>

                    </div>
                  </div>

                  <div className="col-md-9 col-lg-9">
                    <div className="profile-area mb-3" id="personal">
                      <div className="d-flex flex-column">
                        <div><h5>Personal Informations </h5></div>
                        <div><h5> <p className="small-text mb-2">
                          Basic info, for a faster booking experience
                        </p> </h5></div>

                      </div>
                      <div className="edit-pos">
                        <div className="d-flex flex-row">
                          <div className="mr-10">
                            <a className="edit_btn" onClick={this.handleProfileShow}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-pencil"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                              </svg>{" "}
                              Edit
                            </a>
                          </div>
                          <div>

                          </div>
                        </div>
                      </div>























                      <form id="profile" className="pt-3"  >
                        <div className="row">
                          <div className="col-md-4 col-lg-4">
                            <label htmlFor="" className="small-text">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control mb-3" aria-label="Disabled input example" disabled readOnly
                              placeholder="First Name"
                              defaultValue={this.state.profiledetails.firstName}
                            />
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <label htmlFor="" className="small-text">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control mb-3" aria-label="Disabled input example" disabled readOnly
                              placeholder="Last Name"
                              defaultValue={this.state.profiledetails.lastName}

                            />
                          </div>
                          <div className="col-md-4 col-lg-4 position-relative">
                            <label htmlFor="" className="small-text">
                              Email ID
                            </label>
                            <input
                              type="text"
                              className="form-control mb-3" aria-label="Disabled input example" disabled readOnly
                              placeholder="Email ID"

                              defaultValue={this.state.profiledetails.email}

                            />
                            <a className="email-edit" onClick={this.handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path></svg> Change</a>
                            <Modal show={show} onHide={this.handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Change Email ID</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="did-floating-label-content position-relative">
                                  <input
                                    type="text"
                                    name="emailId"
                                    spellCheck="false"
                                    onChange={(event) => this.handleChangeEmail(event)}
                                    onKeyDown={(e) => { this.handleKeyDown(e, "email") }}
                                    placeholder=" "
                                    className="did-floating-input form-control mb-4"
                                  />
                                  <label className="did-floating-label">Enter new email ID</label>

                                  <span className="email-otp" onClick={this.handleGetOTP}>Get OTP</span>


                                </div>
                                {showOTPField && (
                                  <div className="did-floating-label-content">
                                    <input
                                      type="text"
                                      name="otp"
                                      onChange={(event) => this.handleChangeEmail(event)}
                                      onKeyDown={(e) => { this.handleKeyDown(e, "otp") }}
                                      spellCheck="false"
                                      placeholder=" "
                                      className="did-floating-input form-control mb-4"
                                    />
                                    <label className="did-floating-label">Enter OTP</label>
                                  </div>

                                )}
                                {this.state.warningMessage}


                              </Modal.Body>
                              <Modal.Footer>

                                {!this.state.showOtpBtn && <Button variant="primary" onClick={this.handleClose}>
                                  Update
                                </Button>}
                              </Modal.Footer>
                            </Modal>
                          </div>

                          <div className="col-md-3 col-lg-3">
                            <label htmlFor="" className="small-text">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control mb-3" aria-label="Disabled input example" disabled readOnly
                              placeholder="Country Name"
                              defaultValue={this.state.profiledetails.country}

                            />
                          </div>
                          <div className="col-md-3 col-lg-3">
                            <label htmlFor="" className="small-text">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control mb-3" aria-label="Disabled input example" disabled readOnly
                              placeholder="State Name"
                              defaultValue={this.state.profiledetails.state}

                            />
                          </div>
                          <div className="col-md-3 col-lg-3">
                            <label htmlFor="" className="small-text">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control" aria-label="Disabled input example" disabled readOnly
                              placeholder="City"
                              defaultValue={this.state.profiledetails.city}

                            />
                          </div>
                          <div className="col-md-3 col-lg-3">
                            <label htmlFor="" className="small-text">
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control" aria-label="Disabled input example" disabled readOnly
                              placeholder="Pincode"
                              defaultValue={this.state.profiledetails.pincode}

                            />
                          </div>
                          <div className="col-md-12 col-lg-12 pt-3">
                            <label htmlFor="" className="small-text">
                              Address
                            </label>
                            <textarea
                              name="pax_address"
                              id="pax_address"
                              className="form-control" aria-label="Disabled input example" disabled readOnly
                              placeholder="Address"
                              defaultValue={this.state.profiledetails.address}

                            />
                          </div>
                        </div>

                      </form>

                      <Modal show={this.state.editModalShow} onHide={this.handleProfileClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Personal Informations</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form id="profile1" className="pt-3">
                            <div className="row">
                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control mb-3"
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={this.handleChange}

                                />
                                {(!this.state.ProfileFieldValidity.firstName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid Name required </div>}
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="form-control mb-3"
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={this.handleChange}
                                />
                                {(!this.state.ProfileFieldValidity.lastName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Valid Name required </div>}
                              </div>

                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  Country
                                </label>
                                <select
                                  name="selectedCountry"
                                  id="country"
                                  className="form-control"
                                  value={selectedCountry}
                                  onChange={(e) => { this.getStateListByCountryCode(e.target.value); this.countryChange(e) }}
                                >
                                  <option value="">Please select</option> {/* Added option */}
                                  {(this.CountryJson.length > 0) && this.CountryJson.map((country) => (
                                    <option key={country.id} value={country.countryCode}>
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {(!this.state.ProfileFieldValidity.countryId && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Country required </div>}
                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  State
                                </label>
                                <select
                                  name="selectedState"
                                  id="state"
                                  className="form-control"
                                  value={this.selectedStateCOde}
                                  onChange={(e) => { this.stateChange(e) }}
                                >
                                  {this.StateJson.map((state) => (
                                    <option key={state.stateCode} value={state.stateCode}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  City
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  value={city}
                                  onChange={this.handleChange}
                                />
                                {(!this.state.ProfileFieldValidity.city && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid City Name required </div>}
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <label htmlFor="" className="small-text">
                                  Pincode
                                </label>
                                <input
                                  type="text"
                                  name="pincode"
                                  className="form-control"
                                  placeholder="Pincode"
                                  value={pincode}
                                  onChange={this.handleChange}
                                />
                                {(!this.state.ProfileFieldValidity.pincode && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid Postal Code required </div>}
                              </div>

                              <div className="col-md-12 col-lg-12 pt-3">
                                <textarea
                                  name="address"
                                  className="form-control"
                                  placeholder="Address"
                                  defaultValue={address}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="col-md-12 col-lg-12 pt-3 edit_profile">
                                <button type="button" className="btn btn-primary" onClick={() => this.updateClick()}>Update</button>
                              </div>

                            </div>

                          </form>


                        </Modal.Body>
                      </Modal>











                      {/*Profile edit modal. */}
                      <div className="modal fade" id="profileEdit" tabIndex={-1} aria-labelledby="profileEditLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Edit Personal Informations</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                              <form id="profile1" className="pt-3">
                                <div className="row">
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="firstName"
                                      className="form-control mb-3"
                                      placeholder="First Name"
                                      value={firstName}
                                      onChange={this.handleChange}

                                    />
                                    {(!this.state.ProfileFieldValidity.firstName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid Name required </div>}
                                  </div>
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      name="lastName"
                                      className="form-control mb-3"
                                      placeholder="Last Name"
                                      value={lastName}
                                      onChange={this.handleChange}
                                    />
                                    {(!this.state.ProfileFieldValidity.lastName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Valid Name required </div>}
                                  </div>
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      Country
                                    </label>
                                    <select
                                      name="selectedCountry"
                                      id="country"
                                      className="form-control"
                                      value={selectedCountry}
                                      onChange={(e) => { this.getStateListByCountryCode(e.target.value); this.countryChange(e) }}
                                    >
                                      <option value="">Please select</option> {/* Added option */}
                                      {(this.CountryJson.length > 0) && this.CountryJson.map((country) => (
                                        <option key={country.id} value={country.countryCode}>
                                          {country.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {(!this.state.ProfileFieldValidity.countryId && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Country required </div>}
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      State
                                    </label>
                                    <select
                                      name="selectedState"
                                      id="state"
                                      className="form-control"
                                      value={this.selectedStateCOde}
                                      onChange={(e) => { this.stateChange(e) }}
                                    >
                                      {this.StateJson.map((state) => (
                                        <option key={state.stateCode} value={state.stateCode}>
                                          {state.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      className="form-control"
                                      placeholder="City"
                                      value={city}
                                      onChange={this.handleChange}
                                    />
                                    {(!this.state.ProfileFieldValidity.city && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid City Name required </div>}
                                  </div>
                                  <div className="col-md-4 col-lg-4">
                                    <label htmlFor="" className="small-text">
                                      Pincode
                                    </label>
                                    <input
                                      type="text"
                                      name="pincode"
                                      className="form-control"
                                      placeholder="Pincode"
                                      value={pincode}
                                      onChange={this.handleChange}
                                    />
                                    {(!this.state.ProfileFieldValidity.pincode && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i>Valid Postal Code required </div>}
                                  </div>

                                  <div className="col-md-12 col-lg-12 pt-3">
                                    <textarea
                                      name="address"
                                      className="form-control"
                                      placeholder="Address"
                                      defaultValue={address}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <div className="col-md-12 col-lg-12 pt-3 edit_profile">
                                    <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={() => this.updateClick()}>Update</button>
                                  </div>

                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Login edit modal */}
                    <div className="modal fade" id="loginEdit" tabIndex={-1} aria-labelledby="loginEditLabel" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                          </div>
                          <div className="modal-body">
                            <form id="login-details">
                              <div className="row">
                                {(this.state.passwordStatus == 1) && <div className="col-md-12 col-lg-12">
                                  <label htmlFor="" className="small-text">
                                    Current Password
                                  </label>
                                  <input
                                    type="password"
                                    name="password"
                                    className="form-control mb-3"
                                    placeholder="Password"
                                    value={password}
                                    onChange={this.loginhandleChange}
                                  />
                                </div>}
                                <div className="col-md-12 col-lg-12">
                                  <label htmlFor="" className="small-text">
                                    New Password
                                  </label>
                                  <input
                                    type="password"
                                    name="newPassword"
                                    className="form-control mb-3"
                                    placeholder="Password"
                                    value={newPassword}
                                    onChange={this.loginhandleChange}
                                  />
                                </div>
                                <div className="col-md-12 col-lg-12">
                                  <label htmlFor="" className="small-text">
                                    Confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    name="confirmPassword"
                                    className="form-control mb-3"
                                    placeholder="Password"
                                    value={confirmPassword}
                                    onChange={this.loginhandleChange}
                                  />
                                </div>
                                <div className="col-md-12 col-lg-12 pt-3 edit_profile">
                                  <button type="button" className="btn btn-primary" onClick={() => this.loginupdateClick()}>Update</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Profile {...props} navigate={navigate} />
}

export default WithNavigate




